import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: any;
  AWSDateTime: any;
  AWSEmail: any;
  AWSIPAddress: any;
  AWSJSON: any;
  AWSPhone: any;
  AWSTime: any;
  AWSTimestamp: any;
  AWSURL: any;
};

export type Account = {
  __typename?: 'Account';
  accessnickname?: Maybe<Scalars['String']>;
  accounttype?: Maybe<Scalars['String']>;
  arn?: Maybe<Scalars['String']>;
  connectedAccounts?: Maybe<Array<Maybe<ConnectedAccount>>>;
  defaultregion?: Maybe<Scalars['String']>;
  externalid?: Maybe<Scalars['String']>;
  member_accounts?: Maybe<Array<Maybe<Scalars['String']>>>;
  member_regions?: Maybe<Array<Maybe<Scalars['String']>>>;
  organisation?: Maybe<Scalars['ID']>;
  sharrversion?: Maybe<Scalars['String']>;
};

export type AccountFilterInput = {
  accounttype?: InputMaybe<StringFilterInput>;
  defaultregion?: InputMaybe<StringFilterInput>;
};

export type AccountInput = {
  accessnickname?: InputMaybe<Scalars['String']>;
  accounttype?: InputMaybe<Scalars['String']>;
  arn: Scalars['String'];
  defaultregion?: InputMaybe<Scalars['String']>;
  externalid: Scalars['String'];
  sharrversion?: InputMaybe<Scalars['String']>;
};

export type AthenaLambdaCreateResponse = {
  __typename?: 'AthenaLambdaCreateResponse';
  error?: Maybe<Scalars['AWSJSON']>;
  msg?: Maybe<Scalars['String']>;
  output?: Maybe<Scalars['String']>;
  status: Scalars['Int'];
};

export type AthenaResponseGeneric = {
  __typename?: 'AthenaResponseGeneric';
  error?: Maybe<Scalars['AWSJSON']>;
  msg?: Maybe<Scalars['String']>;
  status: Scalars['Int'];
};

export type BooleanFilterInput = {
  eq?: InputMaybe<Scalars['Boolean']>;
  ne?: InputMaybe<Scalars['Boolean']>;
};

export type CallerIdentityResponse = {
  __typename?: 'CallerIdentityResponse';
  Account?: Maybe<Scalars['String']>;
  Arn?: Maybe<Scalars['String']>;
  UserId?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['AWSJSON']>;
};

export type CognitoGetAccountResponse = {
  __typename?: 'CognitoGetAccountResponse';
  emailAddress?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  organisation?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  status: Scalars['Int'];
  username?: Maybe<Scalars['String']>;
};

export type CognitoListUsersResponse = {
  __typename?: 'CognitoListUsersResponse';
  error?: Maybe<Scalars['AWSJSON']>;
  status: Scalars['Int'];
  users?: Maybe<Array<Maybe<CognitoUser>>>;
};

export type CognitoResponseGeneric = {
  __typename?: 'CognitoResponseGeneric';
  error?: Maybe<Scalars['AWSJSON']>;
  message?: Maybe<Scalars['String']>;
  status: Scalars['Int'];
};

export type CognitoSetMfaResponse = {
  __typename?: 'CognitoSetMfaResponse';
  SecretCode?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['AWSJSON']>;
  message?: Maybe<Scalars['String']>;
  status: Scalars['Int'];
};

export type CognitoUser = {
  __typename?: 'CognitoUser';
  email: Scalars['String'];
  family_name: Scalars['String'];
  given_name: Scalars['String'];
  username: Scalars['String'];
};

export type ConnectedAccount = {
  __typename?: 'ConnectedAccount';
  accountIdRef?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
};

export type ConnectedAccountInput = {
  accountIdRef?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
};

export type ControlAssociation = {
  __typename?: 'ControlAssociation';
  control?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  standard?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type DashboardControl = {
  __typename?: 'DashboardControl';
  customStandard?: Maybe<Scalars['String']>;
  dashboardResults?: Maybe<Array<Maybe<DashboardResults>>>;
};

export type DashboardOverview = {
  __typename?: 'DashboardOverview';
  totalCompliant?: Maybe<Scalars['Int']>;
  totalFail?: Maybe<Scalars['Int']>;
  totalItems?: Maybe<Scalars['Int']>;
};

export type DashboardResults = {
  __typename?: 'DashboardResults';
  DAY?: Maybe<Scalars['Int']>;
  DISABLED?: Maybe<Scalars['Int']>;
  FAILED?: Maybe<Scalars['Int']>;
  PASSED?: Maybe<Scalars['Int']>;
  PERCENT?: Maybe<Scalars['Int']>;
  UNKNOWN?: Maybe<Scalars['Int']>;
};

export enum DataSortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type DeploymentStatus = {
  __typename?: 'DeploymentStatus';
  accountid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  executionArn?: Maybe<Scalars['String']>;
  progress: Scalars['Int'];
  status?: Maybe<Scalars['String']>;
};

export type DrsMitigationActions = {
  __typename?: 'DrsMitigationActions';
  agentinstalled?: Maybe<Scalars['String']>;
  datareplicationstatus?: Maybe<Scalars['String']>;
  initiatedby?: Maybe<Scalars['String']>;
  recoverystatus?: Maybe<Scalars['String']>;
  rpodefined?: Maybe<Scalars['String']>;
  rtodefined?: Maybe<Scalars['String']>;
};

export type DrsPitInstance = {
  __typename?: 'DrsPitInstance';
  snapshotID?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['String']>;
};

export type DrsPitResponse = {
  __typename?: 'DrsPitResponse';
  error?: Maybe<Scalars['AWSJSON']>;
  output?: Maybe<DrsPitSnapshot>;
  status: Scalars['Int'];
};

export type DrsPitSnapshot = {
  __typename?: 'DrsPitSnapshot';
  snapshots?: Maybe<Array<Maybe<DrsPitInstance>>>;
};

export type DrsRecoveryInstance = {
  __typename?: 'DrsRecoveryInstance';
  account?: Maybe<Scalars['String']>;
  agentinstalled?: Maybe<Scalars['String']>;
  datareplicationstatus?: Maybe<Scalars['String']>;
  instanceid?: Maybe<Scalars['String']>;
  lastdril?: Maybe<Scalars['String']>;
  mitigationactions?: Maybe<DrsMitigationActions>;
  recoverystatus?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  rpodefined?: Maybe<Scalars['String']>;
  rtodefined?: Maybe<Scalars['String']>;
};

export type DrsRecoveryResponse = {
  __typename?: 'DrsRecoveryResponse';
  error?: Maybe<Scalars['AWSJSON']>;
  instances?: Maybe<Array<Maybe<DrsRecoveryInstance>>>;
  status: Scalars['Int'];
};

export type Event = {
  __typename?: 'Event';
  account_aliasname?: Maybe<Scalars['String']>;
  account_ref?: Maybe<Scalars['String']>;
  control_ref?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  finding_ref: Scalars['String'];
  organisation: Scalars['ID'];
  status?: Maybe<Scalars['String']>;
};

export type EventFilterInput = {
  account_ref?: InputMaybe<StringFilterInput>;
  control_ref?: InputMaybe<StringFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  status?: InputMaybe<StringFilterInput>;
};

export type ExecutionArn = {
  __typename?: 'ExecutionArn';
  executionArn?: Maybe<Scalars['String']>;
};

export type Finding = {
  __typename?: 'Finding';
  AwsAccountId?: Maybe<Scalars['String']>;
  Compliance?: Maybe<Status>;
  Id?: Maybe<Scalars['String']>;
  ProductFields?: Maybe<ProductField>;
  RecordState?: Maybe<Scalars['String']>;
  Resources?: Maybe<Array<Maybe<Resource>>>;
  Title?: Maybe<Scalars['String']>;
  Workflow?: Maybe<Status>;
};

export type FindingInput = {
  AwsAccountId?: InputMaybe<Scalars['String']>;
  Compliance?: InputMaybe<Statusinput>;
  Id?: InputMaybe<Scalars['String']>;
  ProductFields?: InputMaybe<ProductFieldInput>;
  RecordState?: InputMaybe<Scalars['String']>;
  Resources?: InputMaybe<Array<InputMaybe<ResourceInput>>>;
  Title?: InputMaybe<Scalars['String']>;
  Workflow?: InputMaybe<Statusinput>;
};

export type FrameworkQuestion = {
  __typename?: 'FrameworkQuestion';
  answer_id?: Maybe<Scalars['String']>;
  answers?: Maybe<Array<Maybe<Scalars['String']>>>;
  question?: Maybe<Scalars['String']>;
  question_id?: Maybe<Scalars['String']>;
  questionnaire_answer?: Maybe<Scalars['String']>;
  template?: Maybe<Scalars['String']>;
};

export type GenericData = {
  __typename?: 'GenericData';
  data?: Maybe<Array<Maybe<Scalars['AWSJSON']>>>;
};

export type GenericDataInput = {
  data?: InputMaybe<Array<InputMaybe<Scalars['AWSJSON']>>>;
};

export type IdFilterInput = {
  beginsWith?: InputMaybe<Scalars['ID']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contains?: InputMaybe<Scalars['ID']>;
  eq?: InputMaybe<Scalars['ID']>;
  ge?: InputMaybe<Scalars['ID']>;
  gt?: InputMaybe<Scalars['ID']>;
  le?: InputMaybe<Scalars['ID']>;
  lt?: InputMaybe<Scalars['ID']>;
  ne?: InputMaybe<Scalars['ID']>;
  notContains?: InputMaybe<Scalars['ID']>;
};

export type IacBestPracticeControls = {
  __typename?: 'IacBestPracticeControls';
  checkRef?: Maybe<Scalars['String']>;
  checkType?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  overallFinding?: Maybe<Scalars['String']>;
  resource?: Maybe<Scalars['String']>;
};

export type IacBestPracticeControlsList = {
  __typename?: 'IacBestPracticeControlsList';
  items: Array<Maybe<IacBestPracticeControls>>;
};

export type IntFilterInput = {
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  contains?: InputMaybe<Scalars['Int']>;
  eq?: InputMaybe<Scalars['Int']>;
  ge?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  le?: InputMaybe<Scalars['Int']>;
  lt?: InputMaybe<Scalars['Int']>;
  ne?: InputMaybe<Scalars['Int']>;
  notContains?: InputMaybe<Scalars['Int']>;
};

export type Integration = {
  __typename?: 'Integration';
  app_endpoint?: Maybe<Scalars['String']>;
  app_key?: Maybe<Scalars['String']>;
  app_value?: Maybe<Scalars['String']>;
  application?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  msg?: Maybe<Scalars['String']>;
  organisation?: Maybe<Scalars['String']>;
};

export type IntegrationDetailsResponse = {
  __typename?: 'IntegrationDetailsResponse';
  error?: Maybe<Scalars['AWSJSON']>;
  googleChatDetails?: Maybe<Integration>;
  jiraDetails?: Maybe<Integration>;
  msTeamsDetails?: Maybe<Integration>;
  sentinelOneDetails?: Maybe<Integration>;
  slackDetails?: Maybe<Integration>;
  status: Scalars['Int'];
};

export type MultiCloudwatchInput = {
  cloudtrail1Finding?: InputMaybe<Scalars['String']>;
  cloudtrail2Finding?: InputMaybe<Scalars['String']>;
  cloudtrail3Finding?: InputMaybe<Scalars['String']>;
  cloudtrail4Finding?: InputMaybe<Scalars['String']>;
  cloudtrail5Finding?: InputMaybe<Scalars['String']>;
  cloudtrailArn?: InputMaybe<Scalars['String']>;
  cloudwatchControls?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  loggroupArn?: InputMaybe<Scalars['String']>;
  snsSubscriptionEmail?: InputMaybe<Scalars['String']>;
  snsTopicArn?: InputMaybe<Scalars['String']>;
};

export type MultiCloudwatchState = {
  __typename?: 'MultiCloudwatchState';
  cloudTrails?: Maybe<Scalars['AWSJSON']>;
  snsTopics?: Maybe<Scalars['AWSJSON']>;
  status: Scalars['String'];
};

export type MultiFramework = {
  __typename?: 'MultiFramework';
  awsTechnicalReviewAccount?: Maybe<Scalars['String']>;
  connectedAccounts?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  frameworkName?: Maybe<Scalars['String']>;
  standard?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addIntegration: Integration;
  approveRemediationRequest?: Maybe<GenericResponse>;
  cognitoAddOrg?: Maybe<CognitoResponseGeneric>;
  cognitoAddUser?: Maybe<CognitoResponseGeneric>;
  cognitoChangeUserPassword?: Maybe<CognitoResponseGeneric>;
  cognitoDeleteUser?: Maybe<CognitoResponseGeneric>;
  cognitoEnableMfa?: Maybe<CognitoSetMfaResponse>;
  createAccount?: Maybe<Result>;
  createIacMilestone?: Maybe<WellarchitectedMilestone>;
  createIacWorkload?: Maybe<WellarchitectedCreate>;
  createMilestone?: Maybe<WellarchitectedMilestone>;
  createMultiFramework?: Maybe<Result>;
  createWorkload?: Maybe<WellarchitectedCreate>;
  deleteAccount: Account;
  deleteEvent: Event;
  deleteFramework?: Maybe<Result>;
  deleteIacWorkload?: Maybe<Wellarchitected>;
  deleteIntegration: Integration;
  deleteOrg: Account;
  deleteRemediation?: Maybe<GenericResponse>;
  deleteWorkload?: Maybe<Wellarchitected>;
  initiateManualRemediate?: Maybe<UpdateResponse>;
  initiateRecovery?: Maybe<RecoveryJob>;
  initiateRemediation: RemediationResponse;
  initiateRemediationRequest?: Maybe<GenericResponse>;
  initiateRemediations?: Maybe<Array<RemediationResponse>>;
  manageRemediationSettings?: Maybe<Result>;
  publishResult?: Maybe<Result>;
  publishWellArchitectedResult?: Maybe<Result>;
  rejectRemediationRequest?: Maybe<GenericResponse>;
  scheduleRemediationRequest?: Maybe<GenericResponse>;
  startMultiCloudwatch?: Maybe<StartMultiCloudwatchResult>;
  startStackSetDeploySfn?: Maybe<ExecutionArn>;
  startStackSetRollbackSfn?: Maybe<ExecutionArn>;
  submitEmail?: Maybe<Result>;
  submitFindingsEmail?: Maybe<Result>;
  submitIacEmail?: Maybe<Result>;
  submitIacQuestionnaireAnswer?: Maybe<Result>;
  submitQuestionnaireAnswer?: Maybe<Result>;
  toggleAutoRemediation?: Maybe<Result>;
  updateAnswer?: Maybe<Result>;
  updateBestPractice?: Maybe<Result>;
  updateControlAlertStatus?: Maybe<UpdateResponse>;
  updateControlsRemediateStatus?: Maybe<Result>;
  updateIacAnswer?: Maybe<Result>;
  updateIacBestPractice?: Maybe<Result>;
  updateIacQuestionnaireTable?: Maybe<Result>;
  updateMultiFrameworkQuestionnaireTable?: Maybe<Result>;
  updateQuestionnaireTable?: Maybe<Result>;
};


export type MutationAddIntegrationArgs = {
  app_endpoint?: InputMaybe<Scalars['String']>;
  app_key?: InputMaybe<Scalars['String']>;
  app_value?: InputMaybe<Scalars['String']>;
  application: Scalars['String'];
};


export type MutationApproveRemediationRequestArgs = {
  arn: Scalars['String'];
  remediationId: Scalars['Int'];
};


export type MutationCognitoAddOrgArgs = {
  organisation: Scalars['String'];
};


export type MutationCognitoAddUserArgs = {
  arn: Scalars['String'];
  email: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  phone_number: Scalars['String'];
};


export type MutationCognitoChangeUserPasswordArgs = {
  access_token: Scalars['String'];
  arn: Scalars['String'];
  existing: Scalars['String'];
  newpwone: Scalars['String'];
};


export type MutationCognitoDeleteUserArgs = {
  arn: Scalars['String'];
  username: Scalars['String'];
};


export type MutationCognitoEnableMfaArgs = {
  access_token: Scalars['String'];
  method: Scalars['String'];
  usercode?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};


export type MutationCreateAccountArgs = {
  input: AccountInput;
};


export type MutationCreateIacMilestoneArgs = {
  MilestoneName: Scalars['String'];
  WorkloadId: Scalars['String'];
  awsWafrAccount: Scalars['String'];
};


export type MutationCreateIacWorkloadArgs = {
  AwsRegions?: InputMaybe<Scalars['String']>;
  Description: Scalars['String'];
  Environment: Scalars['String'];
  Lenses: Scalars['String'];
  Notes?: InputMaybe<Scalars['String']>;
  ReviewOwner?: InputMaybe<Scalars['String']>;
  WorkloadName: Scalars['String'];
  awsCreditAccount: Scalars['String'];
  awsTechnicalReviewAccount: Scalars['String'];
  awsWafrAccount: Scalars['String'];
};


export type MutationCreateMilestoneArgs = {
  MilestoneName: Scalars['String'];
  WorkloadId: Scalars['String'];
  arn: Scalars['String'];
};


export type MutationCreateMultiFrameworkArgs = {
  arn: Scalars['String'];
  awsTechnicalReviewAccount: Scalars['String'];
  connectedAccounts?: InputMaybe<Array<InputMaybe<ConnectedAccountInput>>>;
  frameworkName: Scalars['String'];
  standard: Scalars['String'];
};


export type MutationCreateWorkloadArgs = {
  AwsRegions?: InputMaybe<Scalars['String']>;
  Description: Scalars['String'];
  Environment: Scalars['String'];
  Lenses: Scalars['String'];
  Notes?: InputMaybe<Scalars['String']>;
  ReviewOwner?: InputMaybe<Scalars['String']>;
  WorkloadName: Scalars['String'];
  accountAssessedId: Scalars['String'];
  arn: Scalars['String'];
};


export type MutationDeleteAccountArgs = {
  arn: Scalars['String'];
};


export type MutationDeleteEventArgs = {
  finding_ref: Scalars['String'];
};


export type MutationDeleteFrameworkArgs = {
  arn: Scalars['String'];
  frameworkName: Scalars['String'];
};


export type MutationDeleteIacWorkloadArgs = {
  WorkloadId: Scalars['String'];
  awsWafrAccount: Scalars['String'];
};


export type MutationDeleteIntegrationArgs = {
  application: Scalars['String'];
};


export type MutationDeleteOrgArgs = {
  organisationId: Scalars['String'];
};


export type MutationDeleteRemediationArgs = {
  arn: Scalars['String'];
  remediationId: Scalars['String'];
};


export type MutationDeleteWorkloadArgs = {
  WorkloadId: Scalars['String'];
  arn: Scalars['String'];
};


export type MutationInitiateManualRemediateArgs = {
  arn: Scalars['String'];
  findingid: Scalars['String'];
};


export type MutationInitiateRecoveryArgs = {
  arn: Scalars['String'];
  drill: Scalars['Boolean'];
  instanceid: Scalars['String'];
  snapshotid: Scalars['String'];
};


export type MutationInitiateRemediationArgs = {
  arn: Scalars['String'];
  findingId: Scalars['String'];
};


export type MutationInitiateRemediationRequestArgs = {
  findingId: Scalars['String'];
};


export type MutationInitiateRemediationsArgs = {
  accountRef: Scalars['String'];
  arn: Scalars['String'];
  control: Scalars['String'];
};


export type MutationManageRemediationSettingsArgs = {
  arn: Scalars['String'];
  automationApprovalFlow?: InputMaybe<Scalars['String']>;
  automationFlowType?: InputMaybe<Scalars['String']>;
};


export type MutationPublishResultArgs = {
  result?: InputMaybe<ResultInput>;
};


export type MutationPublishWellArchitectedResultArgs = {
  result?: InputMaybe<ResultInput>;
};


export type MutationRejectRemediationRequestArgs = {
  arn: Scalars['String'];
  remediationId: Scalars['Int'];
};


export type MutationScheduleRemediationRequestArgs = {
  findingid: Scalars['String'];
  scheduledAt: Scalars['String'];
  timezone: Scalars['String'];
};


export type MutationStartMultiCloudwatchArgs = {
  arn: Scalars['String'];
  input?: InputMaybe<MultiCloudwatchInput>;
};


export type MutationStartStackSetDeploySfnArgs = {
  accountRefs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  administratorRoleArn?: InputMaybe<Scalars['String']>;
  arn?: InputMaybe<Scalars['String']>;
  executionRoleName?: InputMaybe<Scalars['String']>;
  permissionModel?: InputMaybe<Scalars['String']>;
  regions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};


export type MutationStartStackSetRollbackSfnArgs = {
  arn?: InputMaybe<Scalars['String']>;
};


export type MutationSubmitEmailArgs = {
  arn: Scalars['String'];
  stage: Scalars['String'];
  workLoadId: Scalars['String'];
};


export type MutationSubmitFindingsEmailArgs = {
  arn: Scalars['String'];
  compliancestatus: Scalars['String'];
  connectedAccounts?: InputMaybe<Array<InputMaybe<ConnectedAccountInput>>>;
  intrusive: Scalars['String'];
  standard: Scalars['String'];
};


export type MutationSubmitIacEmailArgs = {
  awsWafrAccount: Scalars['String'];
  stage: Scalars['String'];
  workLoadId: Scalars['String'];
};


export type MutationSubmitIacQuestionnaireAnswerArgs = {
  awsWafrAccount: Scalars['String'];
  lensAlias: Scalars['String'];
  questionAnswer: Scalars['String'];
  questionId: Scalars['String'];
  workLoadId: Scalars['String'];
};


export type MutationSubmitQuestionnaireAnswerArgs = {
  arn: Scalars['String'];
  lensAlias: Scalars['String'];
  questionAnswer: Scalars['String'];
  questionId: Scalars['String'];
  workLoadId: Scalars['String'];
};


export type MutationToggleAutoRemediationArgs = {
  action: Scalars['String'];
  arn: Scalars['String'];
  controlnames?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationUpdateAnswerArgs = {
  LensAlias: Scalars['String'];
  WorkloadId: Scalars['String'];
  arn: Scalars['String'];
  connectedAccounts?: InputMaybe<Array<InputMaybe<ConnectedAccountInput>>>;
  targetAccountArn: Scalars['String'];
};


export type MutationUpdateBestPracticeArgs = {
  LensAlias: Scalars['String'];
  arn: Scalars['String'];
  controls?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  workLoadId: Scalars['String'];
};


export type MutationUpdateControlAlertStatusArgs = {
  action: Scalars['String'];
  arn: Scalars['String'];
  controlname: Scalars['String'];
};


export type MutationUpdateControlsRemediateStatusArgs = {
  action: Scalars['String'];
  arn: Scalars['String'];
  controlnames?: InputMaybe<Array<Scalars['String']>>;
};


export type MutationUpdateIacAnswerArgs = {
  LensAlias: Scalars['String'];
  WorkloadId: Scalars['String'];
  awsWafrAccount: Scalars['String'];
  summaryId: Array<InputMaybe<Scalars['Int']>>;
};


export type MutationUpdateIacBestPracticeArgs = {
  LensAlias: Scalars['String'];
  awsWafrAccount: Scalars['String'];
  controls?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  workLoadId: Scalars['String'];
};


export type MutationUpdateIacQuestionnaireTableArgs = {
  answer: Scalars['String'];
  awsWafrAccount: Scalars['String'];
  questionId: Scalars['String'];
  workLoadId: Scalars['String'];
};


export type MutationUpdateMultiFrameworkQuestionnaireTableArgs = {
  answer: Scalars['String'];
  arn: Scalars['String'];
  frameworkName: Scalars['String'];
  questionId: Scalars['String'];
  standard: Scalars['String'];
};


export type MutationUpdateQuestionnaireTableArgs = {
  answer: Scalars['String'];
  arn: Scalars['String'];
  questionId: Scalars['String'];
  workLoadId: Scalars['String'];
};

export type PaginatedAccount = {
  __typename?: 'PaginatedAccount';
  items: Array<Account>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedEvent = {
  __typename?: 'PaginatedEvent';
  items: Array<Event>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedEvents = {
  __typename?: 'PaginatedEvents';
  items: Array<Events>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedFrameworks = {
  __typename?: 'PaginatedFrameworks';
  items: Array<MultiFramework>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedMilestones = {
  __typename?: 'PaginatedMilestones';
  MilestoneSummaries: Array<WellarchitectedMilestones>;
  nextToken?: Maybe<Scalars['String']>;
};

export type PaginatedSecurityControl = {
  __typename?: 'PaginatedSecurityControl';
  items: Array<SecurityControl>;
  limit?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type PaginatedWorkloads = {
  __typename?: 'PaginatedWorkloads';
  items: Array<WellarchitectedWorkloads>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ProductField = {
  __typename?: 'ProductField';
  RecommendationUrl?: Maybe<Scalars['String']>;
};

export type ProductFieldInput = {
  RecommendationUrl?: InputMaybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  automateDeploymentStatus?: Maybe<DeploymentStatus>;
  automateProgressStatus?: Maybe<DeploymentStatus>;
  downloadIacReports?: Maybe<Result>;
  downloadReports?: Maybe<Result>;
  downloadSelfAssessment?: Maybe<Result>;
  getAccount: Account;
  getBestPractice?: Maybe<GetBestPracticeResponse>;
  getCallerIdentity: CallerIdentityResponse;
  getCognitoAccount?: Maybe<CognitoGetAccountResponse>;
  getCognitoUsersResult?: Maybe<CognitoListUsersResponse>;
  getControl?: Maybe<SecurityControl>;
  getControlMappings?: Maybe<GetControlMappingsResponse>;
  getControls?: Maybe<PaginatedSecurityControl>;
  getControlsStatus?: Maybe<PaginatedSecurityControl>;
  getDashboardControls?: Maybe<DashboardControl>;
  getDashboardOverview?: Maybe<DashboardOverview>;
  getDrs1Control?: Maybe<SecurityControl>;
  getDrs2Control?: Maybe<SecurityControl>;
  getDrs3Control?: Maybe<SecurityControl>;
  getDrsPit?: Maybe<Array<Maybe<RecoverySnapShot>>>;
  getDrsPit2?: Maybe<DrsPitResponse>;
  getDrsRecovery?: Maybe<DrsRecoveryResponse>;
  getEvents: PaginatedEvent;
  getExternalApiToken?: Maybe<GetExternalApiTokenResponse>;
  getFrameworkQuestionnaire?: Maybe<Array<Maybe<FrameworkQuestion>>>;
  getFtrnpControls?: Maybe<Array<Maybe<SecurityControl>>>;
  getIacLensReview?: Maybe<WellarchitectedLens>;
  getIacLensReviewReport?: Maybe<WellarchitectedReport>;
  getIacQuestionnaireTable?: Maybe<Scalars['AWSJSON']>;
  getIntegrationDetails?: Maybe<IntegrationDetailsResponse>;
  getLambdaCreationResult?: Maybe<AthenaLambdaCreateResponse>;
  getLensReview?: Maybe<WellarchitectedLens>;
  getLensReviewReport?: Maybe<WellarchitectedReport>;
  getMultiCloudwatchState?: Maybe<MultiCloudwatchState>;
  getQuestionnaire?: Maybe<Array<Maybe<Question>>>;
  getQuestionnaireTable?: Maybe<Scalars['AWSJSON']>;
  getRecoveryServers?: Maybe<Array<Maybe<RecoverServer>>>;
  getRedisData?: Maybe<Scalars['AWSJSON']>;
  getRemediationData?: Maybe<GetRemediationResponse>;
  getRemediationSettings?: Maybe<GetRemediationSettingsResult>;
  getRemediationStatus?: Maybe<RemediationStatus>;
  getRulesMappings?: Maybe<RulesMappingsResponse>;
  getSecurityHubMembers?: Maybe<Array<Maybe<SecurityHubMember>>>;
  getSelfAssessment?: Maybe<SelfAssessmentList>;
  getSupportControl?: Maybe<SecurityControl>;
  getTemplateEngineListTemplates?: Maybe<TemplateScannerListTemplatesResponse>;
  getTemplateEngineSecurityResponse?: Maybe<TemplateScannerUploadResponse>;
  getTemplateS3URL?: Maybe<Scalars['AWSURL']>;
  getUserAccounts: PaginatedAccount;
  getUserDeployEvents?: Maybe<PaginatedEvents>;
  getWorkload?: Maybe<WellarchitectedSingleWorkload>;
  initiateSharrDeploy?: Maybe<Scalars['String']>;
  listIacBestPracticeControls?: Maybe<IacBestPracticeControlsList>;
  listIacMilestones?: Maybe<PaginatedMilestones>;
  listIacWorkloads?: Maybe<PaginatedWorkloads>;
  listMilestones?: Maybe<PaginatedMilestones>;
  listMultiFramework?: Maybe<PaginatedFrameworks>;
  listRemediationRequests?: Maybe<ListRemediationResponse>;
  listWorkloads?: Maybe<PaginatedWorkloads>;
  userRollbackProcess?: Maybe<ExecutionArn>;
  userSetupProcess?: Maybe<ExecutionArn>;
  userUpgradeProcess?: Maybe<ExecutionArn>;
  validateAccount: ValidateAccountResponse;
  wakeUpDatabase?: Maybe<WakeUpStatus>;
};


export type QueryAutomateDeploymentStatusArgs = {
  accountid?: InputMaybe<Scalars['String']>;
};


export type QueryAutomateProgressStatusArgs = {
  accountid?: InputMaybe<Scalars['String']>;
  organisation?: InputMaybe<Scalars['String']>;
};


export type QueryDownloadIacReportsArgs = {
  MilestoneNumber?: InputMaybe<Scalars['Int']>;
  WorkloadId: Scalars['String'];
  WorkloadLens: Scalars['String'];
  WorkloadName: Scalars['String'];
  awsWafrAccount: Scalars['String'];
};


export type QueryDownloadReportsArgs = {
  MilestoneNumber?: InputMaybe<Scalars['Int']>;
  WorkloadId: Scalars['String'];
  WorkloadLens: Scalars['String'];
  WorkloadName: Scalars['String'];
  arn: Scalars['String'];
};


export type QueryDownloadSelfAssessmentArgs = {
  arn: Scalars['String'];
};


export type QueryGetAccountArgs = {
  arn: Scalars['String'];
};


export type QueryGetBestPracticeArgs = {
  checkId: Scalars['String'];
  checkType: Scalars['String'];
  description: Scalars['String'];
  fileFormat: Scalars['String'];
  resource: Scalars['String'];
};


export type QueryGetCallerIdentityArgs = {
  arn?: InputMaybe<Scalars['String']>;
};


export type QueryGetCognitoAccountArgs = {
  emailAddress?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  organisation?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};


export type QueryGetCognitoUsersResultArgs = {
  arn: Scalars['String'];
};


export type QueryGetControlArgs = {
  accountRef?: InputMaybe<Scalars['String']>;
  arn: Scalars['String'];
  connectedAccounts?: InputMaybe<Array<InputMaybe<ConnectedAccountInput>>>;
  controlname: Scalars['String'];
};


export type QueryGetControlMappingsArgs = {
  resultList?: InputMaybe<Scalars['String']>;
};


export type QueryGetControlsArgs = {
  accountRef?: InputMaybe<Scalars['String']>;
  arn: Scalars['String'];
  connectedAccounts?: InputMaybe<Array<InputMaybe<ConnectedAccountInput>>>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryGetControlsStatusArgs = {
  arn: Scalars['String'];
  connectedAccounts?: InputMaybe<Array<InputMaybe<ConnectedAccountInput>>>;
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryGetDashboardControlsArgs = {
  arn: Scalars['String'];
  standard: Scalars['String'];
};


export type QueryGetDashboardOverviewArgs = {
  arn: Scalars['String'];
};


export type QueryGetDrs1ControlArgs = {
  arn: Scalars['String'];
};


export type QueryGetDrs2ControlArgs = {
  arn: Scalars['String'];
};


export type QueryGetDrs3ControlArgs = {
  arn: Scalars['String'];
};


export type QueryGetDrsPitArgs = {
  arn: Scalars['String'];
  instanceid: Scalars['String'];
};


export type QueryGetDrsPit2Args = {
  arn: Scalars['String'];
  instanceid: Scalars['String'];
  token?: InputMaybe<Scalars['String']>;
};


export type QueryGetDrsRecoveryArgs = {
  arn: Scalars['String'];
  token?: InputMaybe<Scalars['String']>;
};


export type QueryGetEventsArgs = {
  cache?: InputMaybe<Scalars['Boolean']>;
  filter?: InputMaybe<EventFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  sortDirection?: InputMaybe<DataSortDirection>;
};


export type QueryGetFrameworkQuestionnaireArgs = {
  Standard: Scalars['String'];
  arn: Scalars['String'];
  frameworkName: Scalars['String'];
};


export type QueryGetFtrnpControlsArgs = {
  arn: Scalars['String'];
};


export type QueryGetIacLensReviewArgs = {
  LensAlias: Scalars['String'];
  WorkloadId: Scalars['String'];
  awsWafrAccount: Scalars['String'];
};


export type QueryGetIacLensReviewReportArgs = {
  LensAlias: Scalars['String'];
  MilestoneNumber?: InputMaybe<Scalars['Int']>;
  WorkloadId: Scalars['String'];
  awsWafrAccount: Scalars['String'];
};


export type QueryGetIacQuestionnaireTableArgs = {
  awsWafrAccount: Scalars['String'];
  workLoadId: Scalars['String'];
};


export type QueryGetIntegrationDetailsArgs = {
  organisation?: InputMaybe<Scalars['String']>;
};


export type QueryGetLambdaCreationResultArgs = {
  arn: Scalars['String'];
  controlID?: InputMaybe<Scalars['String']>;
  customRemediation?: InputMaybe<Scalars['Boolean']>;
  emailAddress?: InputMaybe<Scalars['String']>;
  prompt: Scalars['String'];
};


export type QueryGetLensReviewArgs = {
  LensAlias: Scalars['String'];
  WorkloadId: Scalars['String'];
  arn: Scalars['String'];
};


export type QueryGetLensReviewReportArgs = {
  LensAlias: Scalars['String'];
  MilestoneNumber?: InputMaybe<Scalars['Int']>;
  WorkloadId: Scalars['String'];
  arn: Scalars['String'];
};


export type QueryGetMultiCloudwatchStateArgs = {
  arn: Scalars['String'];
};


export type QueryGetQuestionnaireTableArgs = {
  arn: Scalars['String'];
  workLoadId: Scalars['String'];
};


export type QueryGetRecoveryServersArgs = {
  arn: Scalars['String'];
};


export type QueryGetRedisDataArgs = {
  key: Scalars['String'];
  move_to_cache: Scalars['Boolean'];
  new_key?: InputMaybe<Scalars['String']>;
};


export type QueryGetRemediationDataArgs = {
  arn: Scalars['String'];
  remediationId: Scalars['Int'];
};


export type QueryGetRemediationStatusArgs = {
  arn: Scalars['String'];
  executionArn?: InputMaybe<Scalars['String']>;
};


export type QueryGetSecurityHubMembersArgs = {
  arn?: InputMaybe<Scalars['String']>;
};


export type QueryGetSelfAssessmentArgs = {
  arn: Scalars['String'];
};


export type QueryGetSupportControlArgs = {
  arn: Scalars['String'];
};


export type QueryGetTemplateEngineListTemplatesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryGetTemplateEngineSecurityResponseArgs = {
  fileAlias?: InputMaybe<Scalars['String']>;
  fileName?: InputMaybe<Scalars['String']>;
  fileString?: InputMaybe<Scalars['String']>;
  processType: Scalars['String'];
  summaryId?: InputMaybe<Scalars['String']>;
  summaryList?: InputMaybe<Scalars['String']>;
};


export type QueryGetTemplateS3UrlArgs = {
  template: Scalars['String'];
};


export type QueryGetUserAccountsArgs = {
  arn?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<AccountFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<DataSortDirection>;
};


export type QueryGetUserDeployEventsArgs = {
  organisation?: InputMaybe<Scalars['String']>;
};


export type QueryGetWorkloadArgs = {
  WorkloadId: Scalars['String'];
  arn: Scalars['String'];
};


export type QueryInitiateSharrDeployArgs = {
  arn: Scalars['String'];
};


export type QueryListIacBestPracticeControlsArgs = {
  summaryId: Array<InputMaybe<Scalars['Int']>>;
};


export type QueryListIacMilestonesArgs = {
  WorkloadId: Scalars['String'];
  awsWafrAccount: Scalars['String'];
};


export type QueryListIacWorkloadsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListMilestonesArgs = {
  WorkloadId: Scalars['String'];
  arn: Scalars['String'];
};


export type QueryListMultiFrameworkArgs = {
  arn?: InputMaybe<Scalars['String']>;
};


export type QueryListRemediationRequestsArgs = {
  arn: Scalars['String'];
  limit?: InputMaybe<Scalars['Int']>;
  page?: InputMaybe<Scalars['Int']>;
};


export type QueryListWorkloadsArgs = {
  arn: Scalars['String'];
};


export type QueryUserRollbackProcessArgs = {
  accessnickname?: InputMaybe<Scalars['String']>;
  arn?: InputMaybe<Scalars['String']>;
  externalid?: InputMaybe<Scalars['String']>;
  keepConfig?: InputMaybe<Scalars['String']>;
  keepSecHub?: InputMaybe<Scalars['String']>;
  organisation?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
};


export type QueryUserSetupProcessArgs = {
  accessnickname?: InputMaybe<Scalars['String']>;
  accounttype?: InputMaybe<Scalars['String']>;
  arn?: InputMaybe<Scalars['String']>;
  externalid?: InputMaybe<Scalars['String']>;
  organisation?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
};


export type QueryUserUpgradeProcessArgs = {
  accessnickname?: InputMaybe<Scalars['String']>;
  accounttype?: InputMaybe<Scalars['String']>;
  arn?: InputMaybe<Scalars['String']>;
  externalid?: InputMaybe<Scalars['String']>;
  organisation?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
};


export type QueryValidateAccountArgs = {
  arn: Scalars['String'];
};

export type Question = {
  __typename?: 'Question';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type RecoverServer = {
  __typename?: 'RecoverServer';
  account?: Maybe<Scalars['String']>;
  agentinstalled: Scalars['String'];
  datareplicationstatus: Scalars['String'];
  initiatedby?: Maybe<Scalars['String']>;
  instanceid: Scalars['String'];
  lastdrill?: Maybe<Scalars['String']>;
  recoverystatus?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  rpodefined: Scalars['String'];
  rtodefined: Scalars['String'];
  sourceserverid: Scalars['String'];
};

export type RecoveryJob = {
  __typename?: 'RecoveryJob';
  creationDateTime?: Maybe<Scalars['String']>;
  jobID?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type RecoverySnapShot = {
  __typename?: 'RecoverySnapShot';
  snapshotID: Scalars['String'];
  timestamp: Scalars['String'];
};

export type RemediateStatus = {
  __typename?: 'RemediateStatus';
  alertstatus?: Maybe<Scalars['String']>;
  controlname: Scalars['String'];
  newControlName?: Maybe<Scalars['String']>;
  remediatestatus: Scalars['String'];
};

export type RemediationResponse = {
  __typename?: 'RemediationResponse';
  error?: Maybe<Scalars['String']>;
  findingId?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  template?: Maybe<Scalars['String']>;
};

export type RemediationStatus = {
  __typename?: 'RemediationStatus';
  error?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tasks?: Maybe<Array<Maybe<SfnTask>>>;
};

export type Resource = {
  __typename?: 'Resource';
  Id?: Maybe<Scalars['String']>;
  Name?: Maybe<Scalars['String']>;
  Region?: Maybe<Scalars['String']>;
};

export type ResourceInput = {
  Id?: InputMaybe<Scalars['String']>;
  Region?: InputMaybe<Scalars['String']>;
};

export type Result = {
  __typename?: 'Result';
  id: Scalars['String'];
  result?: Maybe<Scalars['String']>;
  status: ResultStatus;
};

export type ResultInput = {
  id: Scalars['String'];
  result?: InputMaybe<Scalars['String']>;
  status: ResultStatus;
};

export enum ResultStatus {
  Complete = 'COMPLETE',
  Error = 'ERROR',
  Pending = 'PENDING'
}

export type RulesMappingsResponse = {
  __typename?: 'RulesMappingsResponse';
  DRS_CONTROLS?: Maybe<Scalars['AWSJSON']>;
  FTR_SELF_ASSESSMENT?: Maybe<Scalars['AWSJSON']>;
  SECHUB_CDR_CONTROL?: Maybe<Scalars['AWSJSON']>;
  SECHUB_ISO_CONTROL?: Maybe<Scalars['AWSJSON']>;
  SECHUB_NIST_CONTROL?: Maybe<Scalars['AWSJSON']>;
  SECHUB_SOC_CONTROL?: Maybe<Scalars['AWSJSON']>;
  SO_CONTROLS?: Maybe<Scalars['AWSJSON']>;
};

export type SecurityControl = {
  __typename?: 'SecurityControl';
  alertstatus?: Maybe<Scalars['String']>;
  cdrcontrol?: Maybe<Scalars['String']>;
  cdrdescription?: Maybe<Scalars['String']>;
  compliancestatus: Scalars['String'];
  controlAssociations?: Maybe<Array<Maybe<ControlAssociation>>>;
  controldescription?: Maybe<Scalars['String']>;
  controlname?: Maybe<Scalars['String']>;
  controlshlink?: Maybe<Scalars['String']>;
  customStandard?: Maybe<Array<Maybe<Scalars['String']>>>;
  customcontrol?: Maybe<Scalars['Boolean']>;
  customplaybook?: Maybe<Scalars['Boolean']>;
  findings?: Maybe<Array<Finding>>;
  ftrcontrolname?: Maybe<Scalars['String']>;
  intrusive?: Maybe<Scalars['String']>;
  isocontrol?: Maybe<Scalars['String']>;
  isodescription?: Maybe<Scalars['String']>;
  nistcontrol?: Maybe<Scalars['String']>;
  nistdescription?: Maybe<Scalars['String']>;
  pcicontrol?: Maybe<Scalars['String']>;
  pcidescription?: Maybe<Scalars['String']>;
  playbooks?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  remediatestatus?: Maybe<Scalars['String']>;
  remediationUrl?: Maybe<Scalars['String']>;
  remediationsteps?: Maybe<Scalars['String']>;
  select?: Maybe<Scalars['String']>;
  severity?: Maybe<Scalars['String']>;
  soccontrol?: Maybe<Scalars['String']>;
  socdescription?: Maybe<Scalars['String']>;
  watmappingcontrol?: Maybe<Scalars['String']>;
  watmappingdescription?: Maybe<Scalars['String']>;
  workflowType?: Maybe<Scalars['String']>;
};

export type SecurityControlInput = {
  alertstatus?: InputMaybe<Scalars['String']>;
  cdrcontrol?: InputMaybe<Scalars['String']>;
  cdrdescription?: InputMaybe<Scalars['String']>;
  compliancestatus: Scalars['String'];
  controldescription?: InputMaybe<Scalars['String']>;
  controlname?: InputMaybe<Scalars['String']>;
  controlshlink?: InputMaybe<Scalars['String']>;
  customStandard?: InputMaybe<Scalars['String']>;
  customcontrol?: InputMaybe<Scalars['Boolean']>;
  customplaybook?: InputMaybe<Scalars['Boolean']>;
  findings?: InputMaybe<Array<FindingInput>>;
  ftrcontrolname?: InputMaybe<Scalars['String']>;
  intrusive?: InputMaybe<Scalars['String']>;
  isocontrol?: InputMaybe<Scalars['String']>;
  isodescription?: InputMaybe<Scalars['String']>;
  nistcontrol?: InputMaybe<Scalars['String']>;
  nistdescription?: InputMaybe<Scalars['String']>;
  pcicontrol?: InputMaybe<Scalars['String']>;
  pcidescription?: InputMaybe<Scalars['String']>;
  playbooks?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  remediatestatus?: InputMaybe<Scalars['String']>;
  select?: InputMaybe<Scalars['String']>;
  severity?: InputMaybe<Scalars['String']>;
  soccontrol?: InputMaybe<Scalars['String']>;
  socdescription?: InputMaybe<Scalars['String']>;
};

export type SecurityHubMember = {
  __typename?: 'SecurityHubMember';
  AccountId?: Maybe<Scalars['String']>;
  AdministratorId?: Maybe<Scalars['String']>;
  Email?: Maybe<Scalars['String']>;
  InvitedAt?: Maybe<Scalars['AWSDateTime']>;
  MasterId?: Maybe<Scalars['String']>;
  MemberStatus?: Maybe<Scalars['String']>;
  UpdatedAt?: Maybe<Scalars['AWSDateTime']>;
};

export type SelfAssessmentList = {
  __typename?: 'SelfAssessmentList';
  items?: Maybe<Array<Maybe<SelfAssessmentResult>>>;
};

export type SfnTask = {
  __typename?: 'SfnTask';
  error?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  order: Scalars['Int'];
  status: Scalars['String'];
};

export type StartMultiCloudwatchResult = {
  __typename?: 'StartMultiCloudwatchResult';
  executionArn?: Maybe<Scalars['String']>;
};

export type Status = {
  __typename?: 'Status';
  Status?: Maybe<Scalars['String']>;
};

export type Statusinput = {
  Status?: InputMaybe<Scalars['String']>;
};

export type StringFilterInput = {
  beginsWith?: InputMaybe<Scalars['String']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contains?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  ge?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  le?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
  ne?: InputMaybe<Scalars['String']>;
  notContains?: InputMaybe<Scalars['String']>;
};

export type Subscription = {
  __typename?: 'Subscription';
  onResult?: Maybe<Result>;
};


export type SubscriptionOnResultArgs = {
  id: Scalars['String'];
};

export type TemplateScannerListTemplatesResponse = {
  __typename?: 'TemplateScannerListTemplatesResponse';
  error?: Maybe<Scalars['AWSJSON']>;
  msg?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  results?: Maybe<Scalars['AWSJSON']>;
  status: Scalars['Int'];
};

export type TemplateScannerUploadResponse = {
  __typename?: 'TemplateScannerUploadResponse';
  error?: Maybe<Scalars['AWSJSON']>;
  msg?: Maybe<Scalars['String']>;
  result?: Maybe<GetResultResponse>;
  status: Scalars['Int'];
};

export type UpdateAccountInput = {
  accessnickname?: InputMaybe<Scalars['String']>;
  accounttype?: InputMaybe<Scalars['String']>;
  defaultregion?: InputMaybe<Scalars['String']>;
  externalid: Scalars['String'];
  sharrversion?: InputMaybe<Scalars['String']>;
};

export type UpdateResponse = {
  __typename?: 'UpdateResponse';
  id?: Maybe<Scalars['String']>;
  msg?: Maybe<Scalars['String']>;
  status: Scalars['Boolean'];
};

export type ValidateAccountResponse = {
  __typename?: 'ValidateAccountResponse';
  error?: Maybe<Scalars['AWSJSON']>;
  identity?: Maybe<Scalars['AWSJSON']>;
  msg: Scalars['String'];
  status: Scalars['Int'];
};

export type WakeUpStatus = {
  __typename?: 'WakeUpStatus';
  status?: Maybe<Scalars['String']>;
};

export type Events = {
  __typename?: 'events';
  account_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  task?: Maybe<Scalars['String']>;
  task_type?: Maybe<Scalars['String']>;
};

export type GenericResponse = {
  __typename?: 'genericResponse';
  error?: Maybe<Scalars['AWSJSON']>;
  msg?: Maybe<Scalars['String']>;
  status: Scalars['Int'];
};

export type GetBestPracticeResponse = {
  __typename?: 'getBestPracticeResponse';
  error?: Maybe<Scalars['AWSJSON']>;
  msg: Scalars['String'];
  status: Scalars['Int'];
  uuid?: Maybe<Scalars['String']>;
};

export type GetControlMappingsResponse = {
  __typename?: 'getControlMappingsResponse';
  rules?: Maybe<Scalars['AWSJSON']>;
  status: Scalars['Int'];
};

export type GetExternalApiTokenResponse = {
  __typename?: 'getExternalApiTokenResponse';
  apiToken?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['AWSJSON']>;
  msg: Scalars['String'];
  status: Scalars['Int'];
};

export type GetRemediationResponse = {
  __typename?: 'getRemediationResponse';
  data?: Maybe<Scalars['AWSJSON']>;
  error?: Maybe<Scalars['AWSJSON']>;
  msg?: Maybe<Scalars['String']>;
  status: Scalars['Int'];
};

export type GetRemediationSettingsResult = {
  __typename?: 'getRemediationSettingsResult';
  automationApprovalFlow?: Maybe<Scalars['String']>;
  automationFlowType?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['AWSJSON']>;
  msg?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type GetResultResponse = {
  __typename?: 'getResultResponse';
  results?: Maybe<Scalars['AWSJSON']>;
  summary?: Maybe<Scalars['AWSJSON']>;
};

export type ListRemediationResponse = {
  __typename?: 'listRemediationResponse';
  error?: Maybe<Scalars['AWSJSON']>;
  msg?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['Int']>;
  results?: Maybe<Scalars['AWSJSON']>;
  status: Scalars['Int'];
};

export type SelfAssessmentResult = {
  __typename?: 'selfAssessmentResult';
  ControlName?: Maybe<Scalars['String']>;
  Met?: Maybe<Scalars['String']>;
  PartnerResponse?: Maybe<Scalars['String']>;
};

export type Wellarchitected = {
  __typename?: 'wellarchitected';
  WorkloadId?: Maybe<Scalars['String']>;
};

export type WellarchitectedCreate = {
  __typename?: 'wellarchitectedCreate';
  Status?: Maybe<Scalars['String']>;
  WorkloadId?: Maybe<Scalars['String']>;
};

export type WellarchitectedLens = {
  __typename?: 'wellarchitectedLens';
  PillarReviewSummaries?: Maybe<Array<Maybe<WellarchitectedPillar>>>;
};

export type WellarchitectedMilestone = {
  __typename?: 'wellarchitectedMilestone';
  MilestoneNumber?: Maybe<Scalars['Int']>;
  RiskCounts?: Maybe<WellarchitectedRisks>;
  WorkloadId?: Maybe<Scalars['String']>;
};

export type WellarchitectedMilestones = {
  __typename?: 'wellarchitectedMilestones';
  ImprovementStatus?: Maybe<Scalars['String']>;
  Lenses?: Maybe<Array<Maybe<Scalars['String']>>>;
  MilestoneName?: Maybe<Scalars['String']>;
  MilestoneNumber?: Maybe<Scalars['Int']>;
  Owner?: Maybe<Scalars['String']>;
  RecordedAt?: Maybe<Scalars['String']>;
  RiskCounts?: Maybe<WellarchitectedRisks>;
  WorkloadArn?: Maybe<Scalars['String']>;
  WorkloadId?: Maybe<Scalars['String']>;
  WorkloadName?: Maybe<Scalars['String']>;
};

export type WellarchitectedPillar = {
  __typename?: 'wellarchitectedPillar';
  PillarId?: Maybe<Scalars['String']>;
  PillarName?: Maybe<Scalars['String']>;
  RiskCounts?: Maybe<WellarchitectedRisks>;
};

export type WellarchitectedReport = {
  __typename?: 'wellarchitectedReport';
  Base64String?: Maybe<Scalars['String']>;
  LensAlias?: Maybe<Scalars['String']>;
  LensArn?: Maybe<Scalars['String']>;
  MilestoneNumber?: Maybe<Scalars['Int']>;
  WorkloadId?: Maybe<Scalars['String']>;
};

export type WellarchitectedRisks = {
  __typename?: 'wellarchitectedRisks';
  HIGH?: Maybe<Scalars['Int']>;
  MEDIUM?: Maybe<Scalars['Int']>;
  NONE?: Maybe<Scalars['Int']>;
  NOT_APPLICABLE?: Maybe<Scalars['Int']>;
  UNANSWERED?: Maybe<Scalars['Int']>;
};

export type WellarchitectedSingleWorkload = {
  __typename?: 'wellarchitectedSingleWorkload';
  AccountIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  AwsRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  Description?: Maybe<Scalars['String']>;
  Environment?: Maybe<Scalars['String']>;
  RiskCounts?: Maybe<WellarchitectedRisks>;
  UpdatedAt?: Maybe<Scalars['String']>;
  WorkloadArn?: Maybe<Scalars['String']>;
  WorkloadId?: Maybe<Scalars['String']>;
  WorkloadName?: Maybe<Scalars['String']>;
};

export type WellarchitectedWorkloads = {
  __typename?: 'wellarchitectedWorkloads';
  AccountIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  AwsCreditAccount?: Maybe<Scalars['String']>;
  AwsRegions?: Maybe<Array<Maybe<Scalars['String']>>>;
  AwsWafrAccount?: Maybe<Scalars['String']>;
  Description?: Maybe<Scalars['String']>;
  ImprovementStatus?: Maybe<Scalars['String']>;
  Lenses?: Maybe<Array<Maybe<Scalars['String']>>>;
  Owner?: Maybe<Scalars['String']>;
  RiskCounts?: Maybe<WellarchitectedRisks>;
  UpdatedAt?: Maybe<Scalars['String']>;
  WorkloadArn?: Maybe<Scalars['String']>;
  WorkloadId?: Maybe<Scalars['String']>;
  WorkloadName?: Maybe<Scalars['String']>;
};

export type GetUrlQueryVariables = Exact<{
  template: Scalars['String'];
}>;


export type GetUrlQuery = { __typename?: 'Query', getTemplateS3URL?: any | null };

export type GetControlsQueryVariables = Exact<{
  arn: Scalars['String'];
  connectedAccounts?: InputMaybe<Array<InputMaybe<ConnectedAccountInput>> | InputMaybe<ConnectedAccountInput>>;
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type GetControlsQuery = { __typename?: 'Query', getControls?: { __typename?: 'PaginatedSecurityControl', totalCount?: number | null, items: Array<{ __typename?: 'SecurityControl', compliancestatus: string, controldescription?: string | null, controlname?: string | null, customStandard?: Array<string | null> | null, alertstatus?: string | null, region?: string | null, remediatestatus?: string | null, remediationsteps?: string | null, select?: string | null, severity?: string | null, intrusive?: string | null, playbooks?: string | null, remediationUrl?: string | null, controlshlink?: string | null, controlAssociations?: Array<{ __typename?: 'ControlAssociation', control?: string | null, title?: string | null, description?: string | null, standard?: string | null } | null> | null }> } | null };

export type GetRedisDataQueryVariables = Exact<{
  key: Scalars['String'];
  move_to_cache: Scalars['Boolean'];
  new_key?: InputMaybe<Scalars['String']>;
}>;


export type GetRedisDataQuery = { __typename?: 'Query', getRedisData?: any | null };

export type GetFtrnpControlsQueryVariables = Exact<{
  arn: Scalars['String'];
}>;


export type GetFtrnpControlsQuery = { __typename?: 'Query', getFtrnpControls?: Array<{ __typename?: 'SecurityControl', compliancestatus: string, controldescription?: string | null, controlname?: string | null, customStandard?: Array<string | null> | null, alertstatus?: string | null, region?: string | null, remediatestatus?: string | null, select?: string | null, severity?: string | null, ftrcontrolname?: string | null, intrusive?: string | null, playbooks?: string | null, cdrcontrol?: string | null, cdrdescription?: string | null, isocontrol?: string | null, isodescription?: string | null, soccontrol?: string | null, socdescription?: string | null, pcicontrol?: string | null, pcidescription?: string | null, nistcontrol?: string | null, nistdescription?: string | null, watmappingcontrol?: string | null, watmappingdescription?: string | null, controlshlink?: string | null, customcontrol?: boolean | null, findings?: Array<{ __typename?: 'Finding', Id?: string | null, Title?: string | null, AwsAccountId?: string | null, RecordState?: string | null, Workflow?: { __typename?: 'Status', Status?: string | null } | null, Resources?: Array<{ __typename?: 'Resource', Id?: string | null, Region?: string | null } | null> | null, Compliance?: { __typename?: 'Status', Status?: string | null } | null }> | null } | null> | null };

export type GetSupportControlQueryVariables = Exact<{
  arn: Scalars['String'];
}>;


export type GetSupportControlQuery = { __typename?: 'Query', getSupportControl?: { __typename?: 'SecurityControl', compliancestatus: string, controldescription?: string | null, controlname?: string | null, customStandard?: Array<string | null> | null, alertstatus?: string | null, region?: string | null, remediatestatus?: string | null, select?: string | null, severity?: string | null, ftrcontrolname?: string | null, intrusive?: string | null, playbooks?: string | null, cdrcontrol?: string | null, cdrdescription?: string | null, isocontrol?: string | null, isodescription?: string | null, soccontrol?: string | null, socdescription?: string | null, pcicontrol?: string | null, pcidescription?: string | null, nistcontrol?: string | null, nistdescription?: string | null, watmappingcontrol?: string | null, watmappingdescription?: string | null, controlshlink?: string | null, findings?: Array<{ __typename?: 'Finding', Id?: string | null, Title?: string | null, AwsAccountId?: string | null, RecordState?: string | null, Workflow?: { __typename?: 'Status', Status?: string | null } | null, Resources?: Array<{ __typename?: 'Resource', Id?: string | null, Region?: string | null } | null> | null, Compliance?: { __typename?: 'Status', Status?: string | null } | null }> | null } | null };

export type GetControlsStatusQueryVariables = Exact<{
  arn: Scalars['String'];
  connectedAccounts?: InputMaybe<Array<InputMaybe<ConnectedAccountInput>> | InputMaybe<ConnectedAccountInput>>;
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type GetControlsStatusQuery = { __typename?: 'Query', getControlsStatus?: { __typename?: 'PaginatedSecurityControl', totalCount?: number | null, items: Array<{ __typename?: 'SecurityControl', compliancestatus: string, controldescription?: string | null, controlname?: string | null, customStandard?: Array<string | null> | null, alertstatus?: string | null, region?: string | null, remediatestatus?: string | null, remediationsteps?: string | null, select?: string | null, severity?: string | null, intrusive?: string | null, playbooks?: string | null, remediationUrl?: string | null, controlshlink?: string | null, controlAssociations?: Array<{ __typename?: 'ControlAssociation', control?: string | null, title?: string | null, description?: string | null, standard?: string | null } | null> | null }> } | null };

export type GetControlQueryVariables = Exact<{
  arn: Scalars['String'];
  controlname: Scalars['String'];
  connectedAccounts?: InputMaybe<Array<InputMaybe<ConnectedAccountInput>> | InputMaybe<ConnectedAccountInput>>;
}>;


export type GetControlQuery = { __typename?: 'Query', getControl?: { __typename?: 'SecurityControl', compliancestatus: string, controldescription?: string | null, controlname?: string | null, customStandard?: Array<string | null> | null, alertstatus?: string | null, region?: string | null, remediatestatus?: string | null, select?: string | null, severity?: string | null, intrusive?: string | null, playbooks?: string | null, controlshlink?: string | null, controlAssociations?: Array<{ __typename?: 'ControlAssociation', control?: string | null, title?: string | null, description?: string | null, standard?: string | null } | null> | null, findings?: Array<{ __typename?: 'Finding', Id?: string | null, Title?: string | null, AwsAccountId?: string | null, RecordState?: string | null, Workflow?: { __typename?: 'Status', Status?: string | null } | null, Resources?: Array<{ __typename?: 'Resource', Id?: string | null, Name?: string | null, Region?: string | null } | null> | null, Compliance?: { __typename?: 'Status', Status?: string | null } | null, ProductFields?: { __typename?: 'ProductField', RecommendationUrl?: string | null } | null }> | null } | null };

export type DeleteAccountMutationVariables = Exact<{
  arn: Scalars['String'];
}>;


export type DeleteAccountMutation = { __typename?: 'Mutation', deleteAccount: { __typename?: 'Account', arn?: string | null } };

export type ValidateAccountQueryVariables = Exact<{
  arn: Scalars['String'];
}>;


export type ValidateAccountQuery = { __typename?: 'Query', validateAccount: { __typename?: 'ValidateAccountResponse', status: number, msg: string, identity?: any | null } };

export type GetEventsQueryVariables = Exact<{
  nextToken?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
}>;


export type GetEventsQuery = { __typename?: 'Query', getEvents: { __typename?: 'PaginatedEvent', nextToken?: string | null, items: Array<{ __typename?: 'Event', organisation: string, finding_ref: string, account_ref?: string | null, account_aliasname?: string | null, control_ref?: string | null, description?: string | null, status?: string | null, created?: string | null }> } };

export type DeleteEventMutationVariables = Exact<{
  finding_ref: Scalars['String'];
}>;


export type DeleteEventMutation = { __typename?: 'Mutation', deleteEvent: { __typename?: 'Event', finding_ref: string } };

export type OnResultSubscriptionVariables = Exact<{
  id: Scalars['String'];
}>;


export type OnResultSubscription = { __typename?: 'Subscription', onResult?: { __typename?: 'Result', id: string, status: ResultStatus, result?: string | null } | null };

export type UpdateControlAlertStatusMutationVariables = Exact<{
  arn: Scalars['String'];
  controlname: Scalars['String'];
  action: Scalars['String'];
}>;


export type UpdateControlAlertStatusMutation = { __typename?: 'Mutation', updateControlAlertStatus?: { __typename?: 'UpdateResponse', status: boolean, msg?: string | null } | null };

export type InitiateManualRemediateMutationVariables = Exact<{
  arn: Scalars['String'];
  findingid: Scalars['String'];
}>;


export type InitiateManualRemediateMutation = { __typename?: 'Mutation', initiateManualRemediate?: { __typename?: 'UpdateResponse', status: boolean, msg?: string | null, id?: string | null } | null };

export type CreateAccountMutationVariables = Exact<{
  arn: Scalars['String'];
  accessnickname: Scalars['String'];
  accounttype: Scalars['String'];
  defaultregion: Scalars['String'];
  externalid: Scalars['String'];
}>;


export type CreateAccountMutation = { __typename?: 'Mutation', createAccount?: { __typename?: 'Result', id: string, status: ResultStatus, result?: string | null } | null };

export type GetLambdaCreationResultQueryVariables = Exact<{
  prompt: Scalars['String'];
  controlID?: InputMaybe<Scalars['String']>;
  customRemediation?: InputMaybe<Scalars['Boolean']>;
  arn: Scalars['String'];
}>;


export type GetLambdaCreationResultQuery = { __typename?: 'Query', getLambdaCreationResult?: { __typename?: 'AthenaLambdaCreateResponse', status: number, msg?: string | null, output?: string | null } | null };

export type GetCallerIdentityQueryVariables = Exact<{
  arn?: InputMaybe<Scalars['String']>;
}>;


export type GetCallerIdentityQuery = { __typename?: 'Query', getCallerIdentity: { __typename?: 'CallerIdentityResponse', UserId?: string | null, Account?: string | null, Arn?: string | null } };

export type GetRulesMappingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRulesMappingsQuery = { __typename?: 'Query', getRulesMappings?: { __typename?: 'RulesMappingsResponse', SECHUB_NIST_CONTROL?: any | null, SO_CONTROLS?: any | null, SECHUB_SOC_CONTROL?: any | null, SECHUB_ISO_CONTROL?: any | null, SECHUB_CDR_CONTROL?: any | null, FTR_SELF_ASSESSMENT?: any | null, DRS_CONTROLS?: any | null } | null };

export type CognitoAddOrgMutationVariables = Exact<{
  organisation: Scalars['String'];
}>;


export type CognitoAddOrgMutation = { __typename?: 'Mutation', cognitoAddOrg?: { __typename?: 'CognitoResponseGeneric', status: number, message?: string | null } | null };

export type GetCognitoUsersResultQueryVariables = Exact<{
  arn: Scalars['String'];
}>;


export type GetCognitoUsersResultQuery = { __typename?: 'Query', getCognitoUsersResult?: { __typename?: 'CognitoListUsersResponse', status: number, users?: Array<{ __typename?: 'CognitoUser', given_name: string, family_name: string, email: string, username: string } | null> | null } | null };

export type GetCognitoAccountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCognitoAccountQuery = { __typename?: 'Query', getCognitoAccount?: { __typename?: 'CognitoGetAccountResponse', status: number, emailAddress?: string | null, lastName?: string | null, firstName?: string | null, phoneNumber?: string | null, organisation?: string | null, username?: string | null } | null };

export type CognitoAddUserMutationVariables = Exact<{
  arn: Scalars['String'];
  first_name: Scalars['String'];
  last_name: Scalars['String'];
  email: Scalars['String'];
  phone_number: Scalars['String'];
}>;


export type CognitoAddUserMutation = { __typename?: 'Mutation', cognitoAddUser?: { __typename?: 'CognitoResponseGeneric', status: number, error?: any | null, message?: string | null } | null };

export type CognitoDeleteUserMutationVariables = Exact<{
  arn: Scalars['String'];
  username: Scalars['String'];
}>;


export type CognitoDeleteUserMutation = { __typename?: 'Mutation', cognitoDeleteUser?: { __typename?: 'CognitoResponseGeneric', status: number, error?: any | null, message?: string | null } | null };

export type CognitoEnableMfaMutationVariables = Exact<{
  method: Scalars['String'];
  usercode?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
  access_token: Scalars['String'];
}>;


export type CognitoEnableMfaMutation = { __typename?: 'Mutation', cognitoEnableMfa?: { __typename?: 'CognitoSetMfaResponse', status: number, message?: string | null, error?: any | null, SecretCode?: string | null } | null };

export type CognitoChangeUserPasswordMutationVariables = Exact<{
  arn: Scalars['String'];
  existing: Scalars['String'];
  newpwone: Scalars['String'];
  access_token: Scalars['String'];
}>;


export type CognitoChangeUserPasswordMutation = { __typename?: 'Mutation', cognitoChangeUserPassword?: { __typename?: 'CognitoResponseGeneric', status: number, error?: any | null, message?: string | null } | null };

export type AddIntegrationMutationVariables = Exact<{
  application: Scalars['String'];
  app_key?: InputMaybe<Scalars['String']>;
  app_value?: InputMaybe<Scalars['String']>;
  app_endpoint?: InputMaybe<Scalars['String']>;
}>;


export type AddIntegrationMutation = { __typename?: 'Mutation', addIntegration: { __typename?: 'Integration', organisation?: string | null, application?: string | null, app_key?: string | null, app_value?: string | null, app_endpoint?: string | null } };

export type DeleteIntegrationMutationVariables = Exact<{
  application: Scalars['String'];
}>;


export type DeleteIntegrationMutation = { __typename?: 'Mutation', deleteIntegration: { __typename?: 'Integration', organisation?: string | null, application?: string | null, app_key?: string | null, app_value?: string | null, app_endpoint?: string | null } };

export type GetIntegrationDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetIntegrationDetailsQuery = { __typename?: 'Query', getIntegrationDetails?: { __typename?: 'IntegrationDetailsResponse', status: number, error?: any | null, slackDetails?: { __typename?: 'Integration', organisation?: string | null, application?: string | null, app_key?: string | null, app_value?: string | null, app_endpoint?: string | null } | null, sentinelOneDetails?: { __typename?: 'Integration', organisation?: string | null, application?: string | null, app_key?: string | null, app_value?: string | null, app_endpoint?: string | null } | null, msTeamsDetails?: { __typename?: 'Integration', organisation?: string | null, application?: string | null, app_key?: string | null, app_value?: string | null, app_endpoint?: string | null } | null, googleChatDetails?: { __typename?: 'Integration', organisation?: string | null, application?: string | null, app_key?: string | null, app_value?: string | null, app_endpoint?: string | null } | null, jiraDetails?: { __typename?: 'Integration', organisation?: string | null, application?: string | null, app_key?: string | null, app_value?: string | null, app_endpoint?: string | null } | null } | null };

export type GetUserAccountsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserAccountsQuery = { __typename?: 'Query', getUserAccounts: { __typename?: 'PaginatedAccount', items: Array<{ __typename?: 'Account', organisation?: string | null, accessnickname?: string | null, arn?: string | null, defaultregion?: string | null, accounttype?: string | null, externalid?: string | null, sharrversion?: string | null, member_accounts?: Array<string | null> | null, member_regions?: Array<string | null> | null, connectedAccounts?: Array<{ __typename?: 'ConnectedAccount', accountIdRef?: string | null, region?: string | null } | null> | null }> } };

export type InitiateSharrDeployQueryVariables = Exact<{
  arn: Scalars['String'];
}>;


export type InitiateSharrDeployQuery = { __typename?: 'Query', initiateSharrDeploy?: string | null };

export type AutomateDeploymentStatusQueryVariables = Exact<{
  accountid?: InputMaybe<Scalars['String']>;
}>;


export type AutomateDeploymentStatusQuery = { __typename?: 'Query', automateDeploymentStatus?: { __typename?: 'DeploymentStatus', progress: number, executionArn?: string | null, accountid?: string | null } | null };

export type CreateWorkloadMutationVariables = Exact<{
  arn: Scalars['String'];
  workloadname: Scalars['String'];
  description: Scalars['String'];
  environment: Scalars['String'];
  accountAssessedId: Scalars['String'];
  awsregions?: InputMaybe<Scalars['String']>;
  reviewowner?: InputMaybe<Scalars['String']>;
  lenses: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
}>;


export type CreateWorkloadMutation = { __typename?: 'Mutation', createWorkload?: { __typename?: 'wellarchitectedCreate', WorkloadId?: string | null, Status?: string | null } | null };

export type CreateIacWorkloadMutationVariables = Exact<{
  workloadname: Scalars['String'];
  description: Scalars['String'];
  environment: Scalars['String'];
  awsregions?: InputMaybe<Scalars['String']>;
  reviewowner?: InputMaybe<Scalars['String']>;
  lenses: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  awsCreditAccount: Scalars['String'];
  awsTechnicalReviewAccount: Scalars['String'];
  awsWafrAccount: Scalars['String'];
}>;


export type CreateIacWorkloadMutation = { __typename?: 'Mutation', createIacWorkload?: { __typename?: 'wellarchitectedCreate', WorkloadId?: string | null, Status?: string | null } | null };

export type CreateMilestoneMutationVariables = Exact<{
  arn: Scalars['String'];
  workloadid: Scalars['String'];
  milestonename: Scalars['String'];
}>;


export type CreateMilestoneMutation = { __typename?: 'Mutation', createMilestone?: { __typename?: 'wellarchitectedMilestone', WorkloadId?: string | null, MilestoneNumber?: number | null, RiskCounts?: { __typename?: 'wellarchitectedRisks', UNANSWERED?: number | null, HIGH?: number | null, MEDIUM?: number | null, NONE?: number | null, NOT_APPLICABLE?: number | null } | null } | null };

export type CreateIacMilestoneMutationVariables = Exact<{
  workloadid: Scalars['String'];
  milestonename: Scalars['String'];
  awsWafrAccount: Scalars['String'];
}>;


export type CreateIacMilestoneMutation = { __typename?: 'Mutation', createIacMilestone?: { __typename?: 'wellarchitectedMilestone', WorkloadId?: string | null, MilestoneNumber?: number | null, RiskCounts?: { __typename?: 'wellarchitectedRisks', UNANSWERED?: number | null, HIGH?: number | null, MEDIUM?: number | null, NONE?: number | null, NOT_APPLICABLE?: number | null } | null } | null };

export type UpdateAnswerMutationVariables = Exact<{
  arn: Scalars['String'];
  targetAccountArn: Scalars['String'];
  workloadid: Scalars['String'];
  lens: Scalars['String'];
  connectedAccounts?: InputMaybe<Array<InputMaybe<ConnectedAccountInput>> | InputMaybe<ConnectedAccountInput>>;
}>;


export type UpdateAnswerMutation = { __typename?: 'Mutation', updateAnswer?: { __typename?: 'Result', id: string, status: ResultStatus, result?: string | null } | null };

export type UpdateIacAnswerMutationVariables = Exact<{
  workloadid: Scalars['String'];
  lens: Scalars['String'];
  awsWafrAccount: Scalars['String'];
  summaryId: Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>;
}>;


export type UpdateIacAnswerMutation = { __typename?: 'Mutation', updateIacAnswer?: { __typename?: 'Result', id: string, status: ResultStatus, result?: string | null } | null };

export type DeleteWorkloadMutationVariables = Exact<{
  arn: Scalars['String'];
  workloadid: Scalars['String'];
}>;


export type DeleteWorkloadMutation = { __typename?: 'Mutation', deleteWorkload?: { __typename?: 'wellarchitected', WorkloadId?: string | null } | null };

export type DeleteIacWorkloadMutationVariables = Exact<{
  workloadid: Scalars['String'];
  awsWafrAccount: Scalars['String'];
}>;


export type DeleteIacWorkloadMutation = { __typename?: 'Mutation', deleteIacWorkload?: { __typename?: 'wellarchitected', WorkloadId?: string | null } | null };

export type GetLensReviewReportQueryVariables = Exact<{
  arn: Scalars['String'];
  workloadid: Scalars['String'];
  lens: Scalars['String'];
  milestonenumber?: InputMaybe<Scalars['Int']>;
}>;


export type GetLensReviewReportQuery = { __typename?: 'Query', getLensReviewReport?: { __typename?: 'wellarchitectedReport', Base64String?: string | null } | null };

export type GetIacLensReviewReportQueryVariables = Exact<{
  workloadid: Scalars['String'];
  lens: Scalars['String'];
  milestonenumber?: InputMaybe<Scalars['Int']>;
  awsWafrAccount: Scalars['String'];
}>;


export type GetIacLensReviewReportQuery = { __typename?: 'Query', getIacLensReviewReport?: { __typename?: 'wellarchitectedReport', Base64String?: string | null } | null };

export type GetLensReviewQueryVariables = Exact<{
  arn: Scalars['String'];
  workloadid: Scalars['String'];
  lens: Scalars['String'];
}>;


export type GetLensReviewQuery = { __typename?: 'Query', getLensReview?: { __typename?: 'wellarchitectedLens', PillarReviewSummaries?: Array<{ __typename?: 'wellarchitectedPillar', PillarId?: string | null, PillarName?: string | null, RiskCounts?: { __typename?: 'wellarchitectedRisks', UNANSWERED?: number | null, HIGH?: number | null, MEDIUM?: number | null, NONE?: number | null, NOT_APPLICABLE?: number | null } | null } | null> | null } | null };

export type GetIacLensReviewQueryVariables = Exact<{
  workloadid: Scalars['String'];
  lens: Scalars['String'];
  awsWafrAccount: Scalars['String'];
}>;


export type GetIacLensReviewQuery = { __typename?: 'Query', getIacLensReview?: { __typename?: 'wellarchitectedLens', PillarReviewSummaries?: Array<{ __typename?: 'wellarchitectedPillar', PillarId?: string | null, PillarName?: string | null, RiskCounts?: { __typename?: 'wellarchitectedRisks', UNANSWERED?: number | null, HIGH?: number | null, MEDIUM?: number | null, NONE?: number | null, NOT_APPLICABLE?: number | null } | null } | null> | null } | null };

export type ListWorkloadsQueryVariables = Exact<{
  arn: Scalars['String'];
}>;


export type ListWorkloadsQuery = { __typename?: 'Query', listWorkloads?: { __typename?: 'PaginatedWorkloads', items: Array<{ __typename?: 'wellarchitectedWorkloads', WorkloadId?: string | null, WorkloadArn?: string | null, WorkloadName?: string | null, Owner?: string | null, UpdatedAt?: string | null, Lenses?: Array<string | null> | null, ImprovementStatus?: string | null, RiskCounts?: { __typename?: 'wellarchitectedRisks', UNANSWERED?: number | null, HIGH?: number | null, MEDIUM?: number | null, NONE?: number | null, NOT_APPLICABLE?: number | null } | null }> } | null };

export type ListIacWorkloadsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
}>;


export type ListIacWorkloadsQuery = { __typename?: 'Query', listIacWorkloads?: { __typename?: 'PaginatedWorkloads', items: Array<{ __typename?: 'wellarchitectedWorkloads', AwsCreditAccount?: string | null, AwsWafrAccount?: string | null, AccountIds?: Array<string | null> | null, WorkloadId?: string | null, WorkloadArn?: string | null, WorkloadName?: string | null, Description?: string | null, Owner?: string | null, UpdatedAt?: string | null, AwsRegions?: Array<string | null> | null, Lenses?: Array<string | null> | null, ImprovementStatus?: string | null, RiskCounts?: { __typename?: 'wellarchitectedRisks', UNANSWERED?: number | null, HIGH?: number | null, MEDIUM?: number | null, NONE?: number | null, NOT_APPLICABLE?: number | null } | null }> } | null };

export type ListMilestonesQueryVariables = Exact<{
  arn: Scalars['String'];
  workloadid: Scalars['String'];
}>;


export type ListMilestonesQuery = { __typename?: 'Query', listMilestones?: { __typename?: 'PaginatedMilestones', MilestoneSummaries: Array<{ __typename?: 'wellarchitectedMilestones', MilestoneNumber?: number | null, MilestoneName?: string | null, RecordedAt?: string | null, WorkloadId?: string | null, WorkloadArn?: string | null, WorkloadName?: string | null, Owner?: string | null, Lenses?: Array<string | null> | null, ImprovementStatus?: string | null, RiskCounts?: { __typename?: 'wellarchitectedRisks', UNANSWERED?: number | null, HIGH?: number | null, MEDIUM?: number | null, NONE?: number | null, NOT_APPLICABLE?: number | null } | null }> } | null };

export type ListIacMilestonesQueryVariables = Exact<{
  workloadid: Scalars['String'];
  awsWafrAccount: Scalars['String'];
}>;


export type ListIacMilestonesQuery = { __typename?: 'Query', listIacMilestones?: { __typename?: 'PaginatedMilestones', MilestoneSummaries: Array<{ __typename?: 'wellarchitectedMilestones', MilestoneNumber?: number | null, MilestoneName?: string | null, RecordedAt?: string | null, WorkloadId?: string | null, WorkloadArn?: string | null, WorkloadName?: string | null, Owner?: string | null, Lenses?: Array<string | null> | null, ImprovementStatus?: string | null, RiskCounts?: { __typename?: 'wellarchitectedRisks', UNANSWERED?: number | null, HIGH?: number | null, MEDIUM?: number | null, NONE?: number | null, NOT_APPLICABLE?: number | null } | null }> } | null };

export type ListIacBestPracticeControlsQueryVariables = Exact<{
  summaryId: Array<InputMaybe<Scalars['Int']>> | InputMaybe<Scalars['Int']>;
}>;


export type ListIacBestPracticeControlsQuery = { __typename?: 'Query', listIacBestPracticeControls?: { __typename?: 'IacBestPracticeControlsList', items: Array<{ __typename?: 'IacBestPracticeControls', checkRef?: string | null, description?: string | null, checkType?: string | null, resource?: string | null, overallFinding?: string | null } | null> } | null };

export type GetSelfAssessmentQueryVariables = Exact<{
  arn: Scalars['String'];
}>;


export type GetSelfAssessmentQuery = { __typename?: 'Query', getSelfAssessment?: { __typename?: 'SelfAssessmentList', items?: Array<{ __typename?: 'selfAssessmentResult', ControlName?: string | null, Met?: string | null, PartnerResponse?: string | null } | null> | null } | null };

export type DownloadSelfAssessmentQueryVariables = Exact<{
  arn: Scalars['String'];
}>;


export type DownloadSelfAssessmentQuery = { __typename?: 'Query', downloadSelfAssessment?: { __typename?: 'Result', id: string, status: ResultStatus, result?: string | null } | null };

export type GetWorkloadQueryVariables = Exact<{
  arn: Scalars['String'];
  WorkloadId: Scalars['String'];
}>;


export type GetWorkloadQuery = { __typename?: 'Query', getWorkload?: { __typename?: 'wellarchitectedSingleWorkload', WorkloadId?: string | null, WorkloadArn?: string | null, WorkloadName?: string | null, Description?: string | null, Environment?: string | null, UpdatedAt?: string | null, AccountIds?: Array<string | null> | null, AwsRegions?: Array<string | null> | null, RiskCounts?: { __typename?: 'wellarchitectedRisks', UNANSWERED?: number | null, HIGH?: number | null, MEDIUM?: number | null, NONE?: number | null, NOT_APPLICABLE?: number | null } | null } | null };

export type DownloadReportsQueryVariables = Exact<{
  arn: Scalars['String'];
  workloadname: Scalars['String'];
  workloadid: Scalars['String'];
  lens: Scalars['String'];
  milestonenumber?: InputMaybe<Scalars['Int']>;
}>;


export type DownloadReportsQuery = { __typename?: 'Query', downloadReports?: { __typename?: 'Result', id: string, status: ResultStatus, result?: string | null } | null };

export type DownloadIacReportsQueryVariables = Exact<{
  workloadname: Scalars['String'];
  workloadid: Scalars['String'];
  lens: Scalars['String'];
  milestonenumber?: InputMaybe<Scalars['Int']>;
  awsWafrAccount: Scalars['String'];
}>;


export type DownloadIacReportsQuery = { __typename?: 'Query', downloadIacReports?: { __typename?: 'Result', id: string, status: ResultStatus, result?: string | null } | null };

export type GetQuestionnaireTableQueryVariables = Exact<{
  arn: Scalars['String'];
  workloadid: Scalars['String'];
}>;


export type GetQuestionnaireTableQuery = { __typename?: 'Query', getQuestionnaireTable?: any | null };

export type GetIacQuestionnaireTableQueryVariables = Exact<{
  workloadid: Scalars['String'];
  awsWafrAccount: Scalars['String'];
}>;


export type GetIacQuestionnaireTableQuery = { __typename?: 'Query', getIacQuestionnaireTable?: any | null };

export type UpdateBestPracticeMutationVariables = Exact<{
  arn: Scalars['String'];
  workloadid: Scalars['String'];
  lens: Scalars['String'];
  controls?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;


export type UpdateBestPracticeMutation = { __typename?: 'Mutation', updateBestPractice?: { __typename?: 'Result', id: string, status: ResultStatus, result?: string | null } | null };

export type UpdateIacBestPracticeMutationVariables = Exact<{
  workloadid: Scalars['String'];
  lens: Scalars['String'];
  controls?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
  awsWafrAccount: Scalars['String'];
}>;


export type UpdateIacBestPracticeMutation = { __typename?: 'Mutation', updateIacBestPractice?: { __typename?: 'Result', id: string, status: ResultStatus, result?: string | null } | null };

export type UpdateIacQuestionnaireTableMutationVariables = Exact<{
  workloadid: Scalars['String'];
  questionid: Scalars['String'];
  answer: Scalars['String'];
  awsWafrAccount: Scalars['String'];
}>;


export type UpdateIacQuestionnaireTableMutation = { __typename?: 'Mutation', updateIacQuestionnaireTable?: { __typename?: 'Result', id: string, status: ResultStatus, result?: string | null } | null };

export type UpdateQuestionnaireTableMutationVariables = Exact<{
  arn: Scalars['String'];
  workloadid: Scalars['String'];
  questionid: Scalars['String'];
  answer: Scalars['String'];
}>;


export type UpdateQuestionnaireTableMutation = { __typename?: 'Mutation', updateQuestionnaireTable?: { __typename?: 'Result', id: string, status: ResultStatus, result?: string | null } | null };

export type GetFrameworkQuestionnaireQueryVariables = Exact<{
  arn: Scalars['String'];
  standard: Scalars['String'];
  frameworkName: Scalars['String'];
}>;


export type GetFrameworkQuestionnaireQuery = { __typename?: 'Query', getFrameworkQuestionnaire?: Array<{ __typename?: 'FrameworkQuestion', question_id?: string | null, question?: string | null, answer_id?: string | null, answers?: Array<string | null> | null, template?: string | null, questionnaire_answer?: string | null } | null> | null };

export type ListMultiFrameworkQueryVariables = Exact<{
  arn?: InputMaybe<Scalars['String']>;
}>;


export type ListMultiFrameworkQuery = { __typename?: 'Query', listMultiFramework?: { __typename?: 'PaginatedFrameworks', nextToken?: string | null, items: Array<{ __typename?: 'MultiFramework', frameworkName?: string | null, standard?: string | null, awsTechnicalReviewAccount?: string | null, connectedAccounts?: string | null, createdAt?: string | null }> } | null };

export type CreateMultiFrameworkMutationVariables = Exact<{
  arn: Scalars['String'];
  standard: Scalars['String'];
  frameworkName: Scalars['String'];
  awsTechnicalReviewAccount: Scalars['String'];
  connectedAccounts?: InputMaybe<Array<InputMaybe<ConnectedAccountInput>> | InputMaybe<ConnectedAccountInput>>;
}>;


export type CreateMultiFrameworkMutation = { __typename?: 'Mutation', createMultiFramework?: { __typename?: 'Result', id: string, status: ResultStatus, result?: string | null } | null };

export type UpdateMultiFrameworkQuestionnaireTableMutationVariables = Exact<{
  arn: Scalars['String'];
  standard: Scalars['String'];
  frameworkName: Scalars['String'];
  questionId: Scalars['String'];
  answer: Scalars['String'];
}>;


export type UpdateMultiFrameworkQuestionnaireTableMutation = { __typename?: 'Mutation', updateMultiFrameworkQuestionnaireTable?: { __typename?: 'Result', id: string, status: ResultStatus, result?: string | null } | null };

export type DeleteFrameworkMutationVariables = Exact<{
  arn: Scalars['String'];
  frameworkName: Scalars['String'];
}>;


export type DeleteFrameworkMutation = { __typename?: 'Mutation', deleteFramework?: { __typename?: 'Result', id: string, status: ResultStatus, result?: string | null } | null };

export type GetAccountQueryVariables = Exact<{
  arn: Scalars['String'];
}>;


export type GetAccountQuery = { __typename?: 'Query', getAccount: { __typename?: 'Account', organisation?: string | null, arn?: string | null, accessnickname?: string | null, externalid?: string | null, sharrversion?: string | null, defaultregion?: string | null, accounttype?: string | null, member_accounts?: Array<string | null> | null, member_regions?: Array<string | null> | null, connectedAccounts?: Array<{ __typename?: 'ConnectedAccount', accountIdRef?: string | null, region?: string | null } | null> | null } };

export type ToggleAutoRemediationMutationVariables = Exact<{
  arn: Scalars['String'];
  controlnames?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  action: Scalars['String'];
}>;


export type ToggleAutoRemediationMutation = { __typename?: 'Mutation', toggleAutoRemediation?: { __typename?: 'Result', id: string, status: ResultStatus, result?: string | null } | null };

export type GetTemplateEngineSecurityResponseQueryVariables = Exact<{
  processType: Scalars['String'];
  fileName?: InputMaybe<Scalars['String']>;
  fileAlias?: InputMaybe<Scalars['String']>;
  fileString?: InputMaybe<Scalars['String']>;
  summaryId?: InputMaybe<Scalars['String']>;
  summaryList?: InputMaybe<Scalars['String']>;
}>;


export type GetTemplateEngineSecurityResponseQuery = { __typename?: 'Query', getTemplateEngineSecurityResponse?: { __typename?: 'TemplateScannerUploadResponse', status: number, msg?: string | null, error?: any | null, result?: { __typename?: 'getResultResponse', results?: any | null, summary?: any | null } | null } | null };

export type GetTemplateEngineListTemplatesQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type GetTemplateEngineListTemplatesQuery = { __typename?: 'Query', getTemplateEngineListTemplates?: { __typename?: 'TemplateScannerListTemplatesResponse', status: number, msg?: string | null, error?: any | null, results?: any | null, page?: number | null } | null };

export type UserRollbackProcessQueryVariables = Exact<{
  organisation?: InputMaybe<Scalars['String']>;
  accessnickname?: InputMaybe<Scalars['String']>;
  arn?: InputMaybe<Scalars['String']>;
  externalid?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  keepSecHub?: InputMaybe<Scalars['String']>;
  keepConfig?: InputMaybe<Scalars['String']>;
}>;


export type UserRollbackProcessQuery = { __typename?: 'Query', userRollbackProcess?: { __typename?: 'ExecutionArn', executionArn?: string | null } | null };

export type UserSetupProcessQueryVariables = Exact<{
  organisation?: InputMaybe<Scalars['String']>;
  accessnickname?: InputMaybe<Scalars['String']>;
  arn?: InputMaybe<Scalars['String']>;
  externalid?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  accounttype?: InputMaybe<Scalars['String']>;
}>;


export type UserSetupProcessQuery = { __typename?: 'Query', userSetupProcess?: { __typename?: 'ExecutionArn', executionArn?: string | null } | null };

export type UserUpgradeProcessQueryVariables = Exact<{
  organisation?: InputMaybe<Scalars['String']>;
  accessnickname?: InputMaybe<Scalars['String']>;
  arn?: InputMaybe<Scalars['String']>;
  externalid?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  accounttype?: InputMaybe<Scalars['String']>;
}>;


export type UserUpgradeProcessQuery = { __typename?: 'Query', userUpgradeProcess?: { __typename?: 'ExecutionArn', executionArn?: string | null } | null };

export type AutomateProgressStatusQueryVariables = Exact<{
  accountid?: InputMaybe<Scalars['String']>;
  organisation?: InputMaybe<Scalars['String']>;
}>;


export type AutomateProgressStatusQuery = { __typename?: 'Query', automateProgressStatus?: { __typename?: 'DeploymentStatus', progress: number, executionArn?: string | null, status?: string | null, created_at?: string | null } | null };

export type GetUserDeployEventsQueryVariables = Exact<{
  organisation?: InputMaybe<Scalars['String']>;
}>;


export type GetUserDeployEventsQuery = { __typename?: 'Query', getUserDeployEvents?: { __typename?: 'PaginatedEvents', nextToken?: string | null, items: Array<{ __typename?: 'events', account_id?: string | null, task_type?: string | null, status?: string | null, created_at?: string | null, task?: string | null }> } | null };

export type GetQuestionnaireQueryVariables = Exact<{ [key: string]: never; }>;


export type GetQuestionnaireQuery = { __typename?: 'Query', getQuestionnaire?: Array<{ __typename?: 'Question', id?: string | null, description?: string | null } | null> | null };

export type SubmitQuestionnaireAnswerMutationVariables = Exact<{
  arn: Scalars['String'];
  workLoadId: Scalars['String'];
  questionId: Scalars['String'];
  questionAnswer: Scalars['String'];
  lensAlias: Scalars['String'];
}>;


export type SubmitQuestionnaireAnswerMutation = { __typename?: 'Mutation', submitQuestionnaireAnswer?: { __typename?: 'Result', id: string, status: ResultStatus, result?: string | null } | null };

export type SubmitIacQuestionnaireAnswerMutationVariables = Exact<{
  workLoadId: Scalars['String'];
  questionId: Scalars['String'];
  questionAnswer: Scalars['String'];
  lensAlias: Scalars['String'];
  awsWafrAccount: Scalars['String'];
}>;


export type SubmitIacQuestionnaireAnswerMutation = { __typename?: 'Mutation', submitIacQuestionnaireAnswer?: { __typename?: 'Result', id: string, status: ResultStatus, result?: string | null } | null };

export type SubmitEmailMutationVariables = Exact<{
  arn: Scalars['String'];
  workLoadId: Scalars['String'];
  stage: Scalars['String'];
}>;


export type SubmitEmailMutation = { __typename?: 'Mutation', submitEmail?: { __typename?: 'Result', id: string, status: ResultStatus, result?: string | null } | null };

export type SubmitIacEmailMutationVariables = Exact<{
  workLoadId: Scalars['String'];
  stage: Scalars['String'];
  awsWafrAccount: Scalars['String'];
}>;


export type SubmitIacEmailMutation = { __typename?: 'Mutation', submitIacEmail?: { __typename?: 'Result', id: string, status: ResultStatus, result?: string | null } | null };

export type SubmitFindingsEmailMutationVariables = Exact<{
  arn: Scalars['String'];
  connectedAccounts?: InputMaybe<Array<InputMaybe<ConnectedAccountInput>> | InputMaybe<ConnectedAccountInput>>;
  standard: Scalars['String'];
  compliancestatus: Scalars['String'];
  intrusive: Scalars['String'];
}>;


export type SubmitFindingsEmailMutation = { __typename?: 'Mutation', submitFindingsEmail?: { __typename?: 'Result', id: string, status: ResultStatus, result?: string | null } | null };

export type GetExternalApiTokenQueryVariables = Exact<{ [key: string]: never; }>;


export type GetExternalApiTokenQuery = { __typename?: 'Query', getExternalApiToken?: { __typename?: 'getExternalApiTokenResponse', status: number, msg: string, error?: any | null, apiToken?: string | null } | null };

export type GetBestPracticeQueryVariables = Exact<{
  checkType: Scalars['String'];
  resource: Scalars['String'];
  description: Scalars['String'];
  checkId: Scalars['String'];
  fileFormat: Scalars['String'];
}>;


export type GetBestPracticeQuery = { __typename?: 'Query', getBestPractice?: { __typename?: 'getBestPracticeResponse', status: number, msg: string, error?: any | null, uuid?: string | null } | null };

export type GetSecurityHubMembersQueryVariables = Exact<{
  arn: Scalars['String'];
}>;


export type GetSecurityHubMembersQuery = { __typename?: 'Query', getSecurityHubMembers?: Array<{ __typename?: 'SecurityHubMember', AccountId?: string | null, AdministratorId?: string | null } | null> | null };

export type StartStackSetDeploySfnMutationVariables = Exact<{
  arn: Scalars['String'];
  administratorRoleArn: Scalars['String'];
  executionRoleName: Scalars['String'];
  permissionModel: Scalars['String'];
  accountRefs?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
  regions?: InputMaybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type StartStackSetDeploySfnMutation = { __typename?: 'Mutation', startStackSetDeploySfn?: { __typename?: 'ExecutionArn', executionArn?: string | null } | null };

export type GetDashboardOverviewQueryVariables = Exact<{
  arn: Scalars['String'];
}>;


export type GetDashboardOverviewQuery = { __typename?: 'Query', getDashboardOverview?: { __typename?: 'DashboardOverview', totalItems?: number | null, totalCompliant?: number | null, totalFail?: number | null } | null };

export type GetDashboardControlsQueryVariables = Exact<{
  arn: Scalars['String'];
  standard: Scalars['String'];
}>;


export type GetDashboardControlsQuery = { __typename?: 'Query', getDashboardControls?: { __typename?: 'DashboardControl', customStandard?: string | null, dashboardResults?: Array<{ __typename?: 'DashboardResults', DISABLED?: number | null, FAILED?: number | null, PASSED?: number | null, PERCENT?: number | null, UNKNOWN?: number | null, DAY?: number | null } | null> | null } | null };

export type GetControlMappingsQueryVariables = Exact<{
  resultList: Scalars['String'];
}>;


export type GetControlMappingsQuery = { __typename?: 'Query', getControlMappings?: { __typename?: 'getControlMappingsResponse', status: number, rules?: any | null } | null };

export type StartStackSetRollbackSfnMutationVariables = Exact<{
  arn: Scalars['String'];
}>;


export type StartStackSetRollbackSfnMutation = { __typename?: 'Mutation', startStackSetRollbackSfn?: { __typename?: 'ExecutionArn', executionArn?: string | null } | null };

export type ListRemediationRequestsQueryVariables = Exact<{
  arn: Scalars['String'];
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
}>;


export type ListRemediationRequestsQuery = { __typename?: 'Query', listRemediationRequests?: { __typename?: 'listRemediationResponse', status: number, msg?: string | null, error?: any | null, results?: any | null, page?: number | null } | null };

export type ApproveRemediationRequestMutationVariables = Exact<{
  remediationId: Scalars['Int'];
  arn: Scalars['String'];
}>;


export type ApproveRemediationRequestMutation = { __typename?: 'Mutation', approveRemediationRequest?: { __typename?: 'genericResponse', status: number, msg?: string | null, error?: any | null } | null };

export type RejectRemediationRequestMutationVariables = Exact<{
  remediationId: Scalars['Int'];
  arn: Scalars['String'];
}>;


export type RejectRemediationRequestMutation = { __typename?: 'Mutation', rejectRemediationRequest?: { __typename?: 'genericResponse', status: number, msg?: string | null, error?: any | null } | null };

export type ScheduleRemediationRequestMutationVariables = Exact<{
  findingid: Scalars['String'];
  scheduledAt: Scalars['String'];
  timezone: Scalars['String'];
}>;


export type ScheduleRemediationRequestMutation = { __typename?: 'Mutation', scheduleRemediationRequest?: { __typename?: 'genericResponse', status: number, msg?: string | null, error?: any | null } | null };

export type InitiateRemediationRequestMutationVariables = Exact<{
  findingId: Scalars['String'];
}>;


export type InitiateRemediationRequestMutation = { __typename?: 'Mutation', initiateRemediationRequest?: { __typename?: 'genericResponse', status: number, msg?: string | null, error?: any | null } | null };

export type ManageRemediationSettingsMutationVariables = Exact<{
  arn: Scalars['String'];
  automationFlowType?: InputMaybe<Scalars['String']>;
  automationApprovalFlow?: InputMaybe<Scalars['String']>;
}>;


export type ManageRemediationSettingsMutation = { __typename?: 'Mutation', manageRemediationSettings?: { __typename?: 'Result', id: string, status: ResultStatus, result?: string | null } | null };

export type GetRemediationSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetRemediationSettingsQuery = { __typename?: 'Query', getRemediationSettings?: { __typename?: 'getRemediationSettingsResult', status: string, msg?: string | null, error?: any | null, automationFlowType?: string | null, automationApprovalFlow?: string | null } | null };

export type GetRemediationDataQueryVariables = Exact<{
  arn: Scalars['String'];
  remediationId: Scalars['Int'];
}>;


export type GetRemediationDataQuery = { __typename?: 'Query', getRemediationData?: { __typename?: 'getRemediationResponse', status: number, msg?: string | null, error?: any | null, data?: any | null } | null };

export type InitiateRemediationMutationVariables = Exact<{
  arn: Scalars['String'];
  findingId: Scalars['String'];
}>;


export type InitiateRemediationMutation = { __typename?: 'Mutation', initiateRemediation: { __typename?: 'RemediationResponse', status: string, findingId?: string | null, error?: string | null, template?: string | null } };

export type InitiateRemediationsMutationVariables = Exact<{
  arn: Scalars['String'];
  control: Scalars['String'];
  accountRef: Scalars['String'];
}>;


export type InitiateRemediationsMutation = { __typename?: 'Mutation', initiateRemediations?: Array<{ __typename?: 'RemediationResponse', status: string, findingId?: string | null, error?: string | null, template?: string | null }> | null };

export type DeleteRemediationMutationVariables = Exact<{
  remediationId: Scalars['String'];
  arn: Scalars['String'];
}>;


export type DeleteRemediationMutation = { __typename?: 'Mutation', deleteRemediation?: { __typename?: 'genericResponse', status: number, msg?: string | null, error?: any | null } | null };

export type StartMultiCloudwatchMutationVariables = Exact<{
  arn: Scalars['String'];
  input?: InputMaybe<MultiCloudwatchInput>;
}>;


export type StartMultiCloudwatchMutation = { __typename?: 'Mutation', startMultiCloudwatch?: { __typename?: 'StartMultiCloudwatchResult', executionArn?: string | null } | null };

export type GetMultiCloudwatchStateQueryVariables = Exact<{
  arn: Scalars['String'];
}>;


export type GetMultiCloudwatchStateQuery = { __typename?: 'Query', getMultiCloudwatchState?: { __typename?: 'MultiCloudwatchState', status: string, cloudTrails?: any | null, snsTopics?: any | null } | null };

export type GetRemediationStatusQueryVariables = Exact<{
  arn: Scalars['String'];
  executionArn: Scalars['String'];
}>;


export type GetRemediationStatusQuery = { __typename?: 'Query', getRemediationStatus?: { __typename?: 'RemediationStatus', status?: string | null, error?: string | null, tasks?: Array<{ __typename?: 'SfnTask', name: string, order: number, status: string, error?: string | null } | null> | null } | null };


export const GetUrlDocument = gql`
    query getURL($template: String!) {
  getTemplateS3URL(template: $template)
}
    `;

/**
 * __useGetUrlQuery__
 *
 * To run a query within a React component, call `useGetUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUrlQuery({
 *   variables: {
 *      template: // value for 'template'
 *   },
 * });
 */
export function useGetUrlQuery(baseOptions: Apollo.QueryHookOptions<GetUrlQuery, GetUrlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUrlQuery, GetUrlQueryVariables>(GetUrlDocument, options);
      }
export function useGetUrlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUrlQuery, GetUrlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUrlQuery, GetUrlQueryVariables>(GetUrlDocument, options);
        }
export type GetUrlQueryHookResult = ReturnType<typeof useGetUrlQuery>;
export type GetUrlLazyQueryHookResult = ReturnType<typeof useGetUrlLazyQuery>;
export type GetUrlQueryResult = Apollo.QueryResult<GetUrlQuery, GetUrlQueryVariables>;
export const GetControlsDocument = gql`
    query getControls($arn: String!, $connectedAccounts: [ConnectedAccountInput], $page: Int, $limit: Int) {
  getControls(
    arn: $arn
    connectedAccounts: $connectedAccounts
    page: $page
    limit: $limit
  ) {
    items {
      compliancestatus
      controldescription
      controlname
      customStandard
      controlAssociations {
        control
        title
        description
        standard
      }
      alertstatus
      region
      remediatestatus
      remediationsteps
      select
      severity
      intrusive
      playbooks
      remediationUrl
      controlshlink
    }
    totalCount
  }
}
    `;

/**
 * __useGetControlsQuery__
 *
 * To run a query within a React component, call `useGetControlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetControlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetControlsQuery({
 *   variables: {
 *      arn: // value for 'arn'
 *      connectedAccounts: // value for 'connectedAccounts'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetControlsQuery(baseOptions: Apollo.QueryHookOptions<GetControlsQuery, GetControlsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetControlsQuery, GetControlsQueryVariables>(GetControlsDocument, options);
      }
export function useGetControlsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetControlsQuery, GetControlsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetControlsQuery, GetControlsQueryVariables>(GetControlsDocument, options);
        }
export type GetControlsQueryHookResult = ReturnType<typeof useGetControlsQuery>;
export type GetControlsLazyQueryHookResult = ReturnType<typeof useGetControlsLazyQuery>;
export type GetControlsQueryResult = Apollo.QueryResult<GetControlsQuery, GetControlsQueryVariables>;
export const GetRedisDataDocument = gql`
    query getRedisData($key: String!, $move_to_cache: Boolean!, $new_key: String) {
  getRedisData(key: $key, move_to_cache: $move_to_cache, new_key: $new_key)
}
    `;

/**
 * __useGetRedisDataQuery__
 *
 * To run a query within a React component, call `useGetRedisDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRedisDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRedisDataQuery({
 *   variables: {
 *      key: // value for 'key'
 *      move_to_cache: // value for 'move_to_cache'
 *      new_key: // value for 'new_key'
 *   },
 * });
 */
export function useGetRedisDataQuery(baseOptions: Apollo.QueryHookOptions<GetRedisDataQuery, GetRedisDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRedisDataQuery, GetRedisDataQueryVariables>(GetRedisDataDocument, options);
      }
export function useGetRedisDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRedisDataQuery, GetRedisDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRedisDataQuery, GetRedisDataQueryVariables>(GetRedisDataDocument, options);
        }
export type GetRedisDataQueryHookResult = ReturnType<typeof useGetRedisDataQuery>;
export type GetRedisDataLazyQueryHookResult = ReturnType<typeof useGetRedisDataLazyQuery>;
export type GetRedisDataQueryResult = Apollo.QueryResult<GetRedisDataQuery, GetRedisDataQueryVariables>;
export const GetFtrnpControlsDocument = gql`
    query getFtrnpControls($arn: String!) {
  getFtrnpControls(arn: $arn) {
    compliancestatus
    controldescription
    controlname
    customStandard
    alertstatus
    region
    remediatestatus
    select
    severity
    ftrcontrolname
    intrusive
    playbooks
    cdrcontrol
    cdrdescription
    isocontrol
    isodescription
    soccontrol
    socdescription
    pcicontrol
    pcidescription
    nistcontrol
    nistdescription
    watmappingcontrol
    watmappingdescription
    controlshlink
    customcontrol
    findings {
      Id
      Workflow {
        Status
      }
      Resources {
        Id
        Region
      }
      Title
      AwsAccountId
      RecordState
      Compliance {
        Status
      }
    }
  }
}
    `;

/**
 * __useGetFtrnpControlsQuery__
 *
 * To run a query within a React component, call `useGetFtrnpControlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFtrnpControlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFtrnpControlsQuery({
 *   variables: {
 *      arn: // value for 'arn'
 *   },
 * });
 */
export function useGetFtrnpControlsQuery(baseOptions: Apollo.QueryHookOptions<GetFtrnpControlsQuery, GetFtrnpControlsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFtrnpControlsQuery, GetFtrnpControlsQueryVariables>(GetFtrnpControlsDocument, options);
      }
export function useGetFtrnpControlsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFtrnpControlsQuery, GetFtrnpControlsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFtrnpControlsQuery, GetFtrnpControlsQueryVariables>(GetFtrnpControlsDocument, options);
        }
export type GetFtrnpControlsQueryHookResult = ReturnType<typeof useGetFtrnpControlsQuery>;
export type GetFtrnpControlsLazyQueryHookResult = ReturnType<typeof useGetFtrnpControlsLazyQuery>;
export type GetFtrnpControlsQueryResult = Apollo.QueryResult<GetFtrnpControlsQuery, GetFtrnpControlsQueryVariables>;
export const GetSupportControlDocument = gql`
    query getSupportControl($arn: String!) {
  getSupportControl(arn: $arn) {
    compliancestatus
    controldescription
    controlname
    customStandard
    alertstatus
    region
    remediatestatus
    select
    severity
    ftrcontrolname
    intrusive
    playbooks
    cdrcontrol
    cdrdescription
    isocontrol
    isodescription
    soccontrol
    socdescription
    pcicontrol
    pcidescription
    nistcontrol
    nistdescription
    watmappingcontrol
    watmappingdescription
    controlshlink
    findings {
      Id
      Workflow {
        Status
      }
      Resources {
        Id
        Region
      }
      Title
      AwsAccountId
      RecordState
      Compliance {
        Status
      }
    }
  }
}
    `;

/**
 * __useGetSupportControlQuery__
 *
 * To run a query within a React component, call `useGetSupportControlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSupportControlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSupportControlQuery({
 *   variables: {
 *      arn: // value for 'arn'
 *   },
 * });
 */
export function useGetSupportControlQuery(baseOptions: Apollo.QueryHookOptions<GetSupportControlQuery, GetSupportControlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSupportControlQuery, GetSupportControlQueryVariables>(GetSupportControlDocument, options);
      }
export function useGetSupportControlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSupportControlQuery, GetSupportControlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSupportControlQuery, GetSupportControlQueryVariables>(GetSupportControlDocument, options);
        }
export type GetSupportControlQueryHookResult = ReturnType<typeof useGetSupportControlQuery>;
export type GetSupportControlLazyQueryHookResult = ReturnType<typeof useGetSupportControlLazyQuery>;
export type GetSupportControlQueryResult = Apollo.QueryResult<GetSupportControlQuery, GetSupportControlQueryVariables>;
export const GetControlsStatusDocument = gql`
    query getControlsStatus($arn: String!, $connectedAccounts: [ConnectedAccountInput], $page: Int, $limit: Int) {
  getControlsStatus(
    arn: $arn
    connectedAccounts: $connectedAccounts
    page: $page
    limit: $limit
  ) {
    items {
      compliancestatus
      controldescription
      controlname
      customStandard
      controlAssociations {
        control
        title
        description
        standard
      }
      alertstatus
      region
      remediatestatus
      remediationsteps
      select
      severity
      intrusive
      playbooks
      remediationUrl
      controlshlink
    }
    totalCount
  }
}
    `;

/**
 * __useGetControlsStatusQuery__
 *
 * To run a query within a React component, call `useGetControlsStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetControlsStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetControlsStatusQuery({
 *   variables: {
 *      arn: // value for 'arn'
 *      connectedAccounts: // value for 'connectedAccounts'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetControlsStatusQuery(baseOptions: Apollo.QueryHookOptions<GetControlsStatusQuery, GetControlsStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetControlsStatusQuery, GetControlsStatusQueryVariables>(GetControlsStatusDocument, options);
      }
export function useGetControlsStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetControlsStatusQuery, GetControlsStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetControlsStatusQuery, GetControlsStatusQueryVariables>(GetControlsStatusDocument, options);
        }
export type GetControlsStatusQueryHookResult = ReturnType<typeof useGetControlsStatusQuery>;
export type GetControlsStatusLazyQueryHookResult = ReturnType<typeof useGetControlsStatusLazyQuery>;
export type GetControlsStatusQueryResult = Apollo.QueryResult<GetControlsStatusQuery, GetControlsStatusQueryVariables>;
export const GetControlDocument = gql`
    query getControl($arn: String!, $controlname: String!, $connectedAccounts: [ConnectedAccountInput]) {
  getControl(
    arn: $arn
    controlname: $controlname
    connectedAccounts: $connectedAccounts
  ) {
    compliancestatus
    controldescription
    controlname
    customStandard
    controlAssociations {
      control
      title
      description
      standard
    }
    findings {
      Id
      Workflow {
        Status
      }
      Resources {
        Id
        Name
        Region
      }
      Title
      AwsAccountId
      RecordState
      Compliance {
        Status
      }
      ProductFields {
        RecommendationUrl
      }
    }
    alertstatus
    region
    remediatestatus
    select
    severity
    intrusive
    playbooks
    controlshlink
  }
}
    `;

/**
 * __useGetControlQuery__
 *
 * To run a query within a React component, call `useGetControlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetControlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetControlQuery({
 *   variables: {
 *      arn: // value for 'arn'
 *      controlname: // value for 'controlname'
 *      connectedAccounts: // value for 'connectedAccounts'
 *   },
 * });
 */
export function useGetControlQuery(baseOptions: Apollo.QueryHookOptions<GetControlQuery, GetControlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetControlQuery, GetControlQueryVariables>(GetControlDocument, options);
      }
export function useGetControlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetControlQuery, GetControlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetControlQuery, GetControlQueryVariables>(GetControlDocument, options);
        }
export type GetControlQueryHookResult = ReturnType<typeof useGetControlQuery>;
export type GetControlLazyQueryHookResult = ReturnType<typeof useGetControlLazyQuery>;
export type GetControlQueryResult = Apollo.QueryResult<GetControlQuery, GetControlQueryVariables>;
export const DeleteAccountDocument = gql`
    mutation deleteAccount($arn: String!) @api(contextKey: "apiName") {
  deleteAccount(arn: $arn) {
    arn
  }
}
    `;
export type DeleteAccountMutationFn = Apollo.MutationFunction<DeleteAccountMutation, DeleteAccountMutationVariables>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *      arn: // value for 'arn'
 *   },
 * });
 */
export function useDeleteAccountMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAccountMutation, DeleteAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAccountMutation, DeleteAccountMutationVariables>(DeleteAccountDocument, options);
      }
export type DeleteAccountMutationHookResult = ReturnType<typeof useDeleteAccountMutation>;
export type DeleteAccountMutationResult = Apollo.MutationResult<DeleteAccountMutation>;
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<DeleteAccountMutation, DeleteAccountMutationVariables>;
export const ValidateAccountDocument = gql`
    query validateAccount($arn: String!) {
  validateAccount(arn: $arn) {
    status
    msg
    identity
  }
}
    `;

/**
 * __useValidateAccountQuery__
 *
 * To run a query within a React component, call `useValidateAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidateAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidateAccountQuery({
 *   variables: {
 *      arn: // value for 'arn'
 *   },
 * });
 */
export function useValidateAccountQuery(baseOptions: Apollo.QueryHookOptions<ValidateAccountQuery, ValidateAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidateAccountQuery, ValidateAccountQueryVariables>(ValidateAccountDocument, options);
      }
export function useValidateAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidateAccountQuery, ValidateAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidateAccountQuery, ValidateAccountQueryVariables>(ValidateAccountDocument, options);
        }
export type ValidateAccountQueryHookResult = ReturnType<typeof useValidateAccountQuery>;
export type ValidateAccountLazyQueryHookResult = ReturnType<typeof useValidateAccountLazyQuery>;
export type ValidateAccountQueryResult = Apollo.QueryResult<ValidateAccountQuery, ValidateAccountQueryVariables>;
export const GetEventsDocument = gql`
    query getEvents($nextToken: String, $page: Int) {
  getEvents(page: $page, nextToken: $nextToken) {
    items {
      organisation
      finding_ref
      account_ref
      account_aliasname
      control_ref
      description
      status
      created
    }
    nextToken
  }
}
    `;

/**
 * __useGetEventsQuery__
 *
 * To run a query within a React component, call `useGetEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventsQuery({
 *   variables: {
 *      nextToken: // value for 'nextToken'
 *      page: // value for 'page'
 *   },
 * });
 */
export function useGetEventsQuery(baseOptions?: Apollo.QueryHookOptions<GetEventsQuery, GetEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEventsQuery, GetEventsQueryVariables>(GetEventsDocument, options);
      }
export function useGetEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEventsQuery, GetEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEventsQuery, GetEventsQueryVariables>(GetEventsDocument, options);
        }
export type GetEventsQueryHookResult = ReturnType<typeof useGetEventsQuery>;
export type GetEventsLazyQueryHookResult = ReturnType<typeof useGetEventsLazyQuery>;
export type GetEventsQueryResult = Apollo.QueryResult<GetEventsQuery, GetEventsQueryVariables>;
export const DeleteEventDocument = gql`
    mutation deleteEvent($finding_ref: String!) {
  deleteEvent(finding_ref: $finding_ref) {
    finding_ref
  }
}
    `;
export type DeleteEventMutationFn = Apollo.MutationFunction<DeleteEventMutation, DeleteEventMutationVariables>;

/**
 * __useDeleteEventMutation__
 *
 * To run a mutation, you first call `useDeleteEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventMutation, { data, loading, error }] = useDeleteEventMutation({
 *   variables: {
 *      finding_ref: // value for 'finding_ref'
 *   },
 * });
 */
export function useDeleteEventMutation(baseOptions?: Apollo.MutationHookOptions<DeleteEventMutation, DeleteEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteEventMutation, DeleteEventMutationVariables>(DeleteEventDocument, options);
      }
export type DeleteEventMutationHookResult = ReturnType<typeof useDeleteEventMutation>;
export type DeleteEventMutationResult = Apollo.MutationResult<DeleteEventMutation>;
export type DeleteEventMutationOptions = Apollo.BaseMutationOptions<DeleteEventMutation, DeleteEventMutationVariables>;
export const OnResultDocument = gql`
    subscription onResult($id: String!) {
  onResult(id: $id) {
    id
    status
    result
  }
}
    `;

/**
 * __useOnResultSubscription__
 *
 * To run a query within a React component, call `useOnResultSubscription` and pass it any options that fit your needs.
 * When your component renders, `useOnResultSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOnResultSubscription({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useOnResultSubscription(baseOptions: Apollo.SubscriptionHookOptions<OnResultSubscription, OnResultSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<OnResultSubscription, OnResultSubscriptionVariables>(OnResultDocument, options);
      }
export type OnResultSubscriptionHookResult = ReturnType<typeof useOnResultSubscription>;
export type OnResultSubscriptionResult = Apollo.SubscriptionResult<OnResultSubscription>;
export const UpdateControlAlertStatusDocument = gql`
    mutation updateControlAlertStatus($arn: String!, $controlname: String!, $action: String!) {
  updateControlAlertStatus(arn: $arn, controlname: $controlname, action: $action) {
    status
    msg
  }
}
    `;
export type UpdateControlAlertStatusMutationFn = Apollo.MutationFunction<UpdateControlAlertStatusMutation, UpdateControlAlertStatusMutationVariables>;

/**
 * __useUpdateControlAlertStatusMutation__
 *
 * To run a mutation, you first call `useUpdateControlAlertStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateControlAlertStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateControlAlertStatusMutation, { data, loading, error }] = useUpdateControlAlertStatusMutation({
 *   variables: {
 *      arn: // value for 'arn'
 *      controlname: // value for 'controlname'
 *      action: // value for 'action'
 *   },
 * });
 */
export function useUpdateControlAlertStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateControlAlertStatusMutation, UpdateControlAlertStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateControlAlertStatusMutation, UpdateControlAlertStatusMutationVariables>(UpdateControlAlertStatusDocument, options);
      }
export type UpdateControlAlertStatusMutationHookResult = ReturnType<typeof useUpdateControlAlertStatusMutation>;
export type UpdateControlAlertStatusMutationResult = Apollo.MutationResult<UpdateControlAlertStatusMutation>;
export type UpdateControlAlertStatusMutationOptions = Apollo.BaseMutationOptions<UpdateControlAlertStatusMutation, UpdateControlAlertStatusMutationVariables>;
export const InitiateManualRemediateDocument = gql`
    mutation initiateManualRemediate($arn: String!, $findingid: String!) {
  initiateManualRemediate(arn: $arn, findingid: $findingid) {
    status
    msg
    id
  }
}
    `;
export type InitiateManualRemediateMutationFn = Apollo.MutationFunction<InitiateManualRemediateMutation, InitiateManualRemediateMutationVariables>;

/**
 * __useInitiateManualRemediateMutation__
 *
 * To run a mutation, you first call `useInitiateManualRemediateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateManualRemediateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateManualRemediateMutation, { data, loading, error }] = useInitiateManualRemediateMutation({
 *   variables: {
 *      arn: // value for 'arn'
 *      findingid: // value for 'findingid'
 *   },
 * });
 */
export function useInitiateManualRemediateMutation(baseOptions?: Apollo.MutationHookOptions<InitiateManualRemediateMutation, InitiateManualRemediateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitiateManualRemediateMutation, InitiateManualRemediateMutationVariables>(InitiateManualRemediateDocument, options);
      }
export type InitiateManualRemediateMutationHookResult = ReturnType<typeof useInitiateManualRemediateMutation>;
export type InitiateManualRemediateMutationResult = Apollo.MutationResult<InitiateManualRemediateMutation>;
export type InitiateManualRemediateMutationOptions = Apollo.BaseMutationOptions<InitiateManualRemediateMutation, InitiateManualRemediateMutationVariables>;
export const CreateAccountDocument = gql`
    mutation createAccount($arn: String!, $accessnickname: String!, $accounttype: String!, $defaultregion: String!, $externalid: String!) @api(contextKey: "apiName") {
  createAccount(
    input: {arn: $arn, accessnickname: $accessnickname, externalid: $externalid, defaultregion: $defaultregion, accounttype: $accounttype}
  ) {
    id
    status
    result
  }
}
    `;
export type CreateAccountMutationFn = Apollo.MutationFunction<CreateAccountMutation, CreateAccountMutationVariables>;

/**
 * __useCreateAccountMutation__
 *
 * To run a mutation, you first call `useCreateAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccountMutation, { data, loading, error }] = useCreateAccountMutation({
 *   variables: {
 *      arn: // value for 'arn'
 *      accessnickname: // value for 'accessnickname'
 *      accounttype: // value for 'accounttype'
 *      defaultregion: // value for 'defaultregion'
 *      externalid: // value for 'externalid'
 *   },
 * });
 */
export function useCreateAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateAccountMutation, CreateAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAccountMutation, CreateAccountMutationVariables>(CreateAccountDocument, options);
      }
export type CreateAccountMutationHookResult = ReturnType<typeof useCreateAccountMutation>;
export type CreateAccountMutationResult = Apollo.MutationResult<CreateAccountMutation>;
export type CreateAccountMutationOptions = Apollo.BaseMutationOptions<CreateAccountMutation, CreateAccountMutationVariables>;
export const GetLambdaCreationResultDocument = gql`
    query getLambdaCreationResult($prompt: String!, $controlID: String, $customRemediation: Boolean, $arn: String!) {
  getLambdaCreationResult(
    prompt: $prompt
    controlID: $controlID
    customRemediation: $customRemediation
    arn: $arn
  ) {
    status
    msg
    output
  }
}
    `;

/**
 * __useGetLambdaCreationResultQuery__
 *
 * To run a query within a React component, call `useGetLambdaCreationResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLambdaCreationResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLambdaCreationResultQuery({
 *   variables: {
 *      prompt: // value for 'prompt'
 *      controlID: // value for 'controlID'
 *      customRemediation: // value for 'customRemediation'
 *      arn: // value for 'arn'
 *   },
 * });
 */
export function useGetLambdaCreationResultQuery(baseOptions: Apollo.QueryHookOptions<GetLambdaCreationResultQuery, GetLambdaCreationResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLambdaCreationResultQuery, GetLambdaCreationResultQueryVariables>(GetLambdaCreationResultDocument, options);
      }
export function useGetLambdaCreationResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLambdaCreationResultQuery, GetLambdaCreationResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLambdaCreationResultQuery, GetLambdaCreationResultQueryVariables>(GetLambdaCreationResultDocument, options);
        }
export type GetLambdaCreationResultQueryHookResult = ReturnType<typeof useGetLambdaCreationResultQuery>;
export type GetLambdaCreationResultLazyQueryHookResult = ReturnType<typeof useGetLambdaCreationResultLazyQuery>;
export type GetLambdaCreationResultQueryResult = Apollo.QueryResult<GetLambdaCreationResultQuery, GetLambdaCreationResultQueryVariables>;
export const GetCallerIdentityDocument = gql`
    query getCallerIdentity($arn: String) {
  getCallerIdentity(arn: $arn) {
    UserId
    Account
    Arn
  }
}
    `;

/**
 * __useGetCallerIdentityQuery__
 *
 * To run a query within a React component, call `useGetCallerIdentityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCallerIdentityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCallerIdentityQuery({
 *   variables: {
 *      arn: // value for 'arn'
 *   },
 * });
 */
export function useGetCallerIdentityQuery(baseOptions?: Apollo.QueryHookOptions<GetCallerIdentityQuery, GetCallerIdentityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCallerIdentityQuery, GetCallerIdentityQueryVariables>(GetCallerIdentityDocument, options);
      }
export function useGetCallerIdentityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCallerIdentityQuery, GetCallerIdentityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCallerIdentityQuery, GetCallerIdentityQueryVariables>(GetCallerIdentityDocument, options);
        }
export type GetCallerIdentityQueryHookResult = ReturnType<typeof useGetCallerIdentityQuery>;
export type GetCallerIdentityLazyQueryHookResult = ReturnType<typeof useGetCallerIdentityLazyQuery>;
export type GetCallerIdentityQueryResult = Apollo.QueryResult<GetCallerIdentityQuery, GetCallerIdentityQueryVariables>;
export const GetRulesMappingsDocument = gql`
    query getRulesMappings {
  getRulesMappings {
    SECHUB_NIST_CONTROL
    SO_CONTROLS
    SECHUB_SOC_CONTROL
    SECHUB_ISO_CONTROL
    SECHUB_CDR_CONTROL
    FTR_SELF_ASSESSMENT
    DRS_CONTROLS
  }
}
    `;

/**
 * __useGetRulesMappingsQuery__
 *
 * To run a query within a React component, call `useGetRulesMappingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRulesMappingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRulesMappingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRulesMappingsQuery(baseOptions?: Apollo.QueryHookOptions<GetRulesMappingsQuery, GetRulesMappingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRulesMappingsQuery, GetRulesMappingsQueryVariables>(GetRulesMappingsDocument, options);
      }
export function useGetRulesMappingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRulesMappingsQuery, GetRulesMappingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRulesMappingsQuery, GetRulesMappingsQueryVariables>(GetRulesMappingsDocument, options);
        }
export type GetRulesMappingsQueryHookResult = ReturnType<typeof useGetRulesMappingsQuery>;
export type GetRulesMappingsLazyQueryHookResult = ReturnType<typeof useGetRulesMappingsLazyQuery>;
export type GetRulesMappingsQueryResult = Apollo.QueryResult<GetRulesMappingsQuery, GetRulesMappingsQueryVariables>;
export const CognitoAddOrgDocument = gql`
    mutation cognitoAddOrg($organisation: String!) {
  cognitoAddOrg(organisation: $organisation) {
    status
    message
  }
}
    `;
export type CognitoAddOrgMutationFn = Apollo.MutationFunction<CognitoAddOrgMutation, CognitoAddOrgMutationVariables>;

/**
 * __useCognitoAddOrgMutation__
 *
 * To run a mutation, you first call `useCognitoAddOrgMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCognitoAddOrgMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cognitoAddOrgMutation, { data, loading, error }] = useCognitoAddOrgMutation({
 *   variables: {
 *      organisation: // value for 'organisation'
 *   },
 * });
 */
export function useCognitoAddOrgMutation(baseOptions?: Apollo.MutationHookOptions<CognitoAddOrgMutation, CognitoAddOrgMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CognitoAddOrgMutation, CognitoAddOrgMutationVariables>(CognitoAddOrgDocument, options);
      }
export type CognitoAddOrgMutationHookResult = ReturnType<typeof useCognitoAddOrgMutation>;
export type CognitoAddOrgMutationResult = Apollo.MutationResult<CognitoAddOrgMutation>;
export type CognitoAddOrgMutationOptions = Apollo.BaseMutationOptions<CognitoAddOrgMutation, CognitoAddOrgMutationVariables>;
export const GetCognitoUsersResultDocument = gql`
    query getCognitoUsersResult($arn: String!) {
  getCognitoUsersResult(arn: $arn) {
    status
    users {
      given_name
      family_name
      email
      username
    }
  }
}
    `;

/**
 * __useGetCognitoUsersResultQuery__
 *
 * To run a query within a React component, call `useGetCognitoUsersResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCognitoUsersResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCognitoUsersResultQuery({
 *   variables: {
 *      arn: // value for 'arn'
 *   },
 * });
 */
export function useGetCognitoUsersResultQuery(baseOptions: Apollo.QueryHookOptions<GetCognitoUsersResultQuery, GetCognitoUsersResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCognitoUsersResultQuery, GetCognitoUsersResultQueryVariables>(GetCognitoUsersResultDocument, options);
      }
export function useGetCognitoUsersResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCognitoUsersResultQuery, GetCognitoUsersResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCognitoUsersResultQuery, GetCognitoUsersResultQueryVariables>(GetCognitoUsersResultDocument, options);
        }
export type GetCognitoUsersResultQueryHookResult = ReturnType<typeof useGetCognitoUsersResultQuery>;
export type GetCognitoUsersResultLazyQueryHookResult = ReturnType<typeof useGetCognitoUsersResultLazyQuery>;
export type GetCognitoUsersResultQueryResult = Apollo.QueryResult<GetCognitoUsersResultQuery, GetCognitoUsersResultQueryVariables>;
export const GetCognitoAccountDocument = gql`
    query getCognitoAccount {
  getCognitoAccount {
    status
    emailAddress
    lastName
    firstName
    phoneNumber
    organisation
    username
  }
}
    `;

/**
 * __useGetCognitoAccountQuery__
 *
 * To run a query within a React component, call `useGetCognitoAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCognitoAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCognitoAccountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCognitoAccountQuery(baseOptions?: Apollo.QueryHookOptions<GetCognitoAccountQuery, GetCognitoAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCognitoAccountQuery, GetCognitoAccountQueryVariables>(GetCognitoAccountDocument, options);
      }
export function useGetCognitoAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCognitoAccountQuery, GetCognitoAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCognitoAccountQuery, GetCognitoAccountQueryVariables>(GetCognitoAccountDocument, options);
        }
export type GetCognitoAccountQueryHookResult = ReturnType<typeof useGetCognitoAccountQuery>;
export type GetCognitoAccountLazyQueryHookResult = ReturnType<typeof useGetCognitoAccountLazyQuery>;
export type GetCognitoAccountQueryResult = Apollo.QueryResult<GetCognitoAccountQuery, GetCognitoAccountQueryVariables>;
export const CognitoAddUserDocument = gql`
    mutation cognitoAddUser($arn: String!, $first_name: String!, $last_name: String!, $email: String!, $phone_number: String!) {
  cognitoAddUser(
    arn: $arn
    first_name: $first_name
    last_name: $last_name
    email: $email
    phone_number: $phone_number
  ) {
    status
    error
    message
  }
}
    `;
export type CognitoAddUserMutationFn = Apollo.MutationFunction<CognitoAddUserMutation, CognitoAddUserMutationVariables>;

/**
 * __useCognitoAddUserMutation__
 *
 * To run a mutation, you first call `useCognitoAddUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCognitoAddUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cognitoAddUserMutation, { data, loading, error }] = useCognitoAddUserMutation({
 *   variables: {
 *      arn: // value for 'arn'
 *      first_name: // value for 'first_name'
 *      last_name: // value for 'last_name'
 *      email: // value for 'email'
 *      phone_number: // value for 'phone_number'
 *   },
 * });
 */
export function useCognitoAddUserMutation(baseOptions?: Apollo.MutationHookOptions<CognitoAddUserMutation, CognitoAddUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CognitoAddUserMutation, CognitoAddUserMutationVariables>(CognitoAddUserDocument, options);
      }
export type CognitoAddUserMutationHookResult = ReturnType<typeof useCognitoAddUserMutation>;
export type CognitoAddUserMutationResult = Apollo.MutationResult<CognitoAddUserMutation>;
export type CognitoAddUserMutationOptions = Apollo.BaseMutationOptions<CognitoAddUserMutation, CognitoAddUserMutationVariables>;
export const CognitoDeleteUserDocument = gql`
    mutation cognitoDeleteUser($arn: String!, $username: String!) {
  cognitoDeleteUser(arn: $arn, username: $username) {
    status
    error
    message
  }
}
    `;
export type CognitoDeleteUserMutationFn = Apollo.MutationFunction<CognitoDeleteUserMutation, CognitoDeleteUserMutationVariables>;

/**
 * __useCognitoDeleteUserMutation__
 *
 * To run a mutation, you first call `useCognitoDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCognitoDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cognitoDeleteUserMutation, { data, loading, error }] = useCognitoDeleteUserMutation({
 *   variables: {
 *      arn: // value for 'arn'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useCognitoDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<CognitoDeleteUserMutation, CognitoDeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CognitoDeleteUserMutation, CognitoDeleteUserMutationVariables>(CognitoDeleteUserDocument, options);
      }
export type CognitoDeleteUserMutationHookResult = ReturnType<typeof useCognitoDeleteUserMutation>;
export type CognitoDeleteUserMutationResult = Apollo.MutationResult<CognitoDeleteUserMutation>;
export type CognitoDeleteUserMutationOptions = Apollo.BaseMutationOptions<CognitoDeleteUserMutation, CognitoDeleteUserMutationVariables>;
export const CognitoEnableMfaDocument = gql`
    mutation cognitoEnableMfa($method: String!, $usercode: String, $username: String, $access_token: String!) {
  cognitoEnableMfa(
    method: $method
    usercode: $usercode
    username: $username
    access_token: $access_token
  ) {
    status
    message
    error
    SecretCode
  }
}
    `;
export type CognitoEnableMfaMutationFn = Apollo.MutationFunction<CognitoEnableMfaMutation, CognitoEnableMfaMutationVariables>;

/**
 * __useCognitoEnableMfaMutation__
 *
 * To run a mutation, you first call `useCognitoEnableMfaMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCognitoEnableMfaMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cognitoEnableMfaMutation, { data, loading, error }] = useCognitoEnableMfaMutation({
 *   variables: {
 *      method: // value for 'method'
 *      usercode: // value for 'usercode'
 *      username: // value for 'username'
 *      access_token: // value for 'access_token'
 *   },
 * });
 */
export function useCognitoEnableMfaMutation(baseOptions?: Apollo.MutationHookOptions<CognitoEnableMfaMutation, CognitoEnableMfaMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CognitoEnableMfaMutation, CognitoEnableMfaMutationVariables>(CognitoEnableMfaDocument, options);
      }
export type CognitoEnableMfaMutationHookResult = ReturnType<typeof useCognitoEnableMfaMutation>;
export type CognitoEnableMfaMutationResult = Apollo.MutationResult<CognitoEnableMfaMutation>;
export type CognitoEnableMfaMutationOptions = Apollo.BaseMutationOptions<CognitoEnableMfaMutation, CognitoEnableMfaMutationVariables>;
export const CognitoChangeUserPasswordDocument = gql`
    mutation cognitoChangeUserPassword($arn: String!, $existing: String!, $newpwone: String!, $access_token: String!) {
  cognitoChangeUserPassword(
    arn: $arn
    existing: $existing
    newpwone: $newpwone
    access_token: $access_token
  ) {
    status
    error
    message
  }
}
    `;
export type CognitoChangeUserPasswordMutationFn = Apollo.MutationFunction<CognitoChangeUserPasswordMutation, CognitoChangeUserPasswordMutationVariables>;

/**
 * __useCognitoChangeUserPasswordMutation__
 *
 * To run a mutation, you first call `useCognitoChangeUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCognitoChangeUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cognitoChangeUserPasswordMutation, { data, loading, error }] = useCognitoChangeUserPasswordMutation({
 *   variables: {
 *      arn: // value for 'arn'
 *      existing: // value for 'existing'
 *      newpwone: // value for 'newpwone'
 *      access_token: // value for 'access_token'
 *   },
 * });
 */
export function useCognitoChangeUserPasswordMutation(baseOptions?: Apollo.MutationHookOptions<CognitoChangeUserPasswordMutation, CognitoChangeUserPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CognitoChangeUserPasswordMutation, CognitoChangeUserPasswordMutationVariables>(CognitoChangeUserPasswordDocument, options);
      }
export type CognitoChangeUserPasswordMutationHookResult = ReturnType<typeof useCognitoChangeUserPasswordMutation>;
export type CognitoChangeUserPasswordMutationResult = Apollo.MutationResult<CognitoChangeUserPasswordMutation>;
export type CognitoChangeUserPasswordMutationOptions = Apollo.BaseMutationOptions<CognitoChangeUserPasswordMutation, CognitoChangeUserPasswordMutationVariables>;
export const AddIntegrationDocument = gql`
    mutation addIntegration($application: String!, $app_key: String, $app_value: String, $app_endpoint: String) {
  addIntegration(
    application: $application
    app_key: $app_key
    app_value: $app_value
    app_endpoint: $app_endpoint
  ) {
    organisation
    application
    app_key
    app_value
    app_endpoint
  }
}
    `;
export type AddIntegrationMutationFn = Apollo.MutationFunction<AddIntegrationMutation, AddIntegrationMutationVariables>;

/**
 * __useAddIntegrationMutation__
 *
 * To run a mutation, you first call `useAddIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addIntegrationMutation, { data, loading, error }] = useAddIntegrationMutation({
 *   variables: {
 *      application: // value for 'application'
 *      app_key: // value for 'app_key'
 *      app_value: // value for 'app_value'
 *      app_endpoint: // value for 'app_endpoint'
 *   },
 * });
 */
export function useAddIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<AddIntegrationMutation, AddIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddIntegrationMutation, AddIntegrationMutationVariables>(AddIntegrationDocument, options);
      }
export type AddIntegrationMutationHookResult = ReturnType<typeof useAddIntegrationMutation>;
export type AddIntegrationMutationResult = Apollo.MutationResult<AddIntegrationMutation>;
export type AddIntegrationMutationOptions = Apollo.BaseMutationOptions<AddIntegrationMutation, AddIntegrationMutationVariables>;
export const DeleteIntegrationDocument = gql`
    mutation deleteIntegration($application: String!) {
  deleteIntegration(application: $application) {
    organisation
    application
    app_key
    app_value
    app_endpoint
  }
}
    `;
export type DeleteIntegrationMutationFn = Apollo.MutationFunction<DeleteIntegrationMutation, DeleteIntegrationMutationVariables>;

/**
 * __useDeleteIntegrationMutation__
 *
 * To run a mutation, you first call `useDeleteIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIntegrationMutation, { data, loading, error }] = useDeleteIntegrationMutation({
 *   variables: {
 *      application: // value for 'application'
 *   },
 * });
 */
export function useDeleteIntegrationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteIntegrationMutation, DeleteIntegrationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteIntegrationMutation, DeleteIntegrationMutationVariables>(DeleteIntegrationDocument, options);
      }
export type DeleteIntegrationMutationHookResult = ReturnType<typeof useDeleteIntegrationMutation>;
export type DeleteIntegrationMutationResult = Apollo.MutationResult<DeleteIntegrationMutation>;
export type DeleteIntegrationMutationOptions = Apollo.BaseMutationOptions<DeleteIntegrationMutation, DeleteIntegrationMutationVariables>;
export const GetIntegrationDetailsDocument = gql`
    query getIntegrationDetails {
  getIntegrationDetails {
    status
    error
    slackDetails {
      organisation
      application
      app_key
      app_value
      app_endpoint
    }
    sentinelOneDetails {
      organisation
      application
      app_key
      app_value
      app_endpoint
    }
    msTeamsDetails {
      organisation
      application
      app_key
      app_value
      app_endpoint
    }
    googleChatDetails {
      organisation
      application
      app_key
      app_value
      app_endpoint
    }
    jiraDetails {
      organisation
      application
      app_key
      app_value
      app_endpoint
    }
  }
}
    `;

/**
 * __useGetIntegrationDetailsQuery__
 *
 * To run a query within a React component, call `useGetIntegrationDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIntegrationDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIntegrationDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetIntegrationDetailsQuery(baseOptions?: Apollo.QueryHookOptions<GetIntegrationDetailsQuery, GetIntegrationDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIntegrationDetailsQuery, GetIntegrationDetailsQueryVariables>(GetIntegrationDetailsDocument, options);
      }
export function useGetIntegrationDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIntegrationDetailsQuery, GetIntegrationDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIntegrationDetailsQuery, GetIntegrationDetailsQueryVariables>(GetIntegrationDetailsDocument, options);
        }
export type GetIntegrationDetailsQueryHookResult = ReturnType<typeof useGetIntegrationDetailsQuery>;
export type GetIntegrationDetailsLazyQueryHookResult = ReturnType<typeof useGetIntegrationDetailsLazyQuery>;
export type GetIntegrationDetailsQueryResult = Apollo.QueryResult<GetIntegrationDetailsQuery, GetIntegrationDetailsQueryVariables>;
export const GetUserAccountsDocument = gql`
    query getUserAccounts {
  getUserAccounts(limit: 1000) {
    items {
      organisation
      accessnickname
      arn
      defaultregion
      accounttype
      externalid
      sharrversion
      member_accounts
      member_regions
      connectedAccounts {
        accountIdRef
        region
      }
    }
  }
}
    `;

/**
 * __useGetUserAccountsQuery__
 *
 * To run a query within a React component, call `useGetUserAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserAccountsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserAccountsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserAccountsQuery, GetUserAccountsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserAccountsQuery, GetUserAccountsQueryVariables>(GetUserAccountsDocument, options);
      }
export function useGetUserAccountsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserAccountsQuery, GetUserAccountsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserAccountsQuery, GetUserAccountsQueryVariables>(GetUserAccountsDocument, options);
        }
export type GetUserAccountsQueryHookResult = ReturnType<typeof useGetUserAccountsQuery>;
export type GetUserAccountsLazyQueryHookResult = ReturnType<typeof useGetUserAccountsLazyQuery>;
export type GetUserAccountsQueryResult = Apollo.QueryResult<GetUserAccountsQuery, GetUserAccountsQueryVariables>;
export const InitiateSharrDeployDocument = gql`
    query initiateSharrDeploy($arn: String!) {
  initiateSharrDeploy(arn: $arn)
}
    `;

/**
 * __useInitiateSharrDeployQuery__
 *
 * To run a query within a React component, call `useInitiateSharrDeployQuery` and pass it any options that fit your needs.
 * When your component renders, `useInitiateSharrDeployQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInitiateSharrDeployQuery({
 *   variables: {
 *      arn: // value for 'arn'
 *   },
 * });
 */
export function useInitiateSharrDeployQuery(baseOptions: Apollo.QueryHookOptions<InitiateSharrDeployQuery, InitiateSharrDeployQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InitiateSharrDeployQuery, InitiateSharrDeployQueryVariables>(InitiateSharrDeployDocument, options);
      }
export function useInitiateSharrDeployLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InitiateSharrDeployQuery, InitiateSharrDeployQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InitiateSharrDeployQuery, InitiateSharrDeployQueryVariables>(InitiateSharrDeployDocument, options);
        }
export type InitiateSharrDeployQueryHookResult = ReturnType<typeof useInitiateSharrDeployQuery>;
export type InitiateSharrDeployLazyQueryHookResult = ReturnType<typeof useInitiateSharrDeployLazyQuery>;
export type InitiateSharrDeployQueryResult = Apollo.QueryResult<InitiateSharrDeployQuery, InitiateSharrDeployQueryVariables>;
export const AutomateDeploymentStatusDocument = gql`
    query automateDeploymentStatus($accountid: String) {
  automateDeploymentStatus(accountid: $accountid) {
    progress
    executionArn
    accountid
  }
}
    `;

/**
 * __useAutomateDeploymentStatusQuery__
 *
 * To run a query within a React component, call `useAutomateDeploymentStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutomateDeploymentStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutomateDeploymentStatusQuery({
 *   variables: {
 *      accountid: // value for 'accountid'
 *   },
 * });
 */
export function useAutomateDeploymentStatusQuery(baseOptions?: Apollo.QueryHookOptions<AutomateDeploymentStatusQuery, AutomateDeploymentStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AutomateDeploymentStatusQuery, AutomateDeploymentStatusQueryVariables>(AutomateDeploymentStatusDocument, options);
      }
export function useAutomateDeploymentStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AutomateDeploymentStatusQuery, AutomateDeploymentStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AutomateDeploymentStatusQuery, AutomateDeploymentStatusQueryVariables>(AutomateDeploymentStatusDocument, options);
        }
export type AutomateDeploymentStatusQueryHookResult = ReturnType<typeof useAutomateDeploymentStatusQuery>;
export type AutomateDeploymentStatusLazyQueryHookResult = ReturnType<typeof useAutomateDeploymentStatusLazyQuery>;
export type AutomateDeploymentStatusQueryResult = Apollo.QueryResult<AutomateDeploymentStatusQuery, AutomateDeploymentStatusQueryVariables>;
export const CreateWorkloadDocument = gql`
    mutation createWorkload($arn: String!, $workloadname: String!, $description: String!, $environment: String!, $accountAssessedId: String!, $awsregions: String, $reviewowner: String, $lenses: String!, $notes: String) @api(contextKey: "apiName") {
  createWorkload(
    arn: $arn
    WorkloadName: $workloadname
    Description: $description
    Environment: $environment
    accountAssessedId: $accountAssessedId
    AwsRegions: $awsregions
    ReviewOwner: $reviewowner
    Lenses: $lenses
    Notes: $notes
  ) {
    WorkloadId
    Status
  }
}
    `;
export type CreateWorkloadMutationFn = Apollo.MutationFunction<CreateWorkloadMutation, CreateWorkloadMutationVariables>;

/**
 * __useCreateWorkloadMutation__
 *
 * To run a mutation, you first call `useCreateWorkloadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkloadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkloadMutation, { data, loading, error }] = useCreateWorkloadMutation({
 *   variables: {
 *      arn: // value for 'arn'
 *      workloadname: // value for 'workloadname'
 *      description: // value for 'description'
 *      environment: // value for 'environment'
 *      accountAssessedId: // value for 'accountAssessedId'
 *      awsregions: // value for 'awsregions'
 *      reviewowner: // value for 'reviewowner'
 *      lenses: // value for 'lenses'
 *      notes: // value for 'notes'
 *   },
 * });
 */
export function useCreateWorkloadMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkloadMutation, CreateWorkloadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkloadMutation, CreateWorkloadMutationVariables>(CreateWorkloadDocument, options);
      }
export type CreateWorkloadMutationHookResult = ReturnType<typeof useCreateWorkloadMutation>;
export type CreateWorkloadMutationResult = Apollo.MutationResult<CreateWorkloadMutation>;
export type CreateWorkloadMutationOptions = Apollo.BaseMutationOptions<CreateWorkloadMutation, CreateWorkloadMutationVariables>;
export const CreateIacWorkloadDocument = gql`
    mutation createIacWorkload($workloadname: String!, $description: String!, $environment: String!, $awsregions: String, $reviewowner: String, $lenses: String!, $notes: String, $awsCreditAccount: String!, $awsTechnicalReviewAccount: String!, $awsWafrAccount: String!) @api(contextKey: "apiName") {
  createIacWorkload(
    WorkloadName: $workloadname
    Description: $description
    Environment: $environment
    AwsRegions: $awsregions
    ReviewOwner: $reviewowner
    Lenses: $lenses
    Notes: $notes
    awsCreditAccount: $awsCreditAccount
    awsTechnicalReviewAccount: $awsTechnicalReviewAccount
    awsWafrAccount: $awsWafrAccount
  ) {
    WorkloadId
    Status
  }
}
    `;
export type CreateIacWorkloadMutationFn = Apollo.MutationFunction<CreateIacWorkloadMutation, CreateIacWorkloadMutationVariables>;

/**
 * __useCreateIacWorkloadMutation__
 *
 * To run a mutation, you first call `useCreateIacWorkloadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIacWorkloadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIacWorkloadMutation, { data, loading, error }] = useCreateIacWorkloadMutation({
 *   variables: {
 *      workloadname: // value for 'workloadname'
 *      description: // value for 'description'
 *      environment: // value for 'environment'
 *      awsregions: // value for 'awsregions'
 *      reviewowner: // value for 'reviewowner'
 *      lenses: // value for 'lenses'
 *      notes: // value for 'notes'
 *      awsCreditAccount: // value for 'awsCreditAccount'
 *      awsTechnicalReviewAccount: // value for 'awsTechnicalReviewAccount'
 *      awsWafrAccount: // value for 'awsWafrAccount'
 *   },
 * });
 */
export function useCreateIacWorkloadMutation(baseOptions?: Apollo.MutationHookOptions<CreateIacWorkloadMutation, CreateIacWorkloadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateIacWorkloadMutation, CreateIacWorkloadMutationVariables>(CreateIacWorkloadDocument, options);
      }
export type CreateIacWorkloadMutationHookResult = ReturnType<typeof useCreateIacWorkloadMutation>;
export type CreateIacWorkloadMutationResult = Apollo.MutationResult<CreateIacWorkloadMutation>;
export type CreateIacWorkloadMutationOptions = Apollo.BaseMutationOptions<CreateIacWorkloadMutation, CreateIacWorkloadMutationVariables>;
export const CreateMilestoneDocument = gql`
    mutation createMilestone($arn: String!, $workloadid: String!, $milestonename: String!) @api(contextKey: "apiName") {
  createMilestone(
    arn: $arn
    WorkloadId: $workloadid
    MilestoneName: $milestonename
  ) {
    WorkloadId
    MilestoneNumber
    RiskCounts {
      UNANSWERED
      HIGH
      MEDIUM
      NONE
      NOT_APPLICABLE
    }
  }
}
    `;
export type CreateMilestoneMutationFn = Apollo.MutationFunction<CreateMilestoneMutation, CreateMilestoneMutationVariables>;

/**
 * __useCreateMilestoneMutation__
 *
 * To run a mutation, you first call `useCreateMilestoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMilestoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMilestoneMutation, { data, loading, error }] = useCreateMilestoneMutation({
 *   variables: {
 *      arn: // value for 'arn'
 *      workloadid: // value for 'workloadid'
 *      milestonename: // value for 'milestonename'
 *   },
 * });
 */
export function useCreateMilestoneMutation(baseOptions?: Apollo.MutationHookOptions<CreateMilestoneMutation, CreateMilestoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMilestoneMutation, CreateMilestoneMutationVariables>(CreateMilestoneDocument, options);
      }
export type CreateMilestoneMutationHookResult = ReturnType<typeof useCreateMilestoneMutation>;
export type CreateMilestoneMutationResult = Apollo.MutationResult<CreateMilestoneMutation>;
export type CreateMilestoneMutationOptions = Apollo.BaseMutationOptions<CreateMilestoneMutation, CreateMilestoneMutationVariables>;
export const CreateIacMilestoneDocument = gql`
    mutation createIacMilestone($workloadid: String!, $milestonename: String!, $awsWafrAccount: String!) @api(contextKey: "apiName") {
  createIacMilestone(
    WorkloadId: $workloadid
    MilestoneName: $milestonename
    awsWafrAccount: $awsWafrAccount
  ) {
    WorkloadId
    MilestoneNumber
    RiskCounts {
      UNANSWERED
      HIGH
      MEDIUM
      NONE
      NOT_APPLICABLE
    }
  }
}
    `;
export type CreateIacMilestoneMutationFn = Apollo.MutationFunction<CreateIacMilestoneMutation, CreateIacMilestoneMutationVariables>;

/**
 * __useCreateIacMilestoneMutation__
 *
 * To run a mutation, you first call `useCreateIacMilestoneMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateIacMilestoneMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createIacMilestoneMutation, { data, loading, error }] = useCreateIacMilestoneMutation({
 *   variables: {
 *      workloadid: // value for 'workloadid'
 *      milestonename: // value for 'milestonename'
 *      awsWafrAccount: // value for 'awsWafrAccount'
 *   },
 * });
 */
export function useCreateIacMilestoneMutation(baseOptions?: Apollo.MutationHookOptions<CreateIacMilestoneMutation, CreateIacMilestoneMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateIacMilestoneMutation, CreateIacMilestoneMutationVariables>(CreateIacMilestoneDocument, options);
      }
export type CreateIacMilestoneMutationHookResult = ReturnType<typeof useCreateIacMilestoneMutation>;
export type CreateIacMilestoneMutationResult = Apollo.MutationResult<CreateIacMilestoneMutation>;
export type CreateIacMilestoneMutationOptions = Apollo.BaseMutationOptions<CreateIacMilestoneMutation, CreateIacMilestoneMutationVariables>;
export const UpdateAnswerDocument = gql`
    mutation updateAnswer($arn: String!, $targetAccountArn: String!, $workloadid: String!, $lens: String!, $connectedAccounts: [ConnectedAccountInput]) @api(contextKey: "apiName") {
  updateAnswer(
    arn: $arn
    targetAccountArn: $targetAccountArn
    WorkloadId: $workloadid
    LensAlias: $lens
    connectedAccounts: $connectedAccounts
  ) {
    id
    status
    result
  }
}
    `;
export type UpdateAnswerMutationFn = Apollo.MutationFunction<UpdateAnswerMutation, UpdateAnswerMutationVariables>;

/**
 * __useUpdateAnswerMutation__
 *
 * To run a mutation, you first call `useUpdateAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAnswerMutation, { data, loading, error }] = useUpdateAnswerMutation({
 *   variables: {
 *      arn: // value for 'arn'
 *      targetAccountArn: // value for 'targetAccountArn'
 *      workloadid: // value for 'workloadid'
 *      lens: // value for 'lens'
 *      connectedAccounts: // value for 'connectedAccounts'
 *   },
 * });
 */
export function useUpdateAnswerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAnswerMutation, UpdateAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAnswerMutation, UpdateAnswerMutationVariables>(UpdateAnswerDocument, options);
      }
export type UpdateAnswerMutationHookResult = ReturnType<typeof useUpdateAnswerMutation>;
export type UpdateAnswerMutationResult = Apollo.MutationResult<UpdateAnswerMutation>;
export type UpdateAnswerMutationOptions = Apollo.BaseMutationOptions<UpdateAnswerMutation, UpdateAnswerMutationVariables>;
export const UpdateIacAnswerDocument = gql`
    mutation updateIacAnswer($workloadid: String!, $lens: String!, $awsWafrAccount: String!, $summaryId: [Int]!) @api(contextKey: "apiName") {
  updateIacAnswer(
    WorkloadId: $workloadid
    LensAlias: $lens
    awsWafrAccount: $awsWafrAccount
    summaryId: $summaryId
  ) {
    id
    status
    result
  }
}
    `;
export type UpdateIacAnswerMutationFn = Apollo.MutationFunction<UpdateIacAnswerMutation, UpdateIacAnswerMutationVariables>;

/**
 * __useUpdateIacAnswerMutation__
 *
 * To run a mutation, you first call `useUpdateIacAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIacAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIacAnswerMutation, { data, loading, error }] = useUpdateIacAnswerMutation({
 *   variables: {
 *      workloadid: // value for 'workloadid'
 *      lens: // value for 'lens'
 *      awsWafrAccount: // value for 'awsWafrAccount'
 *      summaryId: // value for 'summaryId'
 *   },
 * });
 */
export function useUpdateIacAnswerMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIacAnswerMutation, UpdateIacAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIacAnswerMutation, UpdateIacAnswerMutationVariables>(UpdateIacAnswerDocument, options);
      }
export type UpdateIacAnswerMutationHookResult = ReturnType<typeof useUpdateIacAnswerMutation>;
export type UpdateIacAnswerMutationResult = Apollo.MutationResult<UpdateIacAnswerMutation>;
export type UpdateIacAnswerMutationOptions = Apollo.BaseMutationOptions<UpdateIacAnswerMutation, UpdateIacAnswerMutationVariables>;
export const DeleteWorkloadDocument = gql`
    mutation deleteWorkload($arn: String!, $workloadid: String!) @api(contextKey: "apiName") {
  deleteWorkload(arn: $arn, WorkloadId: $workloadid) {
    WorkloadId
  }
}
    `;
export type DeleteWorkloadMutationFn = Apollo.MutationFunction<DeleteWorkloadMutation, DeleteWorkloadMutationVariables>;

/**
 * __useDeleteWorkloadMutation__
 *
 * To run a mutation, you first call `useDeleteWorkloadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkloadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkloadMutation, { data, loading, error }] = useDeleteWorkloadMutation({
 *   variables: {
 *      arn: // value for 'arn'
 *      workloadid: // value for 'workloadid'
 *   },
 * });
 */
export function useDeleteWorkloadMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWorkloadMutation, DeleteWorkloadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWorkloadMutation, DeleteWorkloadMutationVariables>(DeleteWorkloadDocument, options);
      }
export type DeleteWorkloadMutationHookResult = ReturnType<typeof useDeleteWorkloadMutation>;
export type DeleteWorkloadMutationResult = Apollo.MutationResult<DeleteWorkloadMutation>;
export type DeleteWorkloadMutationOptions = Apollo.BaseMutationOptions<DeleteWorkloadMutation, DeleteWorkloadMutationVariables>;
export const DeleteIacWorkloadDocument = gql`
    mutation deleteIacWorkload($workloadid: String!, $awsWafrAccount: String!) @api(contextKey: "apiName") {
  deleteIacWorkload(WorkloadId: $workloadid, awsWafrAccount: $awsWafrAccount) {
    WorkloadId
  }
}
    `;
export type DeleteIacWorkloadMutationFn = Apollo.MutationFunction<DeleteIacWorkloadMutation, DeleteIacWorkloadMutationVariables>;

/**
 * __useDeleteIacWorkloadMutation__
 *
 * To run a mutation, you first call `useDeleteIacWorkloadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIacWorkloadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIacWorkloadMutation, { data, loading, error }] = useDeleteIacWorkloadMutation({
 *   variables: {
 *      workloadid: // value for 'workloadid'
 *      awsWafrAccount: // value for 'awsWafrAccount'
 *   },
 * });
 */
export function useDeleteIacWorkloadMutation(baseOptions?: Apollo.MutationHookOptions<DeleteIacWorkloadMutation, DeleteIacWorkloadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteIacWorkloadMutation, DeleteIacWorkloadMutationVariables>(DeleteIacWorkloadDocument, options);
      }
export type DeleteIacWorkloadMutationHookResult = ReturnType<typeof useDeleteIacWorkloadMutation>;
export type DeleteIacWorkloadMutationResult = Apollo.MutationResult<DeleteIacWorkloadMutation>;
export type DeleteIacWorkloadMutationOptions = Apollo.BaseMutationOptions<DeleteIacWorkloadMutation, DeleteIacWorkloadMutationVariables>;
export const GetLensReviewReportDocument = gql`
    query getLensReviewReport($arn: String!, $workloadid: String!, $lens: String!, $milestonenumber: Int) @api(contextKey: "apiName") {
  getLensReviewReport(
    arn: $arn
    WorkloadId: $workloadid
    LensAlias: $lens
    MilestoneNumber: $milestonenumber
  ) {
    Base64String
  }
}
    `;

/**
 * __useGetLensReviewReportQuery__
 *
 * To run a query within a React component, call `useGetLensReviewReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLensReviewReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLensReviewReportQuery({
 *   variables: {
 *      arn: // value for 'arn'
 *      workloadid: // value for 'workloadid'
 *      lens: // value for 'lens'
 *      milestonenumber: // value for 'milestonenumber'
 *   },
 * });
 */
export function useGetLensReviewReportQuery(baseOptions: Apollo.QueryHookOptions<GetLensReviewReportQuery, GetLensReviewReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLensReviewReportQuery, GetLensReviewReportQueryVariables>(GetLensReviewReportDocument, options);
      }
export function useGetLensReviewReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLensReviewReportQuery, GetLensReviewReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLensReviewReportQuery, GetLensReviewReportQueryVariables>(GetLensReviewReportDocument, options);
        }
export type GetLensReviewReportQueryHookResult = ReturnType<typeof useGetLensReviewReportQuery>;
export type GetLensReviewReportLazyQueryHookResult = ReturnType<typeof useGetLensReviewReportLazyQuery>;
export type GetLensReviewReportQueryResult = Apollo.QueryResult<GetLensReviewReportQuery, GetLensReviewReportQueryVariables>;
export const GetIacLensReviewReportDocument = gql`
    query getIacLensReviewReport($workloadid: String!, $lens: String!, $milestonenumber: Int, $awsWafrAccount: String!) @api(contextKey: "apiName") {
  getIacLensReviewReport(
    WorkloadId: $workloadid
    LensAlias: $lens
    MilestoneNumber: $milestonenumber
    awsWafrAccount: $awsWafrAccount
  ) {
    Base64String
  }
}
    `;

/**
 * __useGetIacLensReviewReportQuery__
 *
 * To run a query within a React component, call `useGetIacLensReviewReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIacLensReviewReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIacLensReviewReportQuery({
 *   variables: {
 *      workloadid: // value for 'workloadid'
 *      lens: // value for 'lens'
 *      milestonenumber: // value for 'milestonenumber'
 *      awsWafrAccount: // value for 'awsWafrAccount'
 *   },
 * });
 */
export function useGetIacLensReviewReportQuery(baseOptions: Apollo.QueryHookOptions<GetIacLensReviewReportQuery, GetIacLensReviewReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIacLensReviewReportQuery, GetIacLensReviewReportQueryVariables>(GetIacLensReviewReportDocument, options);
      }
export function useGetIacLensReviewReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIacLensReviewReportQuery, GetIacLensReviewReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIacLensReviewReportQuery, GetIacLensReviewReportQueryVariables>(GetIacLensReviewReportDocument, options);
        }
export type GetIacLensReviewReportQueryHookResult = ReturnType<typeof useGetIacLensReviewReportQuery>;
export type GetIacLensReviewReportLazyQueryHookResult = ReturnType<typeof useGetIacLensReviewReportLazyQuery>;
export type GetIacLensReviewReportQueryResult = Apollo.QueryResult<GetIacLensReviewReportQuery, GetIacLensReviewReportQueryVariables>;
export const GetLensReviewDocument = gql`
    query getLensReview($arn: String!, $workloadid: String!, $lens: String!) @api(contextKey: "apiName") {
  getLensReview(arn: $arn, WorkloadId: $workloadid, LensAlias: $lens) {
    PillarReviewSummaries {
      PillarId
      PillarName
      RiskCounts {
        UNANSWERED
        HIGH
        MEDIUM
        NONE
        NOT_APPLICABLE
      }
    }
  }
}
    `;

/**
 * __useGetLensReviewQuery__
 *
 * To run a query within a React component, call `useGetLensReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLensReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLensReviewQuery({
 *   variables: {
 *      arn: // value for 'arn'
 *      workloadid: // value for 'workloadid'
 *      lens: // value for 'lens'
 *   },
 * });
 */
export function useGetLensReviewQuery(baseOptions: Apollo.QueryHookOptions<GetLensReviewQuery, GetLensReviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLensReviewQuery, GetLensReviewQueryVariables>(GetLensReviewDocument, options);
      }
export function useGetLensReviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLensReviewQuery, GetLensReviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLensReviewQuery, GetLensReviewQueryVariables>(GetLensReviewDocument, options);
        }
export type GetLensReviewQueryHookResult = ReturnType<typeof useGetLensReviewQuery>;
export type GetLensReviewLazyQueryHookResult = ReturnType<typeof useGetLensReviewLazyQuery>;
export type GetLensReviewQueryResult = Apollo.QueryResult<GetLensReviewQuery, GetLensReviewQueryVariables>;
export const GetIacLensReviewDocument = gql`
    query getIacLensReview($workloadid: String!, $lens: String!, $awsWafrAccount: String!) @api(contextKey: "apiName") {
  getIacLensReview(
    WorkloadId: $workloadid
    LensAlias: $lens
    awsWafrAccount: $awsWafrAccount
  ) {
    PillarReviewSummaries {
      PillarId
      PillarName
      RiskCounts {
        UNANSWERED
        HIGH
        MEDIUM
        NONE
        NOT_APPLICABLE
      }
    }
  }
}
    `;

/**
 * __useGetIacLensReviewQuery__
 *
 * To run a query within a React component, call `useGetIacLensReviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIacLensReviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIacLensReviewQuery({
 *   variables: {
 *      workloadid: // value for 'workloadid'
 *      lens: // value for 'lens'
 *      awsWafrAccount: // value for 'awsWafrAccount'
 *   },
 * });
 */
export function useGetIacLensReviewQuery(baseOptions: Apollo.QueryHookOptions<GetIacLensReviewQuery, GetIacLensReviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIacLensReviewQuery, GetIacLensReviewQueryVariables>(GetIacLensReviewDocument, options);
      }
export function useGetIacLensReviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIacLensReviewQuery, GetIacLensReviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIacLensReviewQuery, GetIacLensReviewQueryVariables>(GetIacLensReviewDocument, options);
        }
export type GetIacLensReviewQueryHookResult = ReturnType<typeof useGetIacLensReviewQuery>;
export type GetIacLensReviewLazyQueryHookResult = ReturnType<typeof useGetIacLensReviewLazyQuery>;
export type GetIacLensReviewQueryResult = Apollo.QueryResult<GetIacLensReviewQuery, GetIacLensReviewQueryVariables>;
export const ListWorkloadsDocument = gql`
    query listWorkloads($arn: String!) @api(contextKey: "apiName") {
  listWorkloads(arn: $arn) {
    items {
      WorkloadId
      WorkloadArn
      WorkloadName
      Owner
      UpdatedAt
      Lenses
      RiskCounts {
        UNANSWERED
        HIGH
        MEDIUM
        NONE
        NOT_APPLICABLE
      }
      ImprovementStatus
    }
  }
}
    `;

/**
 * __useListWorkloadsQuery__
 *
 * To run a query within a React component, call `useListWorkloadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListWorkloadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListWorkloadsQuery({
 *   variables: {
 *      arn: // value for 'arn'
 *   },
 * });
 */
export function useListWorkloadsQuery(baseOptions: Apollo.QueryHookOptions<ListWorkloadsQuery, ListWorkloadsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListWorkloadsQuery, ListWorkloadsQueryVariables>(ListWorkloadsDocument, options);
      }
export function useListWorkloadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListWorkloadsQuery, ListWorkloadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListWorkloadsQuery, ListWorkloadsQueryVariables>(ListWorkloadsDocument, options);
        }
export type ListWorkloadsQueryHookResult = ReturnType<typeof useListWorkloadsQuery>;
export type ListWorkloadsLazyQueryHookResult = ReturnType<typeof useListWorkloadsLazyQuery>;
export type ListWorkloadsQueryResult = Apollo.QueryResult<ListWorkloadsQuery, ListWorkloadsQueryVariables>;
export const ListIacWorkloadsDocument = gql`
    query listIacWorkloads($limit: Int, $nextToken: String) @api(contextKey: "apiName") {
  listIacWorkloads(limit: $limit, nextToken: $nextToken) {
    items {
      AwsCreditAccount
      AwsWafrAccount
      AccountIds
      WorkloadId
      WorkloadArn
      WorkloadName
      Description
      Owner
      UpdatedAt
      AccountIds
      AwsRegions
      Lenses
      RiskCounts {
        UNANSWERED
        HIGH
        MEDIUM
        NONE
        NOT_APPLICABLE
      }
      ImprovementStatus
    }
  }
}
    `;

/**
 * __useListIacWorkloadsQuery__
 *
 * To run a query within a React component, call `useListIacWorkloadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListIacWorkloadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListIacWorkloadsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      nextToken: // value for 'nextToken'
 *   },
 * });
 */
export function useListIacWorkloadsQuery(baseOptions?: Apollo.QueryHookOptions<ListIacWorkloadsQuery, ListIacWorkloadsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListIacWorkloadsQuery, ListIacWorkloadsQueryVariables>(ListIacWorkloadsDocument, options);
      }
export function useListIacWorkloadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListIacWorkloadsQuery, ListIacWorkloadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListIacWorkloadsQuery, ListIacWorkloadsQueryVariables>(ListIacWorkloadsDocument, options);
        }
export type ListIacWorkloadsQueryHookResult = ReturnType<typeof useListIacWorkloadsQuery>;
export type ListIacWorkloadsLazyQueryHookResult = ReturnType<typeof useListIacWorkloadsLazyQuery>;
export type ListIacWorkloadsQueryResult = Apollo.QueryResult<ListIacWorkloadsQuery, ListIacWorkloadsQueryVariables>;
export const ListMilestonesDocument = gql`
    query listMilestones($arn: String!, $workloadid: String!) @api(contextKey: "apiName") {
  listMilestones(arn: $arn, WorkloadId: $workloadid) {
    MilestoneSummaries {
      MilestoneNumber
      MilestoneName
      RecordedAt
      WorkloadId
      WorkloadArn
      WorkloadName
      Owner
      Lenses
      RiskCounts {
        UNANSWERED
        HIGH
        MEDIUM
        NONE
        NOT_APPLICABLE
      }
      ImprovementStatus
    }
  }
}
    `;

/**
 * __useListMilestonesQuery__
 *
 * To run a query within a React component, call `useListMilestonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMilestonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMilestonesQuery({
 *   variables: {
 *      arn: // value for 'arn'
 *      workloadid: // value for 'workloadid'
 *   },
 * });
 */
export function useListMilestonesQuery(baseOptions: Apollo.QueryHookOptions<ListMilestonesQuery, ListMilestonesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListMilestonesQuery, ListMilestonesQueryVariables>(ListMilestonesDocument, options);
      }
export function useListMilestonesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListMilestonesQuery, ListMilestonesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListMilestonesQuery, ListMilestonesQueryVariables>(ListMilestonesDocument, options);
        }
export type ListMilestonesQueryHookResult = ReturnType<typeof useListMilestonesQuery>;
export type ListMilestonesLazyQueryHookResult = ReturnType<typeof useListMilestonesLazyQuery>;
export type ListMilestonesQueryResult = Apollo.QueryResult<ListMilestonesQuery, ListMilestonesQueryVariables>;
export const ListIacMilestonesDocument = gql`
    query listIacMilestones($workloadid: String!, $awsWafrAccount: String!) @api(contextKey: "apiName") {
  listIacMilestones(WorkloadId: $workloadid, awsWafrAccount: $awsWafrAccount) {
    MilestoneSummaries {
      MilestoneNumber
      MilestoneName
      RecordedAt
      WorkloadId
      WorkloadArn
      WorkloadName
      Owner
      Lenses
      RiskCounts {
        UNANSWERED
        HIGH
        MEDIUM
        NONE
        NOT_APPLICABLE
      }
      ImprovementStatus
    }
  }
}
    `;

/**
 * __useListIacMilestonesQuery__
 *
 * To run a query within a React component, call `useListIacMilestonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListIacMilestonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListIacMilestonesQuery({
 *   variables: {
 *      workloadid: // value for 'workloadid'
 *      awsWafrAccount: // value for 'awsWafrAccount'
 *   },
 * });
 */
export function useListIacMilestonesQuery(baseOptions: Apollo.QueryHookOptions<ListIacMilestonesQuery, ListIacMilestonesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListIacMilestonesQuery, ListIacMilestonesQueryVariables>(ListIacMilestonesDocument, options);
      }
export function useListIacMilestonesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListIacMilestonesQuery, ListIacMilestonesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListIacMilestonesQuery, ListIacMilestonesQueryVariables>(ListIacMilestonesDocument, options);
        }
export type ListIacMilestonesQueryHookResult = ReturnType<typeof useListIacMilestonesQuery>;
export type ListIacMilestonesLazyQueryHookResult = ReturnType<typeof useListIacMilestonesLazyQuery>;
export type ListIacMilestonesQueryResult = Apollo.QueryResult<ListIacMilestonesQuery, ListIacMilestonesQueryVariables>;
export const ListIacBestPracticeControlsDocument = gql`
    query listIacBestPracticeControls($summaryId: [Int]!) @api(contextKey: "apiName") {
  listIacBestPracticeControls(summaryId: $summaryId) {
    items {
      checkRef
      description
      checkType
      resource
      overallFinding
    }
  }
}
    `;

/**
 * __useListIacBestPracticeControlsQuery__
 *
 * To run a query within a React component, call `useListIacBestPracticeControlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListIacBestPracticeControlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListIacBestPracticeControlsQuery({
 *   variables: {
 *      summaryId: // value for 'summaryId'
 *   },
 * });
 */
export function useListIacBestPracticeControlsQuery(baseOptions: Apollo.QueryHookOptions<ListIacBestPracticeControlsQuery, ListIacBestPracticeControlsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListIacBestPracticeControlsQuery, ListIacBestPracticeControlsQueryVariables>(ListIacBestPracticeControlsDocument, options);
      }
export function useListIacBestPracticeControlsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListIacBestPracticeControlsQuery, ListIacBestPracticeControlsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListIacBestPracticeControlsQuery, ListIacBestPracticeControlsQueryVariables>(ListIacBestPracticeControlsDocument, options);
        }
export type ListIacBestPracticeControlsQueryHookResult = ReturnType<typeof useListIacBestPracticeControlsQuery>;
export type ListIacBestPracticeControlsLazyQueryHookResult = ReturnType<typeof useListIacBestPracticeControlsLazyQuery>;
export type ListIacBestPracticeControlsQueryResult = Apollo.QueryResult<ListIacBestPracticeControlsQuery, ListIacBestPracticeControlsQueryVariables>;
export const GetSelfAssessmentDocument = gql`
    query getSelfAssessment($arn: String!) @api(contextKey: "apiName") {
  getSelfAssessment(arn: $arn) {
    items {
      ControlName
      Met
      PartnerResponse
    }
  }
}
    `;

/**
 * __useGetSelfAssessmentQuery__
 *
 * To run a query within a React component, call `useGetSelfAssessmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelfAssessmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelfAssessmentQuery({
 *   variables: {
 *      arn: // value for 'arn'
 *   },
 * });
 */
export function useGetSelfAssessmentQuery(baseOptions: Apollo.QueryHookOptions<GetSelfAssessmentQuery, GetSelfAssessmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSelfAssessmentQuery, GetSelfAssessmentQueryVariables>(GetSelfAssessmentDocument, options);
      }
export function useGetSelfAssessmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSelfAssessmentQuery, GetSelfAssessmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSelfAssessmentQuery, GetSelfAssessmentQueryVariables>(GetSelfAssessmentDocument, options);
        }
export type GetSelfAssessmentQueryHookResult = ReturnType<typeof useGetSelfAssessmentQuery>;
export type GetSelfAssessmentLazyQueryHookResult = ReturnType<typeof useGetSelfAssessmentLazyQuery>;
export type GetSelfAssessmentQueryResult = Apollo.QueryResult<GetSelfAssessmentQuery, GetSelfAssessmentQueryVariables>;
export const DownloadSelfAssessmentDocument = gql`
    query downloadSelfAssessment($arn: String!) @api(contextKey: "apiName") {
  downloadSelfAssessment(arn: $arn) {
    id
    status
    result
  }
}
    `;

/**
 * __useDownloadSelfAssessmentQuery__
 *
 * To run a query within a React component, call `useDownloadSelfAssessmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadSelfAssessmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadSelfAssessmentQuery({
 *   variables: {
 *      arn: // value for 'arn'
 *   },
 * });
 */
export function useDownloadSelfAssessmentQuery(baseOptions: Apollo.QueryHookOptions<DownloadSelfAssessmentQuery, DownloadSelfAssessmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadSelfAssessmentQuery, DownloadSelfAssessmentQueryVariables>(DownloadSelfAssessmentDocument, options);
      }
export function useDownloadSelfAssessmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadSelfAssessmentQuery, DownloadSelfAssessmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadSelfAssessmentQuery, DownloadSelfAssessmentQueryVariables>(DownloadSelfAssessmentDocument, options);
        }
export type DownloadSelfAssessmentQueryHookResult = ReturnType<typeof useDownloadSelfAssessmentQuery>;
export type DownloadSelfAssessmentLazyQueryHookResult = ReturnType<typeof useDownloadSelfAssessmentLazyQuery>;
export type DownloadSelfAssessmentQueryResult = Apollo.QueryResult<DownloadSelfAssessmentQuery, DownloadSelfAssessmentQueryVariables>;
export const GetWorkloadDocument = gql`
    query getWorkload($arn: String!, $WorkloadId: String!) @api(contextKey: "apiName") {
  getWorkload(arn: $arn, WorkloadId: $WorkloadId) {
    WorkloadId
    WorkloadArn
    WorkloadName
    Description
    Environment
    UpdatedAt
    AccountIds
    AwsRegions
    RiskCounts {
      UNANSWERED
      HIGH
      MEDIUM
      NONE
      NOT_APPLICABLE
    }
  }
}
    `;

/**
 * __useGetWorkloadQuery__
 *
 * To run a query within a React component, call `useGetWorkloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkloadQuery({
 *   variables: {
 *      arn: // value for 'arn'
 *      WorkloadId: // value for 'WorkloadId'
 *   },
 * });
 */
export function useGetWorkloadQuery(baseOptions: Apollo.QueryHookOptions<GetWorkloadQuery, GetWorkloadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWorkloadQuery, GetWorkloadQueryVariables>(GetWorkloadDocument, options);
      }
export function useGetWorkloadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWorkloadQuery, GetWorkloadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWorkloadQuery, GetWorkloadQueryVariables>(GetWorkloadDocument, options);
        }
export type GetWorkloadQueryHookResult = ReturnType<typeof useGetWorkloadQuery>;
export type GetWorkloadLazyQueryHookResult = ReturnType<typeof useGetWorkloadLazyQuery>;
export type GetWorkloadQueryResult = Apollo.QueryResult<GetWorkloadQuery, GetWorkloadQueryVariables>;
export const DownloadReportsDocument = gql`
    query downloadReports($arn: String!, $workloadname: String!, $workloadid: String!, $lens: String!, $milestonenumber: Int) @api(contextKey: "apiName") {
  downloadReports(
    arn: $arn
    WorkloadName: $workloadname
    WorkloadId: $workloadid
    WorkloadLens: $lens
    MilestoneNumber: $milestonenumber
  ) {
    id
    status
    result
  }
}
    `;

/**
 * __useDownloadReportsQuery__
 *
 * To run a query within a React component, call `useDownloadReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadReportsQuery({
 *   variables: {
 *      arn: // value for 'arn'
 *      workloadname: // value for 'workloadname'
 *      workloadid: // value for 'workloadid'
 *      lens: // value for 'lens'
 *      milestonenumber: // value for 'milestonenumber'
 *   },
 * });
 */
export function useDownloadReportsQuery(baseOptions: Apollo.QueryHookOptions<DownloadReportsQuery, DownloadReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadReportsQuery, DownloadReportsQueryVariables>(DownloadReportsDocument, options);
      }
export function useDownloadReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadReportsQuery, DownloadReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadReportsQuery, DownloadReportsQueryVariables>(DownloadReportsDocument, options);
        }
export type DownloadReportsQueryHookResult = ReturnType<typeof useDownloadReportsQuery>;
export type DownloadReportsLazyQueryHookResult = ReturnType<typeof useDownloadReportsLazyQuery>;
export type DownloadReportsQueryResult = Apollo.QueryResult<DownloadReportsQuery, DownloadReportsQueryVariables>;
export const DownloadIacReportsDocument = gql`
    query downloadIacReports($workloadname: String!, $workloadid: String!, $lens: String!, $milestonenumber: Int, $awsWafrAccount: String!) @api(contextKey: "apiName") {
  downloadIacReports(
    WorkloadName: $workloadname
    WorkloadId: $workloadid
    WorkloadLens: $lens
    MilestoneNumber: $milestonenumber
    awsWafrAccount: $awsWafrAccount
  ) {
    id
    status
    result
  }
}
    `;

/**
 * __useDownloadIacReportsQuery__
 *
 * To run a query within a React component, call `useDownloadIacReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadIacReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadIacReportsQuery({
 *   variables: {
 *      workloadname: // value for 'workloadname'
 *      workloadid: // value for 'workloadid'
 *      lens: // value for 'lens'
 *      milestonenumber: // value for 'milestonenumber'
 *      awsWafrAccount: // value for 'awsWafrAccount'
 *   },
 * });
 */
export function useDownloadIacReportsQuery(baseOptions: Apollo.QueryHookOptions<DownloadIacReportsQuery, DownloadIacReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadIacReportsQuery, DownloadIacReportsQueryVariables>(DownloadIacReportsDocument, options);
      }
export function useDownloadIacReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadIacReportsQuery, DownloadIacReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadIacReportsQuery, DownloadIacReportsQueryVariables>(DownloadIacReportsDocument, options);
        }
export type DownloadIacReportsQueryHookResult = ReturnType<typeof useDownloadIacReportsQuery>;
export type DownloadIacReportsLazyQueryHookResult = ReturnType<typeof useDownloadIacReportsLazyQuery>;
export type DownloadIacReportsQueryResult = Apollo.QueryResult<DownloadIacReportsQuery, DownloadIacReportsQueryVariables>;
export const GetQuestionnaireTableDocument = gql`
    query getQuestionnaireTable($arn: String!, $workloadid: String!) @api(contextKey: "apiName") {
  getQuestionnaireTable(arn: $arn, workLoadId: $workloadid)
}
    `;

/**
 * __useGetQuestionnaireTableQuery__
 *
 * To run a query within a React component, call `useGetQuestionnaireTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionnaireTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionnaireTableQuery({
 *   variables: {
 *      arn: // value for 'arn'
 *      workloadid: // value for 'workloadid'
 *   },
 * });
 */
export function useGetQuestionnaireTableQuery(baseOptions: Apollo.QueryHookOptions<GetQuestionnaireTableQuery, GetQuestionnaireTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionnaireTableQuery, GetQuestionnaireTableQueryVariables>(GetQuestionnaireTableDocument, options);
      }
export function useGetQuestionnaireTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionnaireTableQuery, GetQuestionnaireTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionnaireTableQuery, GetQuestionnaireTableQueryVariables>(GetQuestionnaireTableDocument, options);
        }
export type GetQuestionnaireTableQueryHookResult = ReturnType<typeof useGetQuestionnaireTableQuery>;
export type GetQuestionnaireTableLazyQueryHookResult = ReturnType<typeof useGetQuestionnaireTableLazyQuery>;
export type GetQuestionnaireTableQueryResult = Apollo.QueryResult<GetQuestionnaireTableQuery, GetQuestionnaireTableQueryVariables>;
export const GetIacQuestionnaireTableDocument = gql`
    query getIacQuestionnaireTable($workloadid: String!, $awsWafrAccount: String!) @api(contextKey: "apiName") {
  getIacQuestionnaireTable(
    workLoadId: $workloadid
    awsWafrAccount: $awsWafrAccount
  )
}
    `;

/**
 * __useGetIacQuestionnaireTableQuery__
 *
 * To run a query within a React component, call `useGetIacQuestionnaireTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetIacQuestionnaireTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetIacQuestionnaireTableQuery({
 *   variables: {
 *      workloadid: // value for 'workloadid'
 *      awsWafrAccount: // value for 'awsWafrAccount'
 *   },
 * });
 */
export function useGetIacQuestionnaireTableQuery(baseOptions: Apollo.QueryHookOptions<GetIacQuestionnaireTableQuery, GetIacQuestionnaireTableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetIacQuestionnaireTableQuery, GetIacQuestionnaireTableQueryVariables>(GetIacQuestionnaireTableDocument, options);
      }
export function useGetIacQuestionnaireTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetIacQuestionnaireTableQuery, GetIacQuestionnaireTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetIacQuestionnaireTableQuery, GetIacQuestionnaireTableQueryVariables>(GetIacQuestionnaireTableDocument, options);
        }
export type GetIacQuestionnaireTableQueryHookResult = ReturnType<typeof useGetIacQuestionnaireTableQuery>;
export type GetIacQuestionnaireTableLazyQueryHookResult = ReturnType<typeof useGetIacQuestionnaireTableLazyQuery>;
export type GetIacQuestionnaireTableQueryResult = Apollo.QueryResult<GetIacQuestionnaireTableQuery, GetIacQuestionnaireTableQueryVariables>;
export const UpdateBestPracticeDocument = gql`
    mutation updateBestPractice($arn: String!, $workloadid: String!, $lens: String!, $controls: [String]) @api(contextKey: "apiName") {
  updateBestPractice(
    arn: $arn
    workLoadId: $workloadid
    LensAlias: $lens
    controls: $controls
  ) {
    id
    status
    result
  }
}
    `;
export type UpdateBestPracticeMutationFn = Apollo.MutationFunction<UpdateBestPracticeMutation, UpdateBestPracticeMutationVariables>;

/**
 * __useUpdateBestPracticeMutation__
 *
 * To run a mutation, you first call `useUpdateBestPracticeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBestPracticeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBestPracticeMutation, { data, loading, error }] = useUpdateBestPracticeMutation({
 *   variables: {
 *      arn: // value for 'arn'
 *      workloadid: // value for 'workloadid'
 *      lens: // value for 'lens'
 *      controls: // value for 'controls'
 *   },
 * });
 */
export function useUpdateBestPracticeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBestPracticeMutation, UpdateBestPracticeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBestPracticeMutation, UpdateBestPracticeMutationVariables>(UpdateBestPracticeDocument, options);
      }
export type UpdateBestPracticeMutationHookResult = ReturnType<typeof useUpdateBestPracticeMutation>;
export type UpdateBestPracticeMutationResult = Apollo.MutationResult<UpdateBestPracticeMutation>;
export type UpdateBestPracticeMutationOptions = Apollo.BaseMutationOptions<UpdateBestPracticeMutation, UpdateBestPracticeMutationVariables>;
export const UpdateIacBestPracticeDocument = gql`
    mutation updateIacBestPractice($workloadid: String!, $lens: String!, $controls: [String], $awsWafrAccount: String!) @api(contextKey: "apiName") {
  updateIacBestPractice(
    workLoadId: $workloadid
    LensAlias: $lens
    controls: $controls
    awsWafrAccount: $awsWafrAccount
  ) {
    id
    status
    result
  }
}
    `;
export type UpdateIacBestPracticeMutationFn = Apollo.MutationFunction<UpdateIacBestPracticeMutation, UpdateIacBestPracticeMutationVariables>;

/**
 * __useUpdateIacBestPracticeMutation__
 *
 * To run a mutation, you first call `useUpdateIacBestPracticeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIacBestPracticeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIacBestPracticeMutation, { data, loading, error }] = useUpdateIacBestPracticeMutation({
 *   variables: {
 *      workloadid: // value for 'workloadid'
 *      lens: // value for 'lens'
 *      controls: // value for 'controls'
 *      awsWafrAccount: // value for 'awsWafrAccount'
 *   },
 * });
 */
export function useUpdateIacBestPracticeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIacBestPracticeMutation, UpdateIacBestPracticeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIacBestPracticeMutation, UpdateIacBestPracticeMutationVariables>(UpdateIacBestPracticeDocument, options);
      }
export type UpdateIacBestPracticeMutationHookResult = ReturnType<typeof useUpdateIacBestPracticeMutation>;
export type UpdateIacBestPracticeMutationResult = Apollo.MutationResult<UpdateIacBestPracticeMutation>;
export type UpdateIacBestPracticeMutationOptions = Apollo.BaseMutationOptions<UpdateIacBestPracticeMutation, UpdateIacBestPracticeMutationVariables>;
export const UpdateIacQuestionnaireTableDocument = gql`
    mutation updateIacQuestionnaireTable($workloadid: String!, $questionid: String!, $answer: String!, $awsWafrAccount: String!) @api(contextKey: "apiName") {
  updateIacQuestionnaireTable(
    workLoadId: $workloadid
    questionId: $questionid
    answer: $answer
    awsWafrAccount: $awsWafrAccount
  ) {
    id
    status
    result
  }
}
    `;
export type UpdateIacQuestionnaireTableMutationFn = Apollo.MutationFunction<UpdateIacQuestionnaireTableMutation, UpdateIacQuestionnaireTableMutationVariables>;

/**
 * __useUpdateIacQuestionnaireTableMutation__
 *
 * To run a mutation, you first call `useUpdateIacQuestionnaireTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateIacQuestionnaireTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateIacQuestionnaireTableMutation, { data, loading, error }] = useUpdateIacQuestionnaireTableMutation({
 *   variables: {
 *      workloadid: // value for 'workloadid'
 *      questionid: // value for 'questionid'
 *      answer: // value for 'answer'
 *      awsWafrAccount: // value for 'awsWafrAccount'
 *   },
 * });
 */
export function useUpdateIacQuestionnaireTableMutation(baseOptions?: Apollo.MutationHookOptions<UpdateIacQuestionnaireTableMutation, UpdateIacQuestionnaireTableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateIacQuestionnaireTableMutation, UpdateIacQuestionnaireTableMutationVariables>(UpdateIacQuestionnaireTableDocument, options);
      }
export type UpdateIacQuestionnaireTableMutationHookResult = ReturnType<typeof useUpdateIacQuestionnaireTableMutation>;
export type UpdateIacQuestionnaireTableMutationResult = Apollo.MutationResult<UpdateIacQuestionnaireTableMutation>;
export type UpdateIacQuestionnaireTableMutationOptions = Apollo.BaseMutationOptions<UpdateIacQuestionnaireTableMutation, UpdateIacQuestionnaireTableMutationVariables>;
export const UpdateQuestionnaireTableDocument = gql`
    mutation updateQuestionnaireTable($arn: String!, $workloadid: String!, $questionid: String!, $answer: String!) @api(contextKey: "apiName") {
  updateQuestionnaireTable(
    arn: $arn
    workLoadId: $workloadid
    questionId: $questionid
    answer: $answer
  ) {
    id
    status
    result
  }
}
    `;
export type UpdateQuestionnaireTableMutationFn = Apollo.MutationFunction<UpdateQuestionnaireTableMutation, UpdateQuestionnaireTableMutationVariables>;

/**
 * __useUpdateQuestionnaireTableMutation__
 *
 * To run a mutation, you first call `useUpdateQuestionnaireTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuestionnaireTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuestionnaireTableMutation, { data, loading, error }] = useUpdateQuestionnaireTableMutation({
 *   variables: {
 *      arn: // value for 'arn'
 *      workloadid: // value for 'workloadid'
 *      questionid: // value for 'questionid'
 *      answer: // value for 'answer'
 *   },
 * });
 */
export function useUpdateQuestionnaireTableMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuestionnaireTableMutation, UpdateQuestionnaireTableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQuestionnaireTableMutation, UpdateQuestionnaireTableMutationVariables>(UpdateQuestionnaireTableDocument, options);
      }
export type UpdateQuestionnaireTableMutationHookResult = ReturnType<typeof useUpdateQuestionnaireTableMutation>;
export type UpdateQuestionnaireTableMutationResult = Apollo.MutationResult<UpdateQuestionnaireTableMutation>;
export type UpdateQuestionnaireTableMutationOptions = Apollo.BaseMutationOptions<UpdateQuestionnaireTableMutation, UpdateQuestionnaireTableMutationVariables>;
export const GetFrameworkQuestionnaireDocument = gql`
    query getFrameworkQuestionnaire($arn: String!, $standard: String!, $frameworkName: String!) @api(contextKey: "apiName") {
  getFrameworkQuestionnaire(
    arn: $arn
    Standard: $standard
    frameworkName: $frameworkName
  ) {
    question_id
    question
    answer_id
    answers
    template
    questionnaire_answer
  }
}
    `;

/**
 * __useGetFrameworkQuestionnaireQuery__
 *
 * To run a query within a React component, call `useGetFrameworkQuestionnaireQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFrameworkQuestionnaireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFrameworkQuestionnaireQuery({
 *   variables: {
 *      arn: // value for 'arn'
 *      standard: // value for 'standard'
 *      frameworkName: // value for 'frameworkName'
 *   },
 * });
 */
export function useGetFrameworkQuestionnaireQuery(baseOptions: Apollo.QueryHookOptions<GetFrameworkQuestionnaireQuery, GetFrameworkQuestionnaireQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFrameworkQuestionnaireQuery, GetFrameworkQuestionnaireQueryVariables>(GetFrameworkQuestionnaireDocument, options);
      }
export function useGetFrameworkQuestionnaireLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFrameworkQuestionnaireQuery, GetFrameworkQuestionnaireQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFrameworkQuestionnaireQuery, GetFrameworkQuestionnaireQueryVariables>(GetFrameworkQuestionnaireDocument, options);
        }
export type GetFrameworkQuestionnaireQueryHookResult = ReturnType<typeof useGetFrameworkQuestionnaireQuery>;
export type GetFrameworkQuestionnaireLazyQueryHookResult = ReturnType<typeof useGetFrameworkQuestionnaireLazyQuery>;
export type GetFrameworkQuestionnaireQueryResult = Apollo.QueryResult<GetFrameworkQuestionnaireQuery, GetFrameworkQuestionnaireQueryVariables>;
export const ListMultiFrameworkDocument = gql`
    query listMultiFramework($arn: String) @api(contextKey: "apiName") {
  listMultiFramework(arn: $arn) {
    items {
      frameworkName
      standard
      awsTechnicalReviewAccount
      connectedAccounts
      createdAt
    }
    nextToken
  }
}
    `;

/**
 * __useListMultiFrameworkQuery__
 *
 * To run a query within a React component, call `useListMultiFrameworkQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMultiFrameworkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMultiFrameworkQuery({
 *   variables: {
 *      arn: // value for 'arn'
 *   },
 * });
 */
export function useListMultiFrameworkQuery(baseOptions?: Apollo.QueryHookOptions<ListMultiFrameworkQuery, ListMultiFrameworkQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListMultiFrameworkQuery, ListMultiFrameworkQueryVariables>(ListMultiFrameworkDocument, options);
      }
export function useListMultiFrameworkLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListMultiFrameworkQuery, ListMultiFrameworkQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListMultiFrameworkQuery, ListMultiFrameworkQueryVariables>(ListMultiFrameworkDocument, options);
        }
export type ListMultiFrameworkQueryHookResult = ReturnType<typeof useListMultiFrameworkQuery>;
export type ListMultiFrameworkLazyQueryHookResult = ReturnType<typeof useListMultiFrameworkLazyQuery>;
export type ListMultiFrameworkQueryResult = Apollo.QueryResult<ListMultiFrameworkQuery, ListMultiFrameworkQueryVariables>;
export const CreateMultiFrameworkDocument = gql`
    mutation createMultiFramework($arn: String!, $standard: String!, $frameworkName: String!, $awsTechnicalReviewAccount: String!, $connectedAccounts: [ConnectedAccountInput]) @api(contextKey: "apiName") {
  createMultiFramework(
    arn: $arn
    standard: $standard
    frameworkName: $frameworkName
    awsTechnicalReviewAccount: $awsTechnicalReviewAccount
    connectedAccounts: $connectedAccounts
  ) {
    id
    status
    result
  }
}
    `;
export type CreateMultiFrameworkMutationFn = Apollo.MutationFunction<CreateMultiFrameworkMutation, CreateMultiFrameworkMutationVariables>;

/**
 * __useCreateMultiFrameworkMutation__
 *
 * To run a mutation, you first call `useCreateMultiFrameworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMultiFrameworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMultiFrameworkMutation, { data, loading, error }] = useCreateMultiFrameworkMutation({
 *   variables: {
 *      arn: // value for 'arn'
 *      standard: // value for 'standard'
 *      frameworkName: // value for 'frameworkName'
 *      awsTechnicalReviewAccount: // value for 'awsTechnicalReviewAccount'
 *      connectedAccounts: // value for 'connectedAccounts'
 *   },
 * });
 */
export function useCreateMultiFrameworkMutation(baseOptions?: Apollo.MutationHookOptions<CreateMultiFrameworkMutation, CreateMultiFrameworkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMultiFrameworkMutation, CreateMultiFrameworkMutationVariables>(CreateMultiFrameworkDocument, options);
      }
export type CreateMultiFrameworkMutationHookResult = ReturnType<typeof useCreateMultiFrameworkMutation>;
export type CreateMultiFrameworkMutationResult = Apollo.MutationResult<CreateMultiFrameworkMutation>;
export type CreateMultiFrameworkMutationOptions = Apollo.BaseMutationOptions<CreateMultiFrameworkMutation, CreateMultiFrameworkMutationVariables>;
export const UpdateMultiFrameworkQuestionnaireTableDocument = gql`
    mutation updateMultiFrameworkQuestionnaireTable($arn: String!, $standard: String!, $frameworkName: String!, $questionId: String!, $answer: String!) @api(contextKey: "apiName") {
  updateMultiFrameworkQuestionnaireTable(
    arn: $arn
    standard: $standard
    frameworkName: $frameworkName
    questionId: $questionId
    answer: $answer
  ) {
    id
    status
    result
  }
}
    `;
export type UpdateMultiFrameworkQuestionnaireTableMutationFn = Apollo.MutationFunction<UpdateMultiFrameworkQuestionnaireTableMutation, UpdateMultiFrameworkQuestionnaireTableMutationVariables>;

/**
 * __useUpdateMultiFrameworkQuestionnaireTableMutation__
 *
 * To run a mutation, you first call `useUpdateMultiFrameworkQuestionnaireTableMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMultiFrameworkQuestionnaireTableMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMultiFrameworkQuestionnaireTableMutation, { data, loading, error }] = useUpdateMultiFrameworkQuestionnaireTableMutation({
 *   variables: {
 *      arn: // value for 'arn'
 *      standard: // value for 'standard'
 *      frameworkName: // value for 'frameworkName'
 *      questionId: // value for 'questionId'
 *      answer: // value for 'answer'
 *   },
 * });
 */
export function useUpdateMultiFrameworkQuestionnaireTableMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMultiFrameworkQuestionnaireTableMutation, UpdateMultiFrameworkQuestionnaireTableMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMultiFrameworkQuestionnaireTableMutation, UpdateMultiFrameworkQuestionnaireTableMutationVariables>(UpdateMultiFrameworkQuestionnaireTableDocument, options);
      }
export type UpdateMultiFrameworkQuestionnaireTableMutationHookResult = ReturnType<typeof useUpdateMultiFrameworkQuestionnaireTableMutation>;
export type UpdateMultiFrameworkQuestionnaireTableMutationResult = Apollo.MutationResult<UpdateMultiFrameworkQuestionnaireTableMutation>;
export type UpdateMultiFrameworkQuestionnaireTableMutationOptions = Apollo.BaseMutationOptions<UpdateMultiFrameworkQuestionnaireTableMutation, UpdateMultiFrameworkQuestionnaireTableMutationVariables>;
export const DeleteFrameworkDocument = gql`
    mutation deleteFramework($arn: String!, $frameworkName: String!) @api(contextKey: "apiName") {
  deleteFramework(arn: $arn, frameworkName: $frameworkName) {
    id
    status
    result
  }
}
    `;
export type DeleteFrameworkMutationFn = Apollo.MutationFunction<DeleteFrameworkMutation, DeleteFrameworkMutationVariables>;

/**
 * __useDeleteFrameworkMutation__
 *
 * To run a mutation, you first call `useDeleteFrameworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFrameworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFrameworkMutation, { data, loading, error }] = useDeleteFrameworkMutation({
 *   variables: {
 *      arn: // value for 'arn'
 *      frameworkName: // value for 'frameworkName'
 *   },
 * });
 */
export function useDeleteFrameworkMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFrameworkMutation, DeleteFrameworkMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFrameworkMutation, DeleteFrameworkMutationVariables>(DeleteFrameworkDocument, options);
      }
export type DeleteFrameworkMutationHookResult = ReturnType<typeof useDeleteFrameworkMutation>;
export type DeleteFrameworkMutationResult = Apollo.MutationResult<DeleteFrameworkMutation>;
export type DeleteFrameworkMutationOptions = Apollo.BaseMutationOptions<DeleteFrameworkMutation, DeleteFrameworkMutationVariables>;
export const GetAccountDocument = gql`
    query getAccount($arn: String!) {
  getAccount(arn: $arn) {
    organisation
    arn
    accessnickname
    externalid
    sharrversion
    defaultregion
    accounttype
    member_accounts
    member_regions
    connectedAccounts {
      accountIdRef
      region
    }
  }
}
    `;

/**
 * __useGetAccountQuery__
 *
 * To run a query within a React component, call `useGetAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountQuery({
 *   variables: {
 *      arn: // value for 'arn'
 *   },
 * });
 */
export function useGetAccountQuery(baseOptions: Apollo.QueryHookOptions<GetAccountQuery, GetAccountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountQuery, GetAccountQueryVariables>(GetAccountDocument, options);
      }
export function useGetAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountQuery, GetAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountQuery, GetAccountQueryVariables>(GetAccountDocument, options);
        }
export type GetAccountQueryHookResult = ReturnType<typeof useGetAccountQuery>;
export type GetAccountLazyQueryHookResult = ReturnType<typeof useGetAccountLazyQuery>;
export type GetAccountQueryResult = Apollo.QueryResult<GetAccountQuery, GetAccountQueryVariables>;
export const ToggleAutoRemediationDocument = gql`
    mutation toggleAutoRemediation($arn: String!, $controlnames: [String!], $action: String!) @api(contextKey: "apiName") {
  toggleAutoRemediation(arn: $arn, controlnames: $controlnames, action: $action) {
    id
    status
    result
  }
}
    `;
export type ToggleAutoRemediationMutationFn = Apollo.MutationFunction<ToggleAutoRemediationMutation, ToggleAutoRemediationMutationVariables>;

/**
 * __useToggleAutoRemediationMutation__
 *
 * To run a mutation, you first call `useToggleAutoRemediationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleAutoRemediationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleAutoRemediationMutation, { data, loading, error }] = useToggleAutoRemediationMutation({
 *   variables: {
 *      arn: // value for 'arn'
 *      controlnames: // value for 'controlnames'
 *      action: // value for 'action'
 *   },
 * });
 */
export function useToggleAutoRemediationMutation(baseOptions?: Apollo.MutationHookOptions<ToggleAutoRemediationMutation, ToggleAutoRemediationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleAutoRemediationMutation, ToggleAutoRemediationMutationVariables>(ToggleAutoRemediationDocument, options);
      }
export type ToggleAutoRemediationMutationHookResult = ReturnType<typeof useToggleAutoRemediationMutation>;
export type ToggleAutoRemediationMutationResult = Apollo.MutationResult<ToggleAutoRemediationMutation>;
export type ToggleAutoRemediationMutationOptions = Apollo.BaseMutationOptions<ToggleAutoRemediationMutation, ToggleAutoRemediationMutationVariables>;
export const GetTemplateEngineSecurityResponseDocument = gql`
    query getTemplateEngineSecurityResponse($processType: String!, $fileName: String, $fileAlias: String, $fileString: String, $summaryId: String, $summaryList: String) @api(contextKey: "apiName") {
  getTemplateEngineSecurityResponse(
    processType: $processType
    fileName: $fileName
    fileAlias: $fileAlias
    fileString: $fileString
    summaryId: $summaryId
    summaryList: $summaryList
  ) {
    status
    msg
    result {
      results
      summary
    }
    error
  }
}
    `;

/**
 * __useGetTemplateEngineSecurityResponseQuery__
 *
 * To run a query within a React component, call `useGetTemplateEngineSecurityResponseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateEngineSecurityResponseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateEngineSecurityResponseQuery({
 *   variables: {
 *      processType: // value for 'processType'
 *      fileName: // value for 'fileName'
 *      fileAlias: // value for 'fileAlias'
 *      fileString: // value for 'fileString'
 *      summaryId: // value for 'summaryId'
 *      summaryList: // value for 'summaryList'
 *   },
 * });
 */
export function useGetTemplateEngineSecurityResponseQuery(baseOptions: Apollo.QueryHookOptions<GetTemplateEngineSecurityResponseQuery, GetTemplateEngineSecurityResponseQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTemplateEngineSecurityResponseQuery, GetTemplateEngineSecurityResponseQueryVariables>(GetTemplateEngineSecurityResponseDocument, options);
      }
export function useGetTemplateEngineSecurityResponseLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTemplateEngineSecurityResponseQuery, GetTemplateEngineSecurityResponseQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTemplateEngineSecurityResponseQuery, GetTemplateEngineSecurityResponseQueryVariables>(GetTemplateEngineSecurityResponseDocument, options);
        }
export type GetTemplateEngineSecurityResponseQueryHookResult = ReturnType<typeof useGetTemplateEngineSecurityResponseQuery>;
export type GetTemplateEngineSecurityResponseLazyQueryHookResult = ReturnType<typeof useGetTemplateEngineSecurityResponseLazyQuery>;
export type GetTemplateEngineSecurityResponseQueryResult = Apollo.QueryResult<GetTemplateEngineSecurityResponseQuery, GetTemplateEngineSecurityResponseQueryVariables>;
export const GetTemplateEngineListTemplatesDocument = gql`
    query getTemplateEngineListTemplates($page: Int, $limit: Int) @api(contextKey: "apiName") {
  getTemplateEngineListTemplates(page: $page, limit: $limit) {
    status
    msg
    error
    results
    page
  }
}
    `;

/**
 * __useGetTemplateEngineListTemplatesQuery__
 *
 * To run a query within a React component, call `useGetTemplateEngineListTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplateEngineListTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplateEngineListTemplatesQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetTemplateEngineListTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetTemplateEngineListTemplatesQuery, GetTemplateEngineListTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTemplateEngineListTemplatesQuery, GetTemplateEngineListTemplatesQueryVariables>(GetTemplateEngineListTemplatesDocument, options);
      }
export function useGetTemplateEngineListTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTemplateEngineListTemplatesQuery, GetTemplateEngineListTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTemplateEngineListTemplatesQuery, GetTemplateEngineListTemplatesQueryVariables>(GetTemplateEngineListTemplatesDocument, options);
        }
export type GetTemplateEngineListTemplatesQueryHookResult = ReturnType<typeof useGetTemplateEngineListTemplatesQuery>;
export type GetTemplateEngineListTemplatesLazyQueryHookResult = ReturnType<typeof useGetTemplateEngineListTemplatesLazyQuery>;
export type GetTemplateEngineListTemplatesQueryResult = Apollo.QueryResult<GetTemplateEngineListTemplatesQuery, GetTemplateEngineListTemplatesQueryVariables>;
export const UserRollbackProcessDocument = gql`
    query userRollbackProcess($organisation: String, $accessnickname: String, $arn: String, $externalid: String, $region: String, $keepSecHub: String, $keepConfig: String) @api(contextKey: "apiName") {
  userRollbackProcess(
    organisation: $organisation
    accessnickname: $accessnickname
    arn: $arn
    externalid: $externalid
    region: $region
    keepSecHub: $keepSecHub
    keepConfig: $keepConfig
  ) {
    executionArn
  }
}
    `;

/**
 * __useUserRollbackProcessQuery__
 *
 * To run a query within a React component, call `useUserRollbackProcessQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRollbackProcessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRollbackProcessQuery({
 *   variables: {
 *      organisation: // value for 'organisation'
 *      accessnickname: // value for 'accessnickname'
 *      arn: // value for 'arn'
 *      externalid: // value for 'externalid'
 *      region: // value for 'region'
 *      keepSecHub: // value for 'keepSecHub'
 *      keepConfig: // value for 'keepConfig'
 *   },
 * });
 */
export function useUserRollbackProcessQuery(baseOptions?: Apollo.QueryHookOptions<UserRollbackProcessQuery, UserRollbackProcessQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserRollbackProcessQuery, UserRollbackProcessQueryVariables>(UserRollbackProcessDocument, options);
      }
export function useUserRollbackProcessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserRollbackProcessQuery, UserRollbackProcessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserRollbackProcessQuery, UserRollbackProcessQueryVariables>(UserRollbackProcessDocument, options);
        }
export type UserRollbackProcessQueryHookResult = ReturnType<typeof useUserRollbackProcessQuery>;
export type UserRollbackProcessLazyQueryHookResult = ReturnType<typeof useUserRollbackProcessLazyQuery>;
export type UserRollbackProcessQueryResult = Apollo.QueryResult<UserRollbackProcessQuery, UserRollbackProcessQueryVariables>;
export const UserSetupProcessDocument = gql`
    query userSetupProcess($organisation: String, $accessnickname: String, $arn: String, $externalid: String, $region: String, $accounttype: String) @api(contextKey: "apiName") {
  userSetupProcess(
    organisation: $organisation
    accessnickname: $accessnickname
    arn: $arn
    externalid: $externalid
    region: $region
    accounttype: $accounttype
  ) {
    executionArn
  }
}
    `;

/**
 * __useUserSetupProcessQuery__
 *
 * To run a query within a React component, call `useUserSetupProcessQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserSetupProcessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserSetupProcessQuery({
 *   variables: {
 *      organisation: // value for 'organisation'
 *      accessnickname: // value for 'accessnickname'
 *      arn: // value for 'arn'
 *      externalid: // value for 'externalid'
 *      region: // value for 'region'
 *      accounttype: // value for 'accounttype'
 *   },
 * });
 */
export function useUserSetupProcessQuery(baseOptions?: Apollo.QueryHookOptions<UserSetupProcessQuery, UserSetupProcessQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserSetupProcessQuery, UserSetupProcessQueryVariables>(UserSetupProcessDocument, options);
      }
export function useUserSetupProcessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserSetupProcessQuery, UserSetupProcessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserSetupProcessQuery, UserSetupProcessQueryVariables>(UserSetupProcessDocument, options);
        }
export type UserSetupProcessQueryHookResult = ReturnType<typeof useUserSetupProcessQuery>;
export type UserSetupProcessLazyQueryHookResult = ReturnType<typeof useUserSetupProcessLazyQuery>;
export type UserSetupProcessQueryResult = Apollo.QueryResult<UserSetupProcessQuery, UserSetupProcessQueryVariables>;
export const UserUpgradeProcessDocument = gql`
    query userUpgradeProcess($organisation: String, $accessnickname: String, $arn: String, $externalid: String, $region: String, $accounttype: String) @api(contextKey: "apiName") {
  userUpgradeProcess(
    organisation: $organisation
    accessnickname: $accessnickname
    arn: $arn
    externalid: $externalid
    region: $region
    accounttype: $accounttype
  ) {
    executionArn
  }
}
    `;

/**
 * __useUserUpgradeProcessQuery__
 *
 * To run a query within a React component, call `useUserUpgradeProcessQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserUpgradeProcessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserUpgradeProcessQuery({
 *   variables: {
 *      organisation: // value for 'organisation'
 *      accessnickname: // value for 'accessnickname'
 *      arn: // value for 'arn'
 *      externalid: // value for 'externalid'
 *      region: // value for 'region'
 *      accounttype: // value for 'accounttype'
 *   },
 * });
 */
export function useUserUpgradeProcessQuery(baseOptions?: Apollo.QueryHookOptions<UserUpgradeProcessQuery, UserUpgradeProcessQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserUpgradeProcessQuery, UserUpgradeProcessQueryVariables>(UserUpgradeProcessDocument, options);
      }
export function useUserUpgradeProcessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserUpgradeProcessQuery, UserUpgradeProcessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserUpgradeProcessQuery, UserUpgradeProcessQueryVariables>(UserUpgradeProcessDocument, options);
        }
export type UserUpgradeProcessQueryHookResult = ReturnType<typeof useUserUpgradeProcessQuery>;
export type UserUpgradeProcessLazyQueryHookResult = ReturnType<typeof useUserUpgradeProcessLazyQuery>;
export type UserUpgradeProcessQueryResult = Apollo.QueryResult<UserUpgradeProcessQuery, UserUpgradeProcessQueryVariables>;
export const AutomateProgressStatusDocument = gql`
    query automateProgressStatus($accountid: String, $organisation: String) @api(contextKey: "apiName") {
  automateProgressStatus(accountid: $accountid, organisation: $organisation) {
    progress
    executionArn
    status
    created_at
  }
}
    `;

/**
 * __useAutomateProgressStatusQuery__
 *
 * To run a query within a React component, call `useAutomateProgressStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useAutomateProgressStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAutomateProgressStatusQuery({
 *   variables: {
 *      accountid: // value for 'accountid'
 *      organisation: // value for 'organisation'
 *   },
 * });
 */
export function useAutomateProgressStatusQuery(baseOptions?: Apollo.QueryHookOptions<AutomateProgressStatusQuery, AutomateProgressStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AutomateProgressStatusQuery, AutomateProgressStatusQueryVariables>(AutomateProgressStatusDocument, options);
      }
export function useAutomateProgressStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AutomateProgressStatusQuery, AutomateProgressStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AutomateProgressStatusQuery, AutomateProgressStatusQueryVariables>(AutomateProgressStatusDocument, options);
        }
export type AutomateProgressStatusQueryHookResult = ReturnType<typeof useAutomateProgressStatusQuery>;
export type AutomateProgressStatusLazyQueryHookResult = ReturnType<typeof useAutomateProgressStatusLazyQuery>;
export type AutomateProgressStatusQueryResult = Apollo.QueryResult<AutomateProgressStatusQuery, AutomateProgressStatusQueryVariables>;
export const GetUserDeployEventsDocument = gql`
    query getUserDeployEvents($organisation: String) @api(contextKey: "apiName") {
  getUserDeployEvents(organisation: $organisation) {
    items {
      account_id
      task_type
      status
      created_at
      task
    }
    nextToken
  }
}
    `;

/**
 * __useGetUserDeployEventsQuery__
 *
 * To run a query within a React component, call `useGetUserDeployEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDeployEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDeployEventsQuery({
 *   variables: {
 *      organisation: // value for 'organisation'
 *   },
 * });
 */
export function useGetUserDeployEventsQuery(baseOptions?: Apollo.QueryHookOptions<GetUserDeployEventsQuery, GetUserDeployEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserDeployEventsQuery, GetUserDeployEventsQueryVariables>(GetUserDeployEventsDocument, options);
      }
export function useGetUserDeployEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserDeployEventsQuery, GetUserDeployEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserDeployEventsQuery, GetUserDeployEventsQueryVariables>(GetUserDeployEventsDocument, options);
        }
export type GetUserDeployEventsQueryHookResult = ReturnType<typeof useGetUserDeployEventsQuery>;
export type GetUserDeployEventsLazyQueryHookResult = ReturnType<typeof useGetUserDeployEventsLazyQuery>;
export type GetUserDeployEventsQueryResult = Apollo.QueryResult<GetUserDeployEventsQuery, GetUserDeployEventsQueryVariables>;
export const GetQuestionnaireDocument = gql`
    query getQuestionnaire @api(contextKey: "apiName") {
  getQuestionnaire {
    id
    description
  }
}
    `;

/**
 * __useGetQuestionnaireQuery__
 *
 * To run a query within a React component, call `useGetQuestionnaireQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetQuestionnaireQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetQuestionnaireQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetQuestionnaireQuery(baseOptions?: Apollo.QueryHookOptions<GetQuestionnaireQuery, GetQuestionnaireQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetQuestionnaireQuery, GetQuestionnaireQueryVariables>(GetQuestionnaireDocument, options);
      }
export function useGetQuestionnaireLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetQuestionnaireQuery, GetQuestionnaireQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetQuestionnaireQuery, GetQuestionnaireQueryVariables>(GetQuestionnaireDocument, options);
        }
export type GetQuestionnaireQueryHookResult = ReturnType<typeof useGetQuestionnaireQuery>;
export type GetQuestionnaireLazyQueryHookResult = ReturnType<typeof useGetQuestionnaireLazyQuery>;
export type GetQuestionnaireQueryResult = Apollo.QueryResult<GetQuestionnaireQuery, GetQuestionnaireQueryVariables>;
export const SubmitQuestionnaireAnswerDocument = gql`
    mutation submitQuestionnaireAnswer($arn: String!, $workLoadId: String!, $questionId: String!, $questionAnswer: String!, $lensAlias: String!) @api(contextKey: "apiName") {
  submitQuestionnaireAnswer(
    arn: $arn
    workLoadId: $workLoadId
    questionId: $questionId
    questionAnswer: $questionAnswer
    lensAlias: $lensAlias
  ) {
    id
    status
    result
  }
}
    `;
export type SubmitQuestionnaireAnswerMutationFn = Apollo.MutationFunction<SubmitQuestionnaireAnswerMutation, SubmitQuestionnaireAnswerMutationVariables>;

/**
 * __useSubmitQuestionnaireAnswerMutation__
 *
 * To run a mutation, you first call `useSubmitQuestionnaireAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitQuestionnaireAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitQuestionnaireAnswerMutation, { data, loading, error }] = useSubmitQuestionnaireAnswerMutation({
 *   variables: {
 *      arn: // value for 'arn'
 *      workLoadId: // value for 'workLoadId'
 *      questionId: // value for 'questionId'
 *      questionAnswer: // value for 'questionAnswer'
 *      lensAlias: // value for 'lensAlias'
 *   },
 * });
 */
export function useSubmitQuestionnaireAnswerMutation(baseOptions?: Apollo.MutationHookOptions<SubmitQuestionnaireAnswerMutation, SubmitQuestionnaireAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitQuestionnaireAnswerMutation, SubmitQuestionnaireAnswerMutationVariables>(SubmitQuestionnaireAnswerDocument, options);
      }
export type SubmitQuestionnaireAnswerMutationHookResult = ReturnType<typeof useSubmitQuestionnaireAnswerMutation>;
export type SubmitQuestionnaireAnswerMutationResult = Apollo.MutationResult<SubmitQuestionnaireAnswerMutation>;
export type SubmitQuestionnaireAnswerMutationOptions = Apollo.BaseMutationOptions<SubmitQuestionnaireAnswerMutation, SubmitQuestionnaireAnswerMutationVariables>;
export const SubmitIacQuestionnaireAnswerDocument = gql`
    mutation submitIacQuestionnaireAnswer($workLoadId: String!, $questionId: String!, $questionAnswer: String!, $lensAlias: String!, $awsWafrAccount: String!) @api(contextKey: "apiName") {
  submitIacQuestionnaireAnswer(
    workLoadId: $workLoadId
    questionId: $questionId
    questionAnswer: $questionAnswer
    lensAlias: $lensAlias
    awsWafrAccount: $awsWafrAccount
  ) {
    id
    status
    result
  }
}
    `;
export type SubmitIacQuestionnaireAnswerMutationFn = Apollo.MutationFunction<SubmitIacQuestionnaireAnswerMutation, SubmitIacQuestionnaireAnswerMutationVariables>;

/**
 * __useSubmitIacQuestionnaireAnswerMutation__
 *
 * To run a mutation, you first call `useSubmitIacQuestionnaireAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitIacQuestionnaireAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitIacQuestionnaireAnswerMutation, { data, loading, error }] = useSubmitIacQuestionnaireAnswerMutation({
 *   variables: {
 *      workLoadId: // value for 'workLoadId'
 *      questionId: // value for 'questionId'
 *      questionAnswer: // value for 'questionAnswer'
 *      lensAlias: // value for 'lensAlias'
 *      awsWafrAccount: // value for 'awsWafrAccount'
 *   },
 * });
 */
export function useSubmitIacQuestionnaireAnswerMutation(baseOptions?: Apollo.MutationHookOptions<SubmitIacQuestionnaireAnswerMutation, SubmitIacQuestionnaireAnswerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitIacQuestionnaireAnswerMutation, SubmitIacQuestionnaireAnswerMutationVariables>(SubmitIacQuestionnaireAnswerDocument, options);
      }
export type SubmitIacQuestionnaireAnswerMutationHookResult = ReturnType<typeof useSubmitIacQuestionnaireAnswerMutation>;
export type SubmitIacQuestionnaireAnswerMutationResult = Apollo.MutationResult<SubmitIacQuestionnaireAnswerMutation>;
export type SubmitIacQuestionnaireAnswerMutationOptions = Apollo.BaseMutationOptions<SubmitIacQuestionnaireAnswerMutation, SubmitIacQuestionnaireAnswerMutationVariables>;
export const SubmitEmailDocument = gql`
    mutation submitEmail($arn: String!, $workLoadId: String!, $stage: String!) @api(contextKey: "apiName") {
  submitEmail(arn: $arn, workLoadId: $workLoadId, stage: $stage) {
    id
    status
    result
  }
}
    `;
export type SubmitEmailMutationFn = Apollo.MutationFunction<SubmitEmailMutation, SubmitEmailMutationVariables>;

/**
 * __useSubmitEmailMutation__
 *
 * To run a mutation, you first call `useSubmitEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitEmailMutation, { data, loading, error }] = useSubmitEmailMutation({
 *   variables: {
 *      arn: // value for 'arn'
 *      workLoadId: // value for 'workLoadId'
 *      stage: // value for 'stage'
 *   },
 * });
 */
export function useSubmitEmailMutation(baseOptions?: Apollo.MutationHookOptions<SubmitEmailMutation, SubmitEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitEmailMutation, SubmitEmailMutationVariables>(SubmitEmailDocument, options);
      }
export type SubmitEmailMutationHookResult = ReturnType<typeof useSubmitEmailMutation>;
export type SubmitEmailMutationResult = Apollo.MutationResult<SubmitEmailMutation>;
export type SubmitEmailMutationOptions = Apollo.BaseMutationOptions<SubmitEmailMutation, SubmitEmailMutationVariables>;
export const SubmitIacEmailDocument = gql`
    mutation submitIacEmail($workLoadId: String!, $stage: String!, $awsWafrAccount: String!) @api(contextKey: "apiName") {
  submitIacEmail(
    workLoadId: $workLoadId
    stage: $stage
    awsWafrAccount: $awsWafrAccount
  ) {
    id
    status
    result
  }
}
    `;
export type SubmitIacEmailMutationFn = Apollo.MutationFunction<SubmitIacEmailMutation, SubmitIacEmailMutationVariables>;

/**
 * __useSubmitIacEmailMutation__
 *
 * To run a mutation, you first call `useSubmitIacEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitIacEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitIacEmailMutation, { data, loading, error }] = useSubmitIacEmailMutation({
 *   variables: {
 *      workLoadId: // value for 'workLoadId'
 *      stage: // value for 'stage'
 *      awsWafrAccount: // value for 'awsWafrAccount'
 *   },
 * });
 */
export function useSubmitIacEmailMutation(baseOptions?: Apollo.MutationHookOptions<SubmitIacEmailMutation, SubmitIacEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitIacEmailMutation, SubmitIacEmailMutationVariables>(SubmitIacEmailDocument, options);
      }
export type SubmitIacEmailMutationHookResult = ReturnType<typeof useSubmitIacEmailMutation>;
export type SubmitIacEmailMutationResult = Apollo.MutationResult<SubmitIacEmailMutation>;
export type SubmitIacEmailMutationOptions = Apollo.BaseMutationOptions<SubmitIacEmailMutation, SubmitIacEmailMutationVariables>;
export const SubmitFindingsEmailDocument = gql`
    mutation submitFindingsEmail($arn: String!, $connectedAccounts: [ConnectedAccountInput], $standard: String!, $compliancestatus: String!, $intrusive: String!) @api(contextKey: "apiName") {
  submitFindingsEmail(
    arn: $arn
    connectedAccounts: $connectedAccounts
    standard: $standard
    compliancestatus: $compliancestatus
    intrusive: $intrusive
  ) {
    id
    status
    result
  }
}
    `;
export type SubmitFindingsEmailMutationFn = Apollo.MutationFunction<SubmitFindingsEmailMutation, SubmitFindingsEmailMutationVariables>;

/**
 * __useSubmitFindingsEmailMutation__
 *
 * To run a mutation, you first call `useSubmitFindingsEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitFindingsEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitFindingsEmailMutation, { data, loading, error }] = useSubmitFindingsEmailMutation({
 *   variables: {
 *      arn: // value for 'arn'
 *      connectedAccounts: // value for 'connectedAccounts'
 *      standard: // value for 'standard'
 *      compliancestatus: // value for 'compliancestatus'
 *      intrusive: // value for 'intrusive'
 *   },
 * });
 */
export function useSubmitFindingsEmailMutation(baseOptions?: Apollo.MutationHookOptions<SubmitFindingsEmailMutation, SubmitFindingsEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitFindingsEmailMutation, SubmitFindingsEmailMutationVariables>(SubmitFindingsEmailDocument, options);
      }
export type SubmitFindingsEmailMutationHookResult = ReturnType<typeof useSubmitFindingsEmailMutation>;
export type SubmitFindingsEmailMutationResult = Apollo.MutationResult<SubmitFindingsEmailMutation>;
export type SubmitFindingsEmailMutationOptions = Apollo.BaseMutationOptions<SubmitFindingsEmailMutation, SubmitFindingsEmailMutationVariables>;
export const GetExternalApiTokenDocument = gql`
    query getExternalApiToken @api(contextKey: "apiName") {
  getExternalApiToken {
    status
    msg
    error
    apiToken
  }
}
    `;

/**
 * __useGetExternalApiTokenQuery__
 *
 * To run a query within a React component, call `useGetExternalApiTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExternalApiTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExternalApiTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetExternalApiTokenQuery(baseOptions?: Apollo.QueryHookOptions<GetExternalApiTokenQuery, GetExternalApiTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetExternalApiTokenQuery, GetExternalApiTokenQueryVariables>(GetExternalApiTokenDocument, options);
      }
export function useGetExternalApiTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetExternalApiTokenQuery, GetExternalApiTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetExternalApiTokenQuery, GetExternalApiTokenQueryVariables>(GetExternalApiTokenDocument, options);
        }
export type GetExternalApiTokenQueryHookResult = ReturnType<typeof useGetExternalApiTokenQuery>;
export type GetExternalApiTokenLazyQueryHookResult = ReturnType<typeof useGetExternalApiTokenLazyQuery>;
export type GetExternalApiTokenQueryResult = Apollo.QueryResult<GetExternalApiTokenQuery, GetExternalApiTokenQueryVariables>;
export const GetBestPracticeDocument = gql`
    query getBestPractice($checkType: String!, $resource: String!, $description: String!, $checkId: String!, $fileFormat: String!) @api(contextKey: "apiName") {
  getBestPractice(
    checkType: $checkType
    resource: $resource
    description: $description
    checkId: $checkId
    fileFormat: $fileFormat
  ) {
    status
    msg
    error
    uuid
  }
}
    `;

/**
 * __useGetBestPracticeQuery__
 *
 * To run a query within a React component, call `useGetBestPracticeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBestPracticeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBestPracticeQuery({
 *   variables: {
 *      checkType: // value for 'checkType'
 *      resource: // value for 'resource'
 *      description: // value for 'description'
 *      checkId: // value for 'checkId'
 *      fileFormat: // value for 'fileFormat'
 *   },
 * });
 */
export function useGetBestPracticeQuery(baseOptions: Apollo.QueryHookOptions<GetBestPracticeQuery, GetBestPracticeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBestPracticeQuery, GetBestPracticeQueryVariables>(GetBestPracticeDocument, options);
      }
export function useGetBestPracticeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBestPracticeQuery, GetBestPracticeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBestPracticeQuery, GetBestPracticeQueryVariables>(GetBestPracticeDocument, options);
        }
export type GetBestPracticeQueryHookResult = ReturnType<typeof useGetBestPracticeQuery>;
export type GetBestPracticeLazyQueryHookResult = ReturnType<typeof useGetBestPracticeLazyQuery>;
export type GetBestPracticeQueryResult = Apollo.QueryResult<GetBestPracticeQuery, GetBestPracticeQueryVariables>;
export const GetSecurityHubMembersDocument = gql`
    query getSecurityHubMembers($arn: String!) @api(contextKey: "apiName") {
  getSecurityHubMembers(arn: $arn) {
    AccountId
    AdministratorId
  }
}
    `;

/**
 * __useGetSecurityHubMembersQuery__
 *
 * To run a query within a React component, call `useGetSecurityHubMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSecurityHubMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSecurityHubMembersQuery({
 *   variables: {
 *      arn: // value for 'arn'
 *   },
 * });
 */
export function useGetSecurityHubMembersQuery(baseOptions: Apollo.QueryHookOptions<GetSecurityHubMembersQuery, GetSecurityHubMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSecurityHubMembersQuery, GetSecurityHubMembersQueryVariables>(GetSecurityHubMembersDocument, options);
      }
export function useGetSecurityHubMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSecurityHubMembersQuery, GetSecurityHubMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSecurityHubMembersQuery, GetSecurityHubMembersQueryVariables>(GetSecurityHubMembersDocument, options);
        }
export type GetSecurityHubMembersQueryHookResult = ReturnType<typeof useGetSecurityHubMembersQuery>;
export type GetSecurityHubMembersLazyQueryHookResult = ReturnType<typeof useGetSecurityHubMembersLazyQuery>;
export type GetSecurityHubMembersQueryResult = Apollo.QueryResult<GetSecurityHubMembersQuery, GetSecurityHubMembersQueryVariables>;
export const StartStackSetDeploySfnDocument = gql`
    mutation startStackSetDeploySfn($arn: String!, $administratorRoleArn: String!, $executionRoleName: String!, $permissionModel: String!, $accountRefs: [String!], $regions: [String!]) @api(contextKey: "apiName") {
  startStackSetDeploySfn(
    arn: $arn
    administratorRoleArn: $administratorRoleArn
    executionRoleName: $executionRoleName
    permissionModel: $permissionModel
    accountRefs: $accountRefs
    regions: $regions
  ) {
    executionArn
  }
}
    `;
export type StartStackSetDeploySfnMutationFn = Apollo.MutationFunction<StartStackSetDeploySfnMutation, StartStackSetDeploySfnMutationVariables>;

/**
 * __useStartStackSetDeploySfnMutation__
 *
 * To run a mutation, you first call `useStartStackSetDeploySfnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartStackSetDeploySfnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startStackSetDeploySfnMutation, { data, loading, error }] = useStartStackSetDeploySfnMutation({
 *   variables: {
 *      arn: // value for 'arn'
 *      administratorRoleArn: // value for 'administratorRoleArn'
 *      executionRoleName: // value for 'executionRoleName'
 *      permissionModel: // value for 'permissionModel'
 *      accountRefs: // value for 'accountRefs'
 *      regions: // value for 'regions'
 *   },
 * });
 */
export function useStartStackSetDeploySfnMutation(baseOptions?: Apollo.MutationHookOptions<StartStackSetDeploySfnMutation, StartStackSetDeploySfnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartStackSetDeploySfnMutation, StartStackSetDeploySfnMutationVariables>(StartStackSetDeploySfnDocument, options);
      }
export type StartStackSetDeploySfnMutationHookResult = ReturnType<typeof useStartStackSetDeploySfnMutation>;
export type StartStackSetDeploySfnMutationResult = Apollo.MutationResult<StartStackSetDeploySfnMutation>;
export type StartStackSetDeploySfnMutationOptions = Apollo.BaseMutationOptions<StartStackSetDeploySfnMutation, StartStackSetDeploySfnMutationVariables>;
export const GetDashboardOverviewDocument = gql`
    query getDashboardOverview($arn: String!) {
  getDashboardOverview(arn: $arn) {
    totalItems
    totalCompliant
    totalFail
  }
}
    `;

/**
 * __useGetDashboardOverviewQuery__
 *
 * To run a query within a React component, call `useGetDashboardOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardOverviewQuery({
 *   variables: {
 *      arn: // value for 'arn'
 *   },
 * });
 */
export function useGetDashboardOverviewQuery(baseOptions: Apollo.QueryHookOptions<GetDashboardOverviewQuery, GetDashboardOverviewQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDashboardOverviewQuery, GetDashboardOverviewQueryVariables>(GetDashboardOverviewDocument, options);
      }
export function useGetDashboardOverviewLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDashboardOverviewQuery, GetDashboardOverviewQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDashboardOverviewQuery, GetDashboardOverviewQueryVariables>(GetDashboardOverviewDocument, options);
        }
export type GetDashboardOverviewQueryHookResult = ReturnType<typeof useGetDashboardOverviewQuery>;
export type GetDashboardOverviewLazyQueryHookResult = ReturnType<typeof useGetDashboardOverviewLazyQuery>;
export type GetDashboardOverviewQueryResult = Apollo.QueryResult<GetDashboardOverviewQuery, GetDashboardOverviewQueryVariables>;
export const GetDashboardControlsDocument = gql`
    query getDashboardControls($arn: String!, $standard: String!) {
  getDashboardControls(arn: $arn, standard: $standard) {
    customStandard
    dashboardResults {
      DISABLED
      FAILED
      PASSED
      PERCENT
      UNKNOWN
      DAY
    }
  }
}
    `;

/**
 * __useGetDashboardControlsQuery__
 *
 * To run a query within a React component, call `useGetDashboardControlsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardControlsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardControlsQuery({
 *   variables: {
 *      arn: // value for 'arn'
 *      standard: // value for 'standard'
 *   },
 * });
 */
export function useGetDashboardControlsQuery(baseOptions: Apollo.QueryHookOptions<GetDashboardControlsQuery, GetDashboardControlsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDashboardControlsQuery, GetDashboardControlsQueryVariables>(GetDashboardControlsDocument, options);
      }
export function useGetDashboardControlsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDashboardControlsQuery, GetDashboardControlsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDashboardControlsQuery, GetDashboardControlsQueryVariables>(GetDashboardControlsDocument, options);
        }
export type GetDashboardControlsQueryHookResult = ReturnType<typeof useGetDashboardControlsQuery>;
export type GetDashboardControlsLazyQueryHookResult = ReturnType<typeof useGetDashboardControlsLazyQuery>;
export type GetDashboardControlsQueryResult = Apollo.QueryResult<GetDashboardControlsQuery, GetDashboardControlsQueryVariables>;
export const GetControlMappingsDocument = gql`
    query getControlMappings($resultList: String!) @api(contextKey: "apiName") {
  getControlMappings(resultList: $resultList) {
    status
    rules
  }
}
    `;

/**
 * __useGetControlMappingsQuery__
 *
 * To run a query within a React component, call `useGetControlMappingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetControlMappingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetControlMappingsQuery({
 *   variables: {
 *      resultList: // value for 'resultList'
 *   },
 * });
 */
export function useGetControlMappingsQuery(baseOptions: Apollo.QueryHookOptions<GetControlMappingsQuery, GetControlMappingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetControlMappingsQuery, GetControlMappingsQueryVariables>(GetControlMappingsDocument, options);
      }
export function useGetControlMappingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetControlMappingsQuery, GetControlMappingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetControlMappingsQuery, GetControlMappingsQueryVariables>(GetControlMappingsDocument, options);
        }
export type GetControlMappingsQueryHookResult = ReturnType<typeof useGetControlMappingsQuery>;
export type GetControlMappingsLazyQueryHookResult = ReturnType<typeof useGetControlMappingsLazyQuery>;
export type GetControlMappingsQueryResult = Apollo.QueryResult<GetControlMappingsQuery, GetControlMappingsQueryVariables>;
export const StartStackSetRollbackSfnDocument = gql`
    mutation startStackSetRollbackSfn($arn: String!) @api(contextKey: "apiName") {
  startStackSetRollbackSfn(arn: $arn) {
    executionArn
  }
}
    `;
export type StartStackSetRollbackSfnMutationFn = Apollo.MutationFunction<StartStackSetRollbackSfnMutation, StartStackSetRollbackSfnMutationVariables>;

/**
 * __useStartStackSetRollbackSfnMutation__
 *
 * To run a mutation, you first call `useStartStackSetRollbackSfnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartStackSetRollbackSfnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startStackSetRollbackSfnMutation, { data, loading, error }] = useStartStackSetRollbackSfnMutation({
 *   variables: {
 *      arn: // value for 'arn'
 *   },
 * });
 */
export function useStartStackSetRollbackSfnMutation(baseOptions?: Apollo.MutationHookOptions<StartStackSetRollbackSfnMutation, StartStackSetRollbackSfnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartStackSetRollbackSfnMutation, StartStackSetRollbackSfnMutationVariables>(StartStackSetRollbackSfnDocument, options);
      }
export type StartStackSetRollbackSfnMutationHookResult = ReturnType<typeof useStartStackSetRollbackSfnMutation>;
export type StartStackSetRollbackSfnMutationResult = Apollo.MutationResult<StartStackSetRollbackSfnMutation>;
export type StartStackSetRollbackSfnMutationOptions = Apollo.BaseMutationOptions<StartStackSetRollbackSfnMutation, StartStackSetRollbackSfnMutationVariables>;
export const ListRemediationRequestsDocument = gql`
    query listRemediationRequests($arn: String!, $page: Int, $limit: Int) @api(contextKey: "apiName") {
  listRemediationRequests(arn: $arn, page: $page, limit: $limit) {
    status
    msg
    error
    results
    page
  }
}
    `;

/**
 * __useListRemediationRequestsQuery__
 *
 * To run a query within a React component, call `useListRemediationRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListRemediationRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListRemediationRequestsQuery({
 *   variables: {
 *      arn: // value for 'arn'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useListRemediationRequestsQuery(baseOptions: Apollo.QueryHookOptions<ListRemediationRequestsQuery, ListRemediationRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListRemediationRequestsQuery, ListRemediationRequestsQueryVariables>(ListRemediationRequestsDocument, options);
      }
export function useListRemediationRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListRemediationRequestsQuery, ListRemediationRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListRemediationRequestsQuery, ListRemediationRequestsQueryVariables>(ListRemediationRequestsDocument, options);
        }
export type ListRemediationRequestsQueryHookResult = ReturnType<typeof useListRemediationRequestsQuery>;
export type ListRemediationRequestsLazyQueryHookResult = ReturnType<typeof useListRemediationRequestsLazyQuery>;
export type ListRemediationRequestsQueryResult = Apollo.QueryResult<ListRemediationRequestsQuery, ListRemediationRequestsQueryVariables>;
export const ApproveRemediationRequestDocument = gql`
    mutation approveRemediationRequest($remediationId: Int!, $arn: String!) @api(contextKey: "apiName") {
  approveRemediationRequest(remediationId: $remediationId, arn: $arn) {
    status
    msg
    error
  }
}
    `;
export type ApproveRemediationRequestMutationFn = Apollo.MutationFunction<ApproveRemediationRequestMutation, ApproveRemediationRequestMutationVariables>;

/**
 * __useApproveRemediationRequestMutation__
 *
 * To run a mutation, you first call `useApproveRemediationRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveRemediationRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveRemediationRequestMutation, { data, loading, error }] = useApproveRemediationRequestMutation({
 *   variables: {
 *      remediationId: // value for 'remediationId'
 *      arn: // value for 'arn'
 *   },
 * });
 */
export function useApproveRemediationRequestMutation(baseOptions?: Apollo.MutationHookOptions<ApproveRemediationRequestMutation, ApproveRemediationRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveRemediationRequestMutation, ApproveRemediationRequestMutationVariables>(ApproveRemediationRequestDocument, options);
      }
export type ApproveRemediationRequestMutationHookResult = ReturnType<typeof useApproveRemediationRequestMutation>;
export type ApproveRemediationRequestMutationResult = Apollo.MutationResult<ApproveRemediationRequestMutation>;
export type ApproveRemediationRequestMutationOptions = Apollo.BaseMutationOptions<ApproveRemediationRequestMutation, ApproveRemediationRequestMutationVariables>;
export const RejectRemediationRequestDocument = gql`
    mutation rejectRemediationRequest($remediationId: Int!, $arn: String!) @api(contextKey: "apiName") {
  rejectRemediationRequest(remediationId: $remediationId, arn: $arn) {
    status
    msg
    error
  }
}
    `;
export type RejectRemediationRequestMutationFn = Apollo.MutationFunction<RejectRemediationRequestMutation, RejectRemediationRequestMutationVariables>;

/**
 * __useRejectRemediationRequestMutation__
 *
 * To run a mutation, you first call `useRejectRemediationRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRejectRemediationRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [rejectRemediationRequestMutation, { data, loading, error }] = useRejectRemediationRequestMutation({
 *   variables: {
 *      remediationId: // value for 'remediationId'
 *      arn: // value for 'arn'
 *   },
 * });
 */
export function useRejectRemediationRequestMutation(baseOptions?: Apollo.MutationHookOptions<RejectRemediationRequestMutation, RejectRemediationRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RejectRemediationRequestMutation, RejectRemediationRequestMutationVariables>(RejectRemediationRequestDocument, options);
      }
export type RejectRemediationRequestMutationHookResult = ReturnType<typeof useRejectRemediationRequestMutation>;
export type RejectRemediationRequestMutationResult = Apollo.MutationResult<RejectRemediationRequestMutation>;
export type RejectRemediationRequestMutationOptions = Apollo.BaseMutationOptions<RejectRemediationRequestMutation, RejectRemediationRequestMutationVariables>;
export const ScheduleRemediationRequestDocument = gql`
    mutation scheduleRemediationRequest($findingid: String!, $scheduledAt: String!, $timezone: String!) @api(contextKey: "apiName") {
  scheduleRemediationRequest(
    findingid: $findingid
    scheduledAt: $scheduledAt
    timezone: $timezone
  ) {
    status
    msg
    error
  }
}
    `;
export type ScheduleRemediationRequestMutationFn = Apollo.MutationFunction<ScheduleRemediationRequestMutation, ScheduleRemediationRequestMutationVariables>;

/**
 * __useScheduleRemediationRequestMutation__
 *
 * To run a mutation, you first call `useScheduleRemediationRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useScheduleRemediationRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [scheduleRemediationRequestMutation, { data, loading, error }] = useScheduleRemediationRequestMutation({
 *   variables: {
 *      findingid: // value for 'findingid'
 *      scheduledAt: // value for 'scheduledAt'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useScheduleRemediationRequestMutation(baseOptions?: Apollo.MutationHookOptions<ScheduleRemediationRequestMutation, ScheduleRemediationRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ScheduleRemediationRequestMutation, ScheduleRemediationRequestMutationVariables>(ScheduleRemediationRequestDocument, options);
      }
export type ScheduleRemediationRequestMutationHookResult = ReturnType<typeof useScheduleRemediationRequestMutation>;
export type ScheduleRemediationRequestMutationResult = Apollo.MutationResult<ScheduleRemediationRequestMutation>;
export type ScheduleRemediationRequestMutationOptions = Apollo.BaseMutationOptions<ScheduleRemediationRequestMutation, ScheduleRemediationRequestMutationVariables>;
export const InitiateRemediationRequestDocument = gql`
    mutation initiateRemediationRequest($findingId: String!) @api(contextKey: "apiName") {
  initiateRemediationRequest(findingId: $findingId) {
    status
    msg
    error
  }
}
    `;
export type InitiateRemediationRequestMutationFn = Apollo.MutationFunction<InitiateRemediationRequestMutation, InitiateRemediationRequestMutationVariables>;

/**
 * __useInitiateRemediationRequestMutation__
 *
 * To run a mutation, you first call `useInitiateRemediationRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateRemediationRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateRemediationRequestMutation, { data, loading, error }] = useInitiateRemediationRequestMutation({
 *   variables: {
 *      findingId: // value for 'findingId'
 *   },
 * });
 */
export function useInitiateRemediationRequestMutation(baseOptions?: Apollo.MutationHookOptions<InitiateRemediationRequestMutation, InitiateRemediationRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitiateRemediationRequestMutation, InitiateRemediationRequestMutationVariables>(InitiateRemediationRequestDocument, options);
      }
export type InitiateRemediationRequestMutationHookResult = ReturnType<typeof useInitiateRemediationRequestMutation>;
export type InitiateRemediationRequestMutationResult = Apollo.MutationResult<InitiateRemediationRequestMutation>;
export type InitiateRemediationRequestMutationOptions = Apollo.BaseMutationOptions<InitiateRemediationRequestMutation, InitiateRemediationRequestMutationVariables>;
export const ManageRemediationSettingsDocument = gql`
    mutation manageRemediationSettings($arn: String!, $automationFlowType: String, $automationApprovalFlow: String) @api(contextKey: "apiName") {
  manageRemediationSettings(
    arn: $arn
    automationFlowType: $automationFlowType
    automationApprovalFlow: $automationApprovalFlow
  ) {
    id
    status
    result
  }
}
    `;
export type ManageRemediationSettingsMutationFn = Apollo.MutationFunction<ManageRemediationSettingsMutation, ManageRemediationSettingsMutationVariables>;

/**
 * __useManageRemediationSettingsMutation__
 *
 * To run a mutation, you first call `useManageRemediationSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageRemediationSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageRemediationSettingsMutation, { data, loading, error }] = useManageRemediationSettingsMutation({
 *   variables: {
 *      arn: // value for 'arn'
 *      automationFlowType: // value for 'automationFlowType'
 *      automationApprovalFlow: // value for 'automationApprovalFlow'
 *   },
 * });
 */
export function useManageRemediationSettingsMutation(baseOptions?: Apollo.MutationHookOptions<ManageRemediationSettingsMutation, ManageRemediationSettingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManageRemediationSettingsMutation, ManageRemediationSettingsMutationVariables>(ManageRemediationSettingsDocument, options);
      }
export type ManageRemediationSettingsMutationHookResult = ReturnType<typeof useManageRemediationSettingsMutation>;
export type ManageRemediationSettingsMutationResult = Apollo.MutationResult<ManageRemediationSettingsMutation>;
export type ManageRemediationSettingsMutationOptions = Apollo.BaseMutationOptions<ManageRemediationSettingsMutation, ManageRemediationSettingsMutationVariables>;
export const GetRemediationSettingsDocument = gql`
    query getRemediationSettings @api(contextKey: "apiName") {
  getRemediationSettings {
    status
    msg
    error
    automationFlowType
    automationApprovalFlow
  }
}
    `;

/**
 * __useGetRemediationSettingsQuery__
 *
 * To run a query within a React component, call `useGetRemediationSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRemediationSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRemediationSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRemediationSettingsQuery(baseOptions?: Apollo.QueryHookOptions<GetRemediationSettingsQuery, GetRemediationSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRemediationSettingsQuery, GetRemediationSettingsQueryVariables>(GetRemediationSettingsDocument, options);
      }
export function useGetRemediationSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRemediationSettingsQuery, GetRemediationSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRemediationSettingsQuery, GetRemediationSettingsQueryVariables>(GetRemediationSettingsDocument, options);
        }
export type GetRemediationSettingsQueryHookResult = ReturnType<typeof useGetRemediationSettingsQuery>;
export type GetRemediationSettingsLazyQueryHookResult = ReturnType<typeof useGetRemediationSettingsLazyQuery>;
export type GetRemediationSettingsQueryResult = Apollo.QueryResult<GetRemediationSettingsQuery, GetRemediationSettingsQueryVariables>;
export const GetRemediationDataDocument = gql`
    query getRemediationData($arn: String!, $remediationId: Int!) @api(contextKey: "apiName") {
  getRemediationData(arn: $arn, remediationId: $remediationId) {
    status
    msg
    error
    data
  }
}
    `;

/**
 * __useGetRemediationDataQuery__
 *
 * To run a query within a React component, call `useGetRemediationDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRemediationDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRemediationDataQuery({
 *   variables: {
 *      arn: // value for 'arn'
 *      remediationId: // value for 'remediationId'
 *   },
 * });
 */
export function useGetRemediationDataQuery(baseOptions: Apollo.QueryHookOptions<GetRemediationDataQuery, GetRemediationDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRemediationDataQuery, GetRemediationDataQueryVariables>(GetRemediationDataDocument, options);
      }
export function useGetRemediationDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRemediationDataQuery, GetRemediationDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRemediationDataQuery, GetRemediationDataQueryVariables>(GetRemediationDataDocument, options);
        }
export type GetRemediationDataQueryHookResult = ReturnType<typeof useGetRemediationDataQuery>;
export type GetRemediationDataLazyQueryHookResult = ReturnType<typeof useGetRemediationDataLazyQuery>;
export type GetRemediationDataQueryResult = Apollo.QueryResult<GetRemediationDataQuery, GetRemediationDataQueryVariables>;
export const InitiateRemediationDocument = gql`
    mutation initiateRemediation($arn: String!, $findingId: String!) @api(contextKey: "apiName") {
  initiateRemediation(arn: $arn, findingId: $findingId) {
    status
    findingId
    error
    template
  }
}
    `;
export type InitiateRemediationMutationFn = Apollo.MutationFunction<InitiateRemediationMutation, InitiateRemediationMutationVariables>;

/**
 * __useInitiateRemediationMutation__
 *
 * To run a mutation, you first call `useInitiateRemediationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateRemediationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateRemediationMutation, { data, loading, error }] = useInitiateRemediationMutation({
 *   variables: {
 *      arn: // value for 'arn'
 *      findingId: // value for 'findingId'
 *   },
 * });
 */
export function useInitiateRemediationMutation(baseOptions?: Apollo.MutationHookOptions<InitiateRemediationMutation, InitiateRemediationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitiateRemediationMutation, InitiateRemediationMutationVariables>(InitiateRemediationDocument, options);
      }
export type InitiateRemediationMutationHookResult = ReturnType<typeof useInitiateRemediationMutation>;
export type InitiateRemediationMutationResult = Apollo.MutationResult<InitiateRemediationMutation>;
export type InitiateRemediationMutationOptions = Apollo.BaseMutationOptions<InitiateRemediationMutation, InitiateRemediationMutationVariables>;
export const InitiateRemediationsDocument = gql`
    mutation initiateRemediations($arn: String!, $control: String!, $accountRef: String!) @api(contextKey: "apiName") {
  initiateRemediations(arn: $arn, control: $control, accountRef: $accountRef) {
    status
    findingId
    error
    template
  }
}
    `;
export type InitiateRemediationsMutationFn = Apollo.MutationFunction<InitiateRemediationsMutation, InitiateRemediationsMutationVariables>;

/**
 * __useInitiateRemediationsMutation__
 *
 * To run a mutation, you first call `useInitiateRemediationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateRemediationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateRemediationsMutation, { data, loading, error }] = useInitiateRemediationsMutation({
 *   variables: {
 *      arn: // value for 'arn'
 *      control: // value for 'control'
 *      accountRef: // value for 'accountRef'
 *   },
 * });
 */
export function useInitiateRemediationsMutation(baseOptions?: Apollo.MutationHookOptions<InitiateRemediationsMutation, InitiateRemediationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitiateRemediationsMutation, InitiateRemediationsMutationVariables>(InitiateRemediationsDocument, options);
      }
export type InitiateRemediationsMutationHookResult = ReturnType<typeof useInitiateRemediationsMutation>;
export type InitiateRemediationsMutationResult = Apollo.MutationResult<InitiateRemediationsMutation>;
export type InitiateRemediationsMutationOptions = Apollo.BaseMutationOptions<InitiateRemediationsMutation, InitiateRemediationsMutationVariables>;
export const DeleteRemediationDocument = gql`
    mutation deleteRemediation($remediationId: String!, $arn: String!) @api(contextKey: "apiName") {
  deleteRemediation(remediationId: $remediationId, arn: $arn) {
    status
    msg
    error
  }
}
    `;
export type DeleteRemediationMutationFn = Apollo.MutationFunction<DeleteRemediationMutation, DeleteRemediationMutationVariables>;

/**
 * __useDeleteRemediationMutation__
 *
 * To run a mutation, you first call `useDeleteRemediationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRemediationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRemediationMutation, { data, loading, error }] = useDeleteRemediationMutation({
 *   variables: {
 *      remediationId: // value for 'remediationId'
 *      arn: // value for 'arn'
 *   },
 * });
 */
export function useDeleteRemediationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRemediationMutation, DeleteRemediationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRemediationMutation, DeleteRemediationMutationVariables>(DeleteRemediationDocument, options);
      }
export type DeleteRemediationMutationHookResult = ReturnType<typeof useDeleteRemediationMutation>;
export type DeleteRemediationMutationResult = Apollo.MutationResult<DeleteRemediationMutation>;
export type DeleteRemediationMutationOptions = Apollo.BaseMutationOptions<DeleteRemediationMutation, DeleteRemediationMutationVariables>;
export const StartMultiCloudwatchDocument = gql`
    mutation startMultiCloudwatch($arn: String!, $input: MultiCloudwatchInput) @api(contextKey: "apiName") {
  startMultiCloudwatch(arn: $arn, input: $input) {
    executionArn
  }
}
    `;
export type StartMultiCloudwatchMutationFn = Apollo.MutationFunction<StartMultiCloudwatchMutation, StartMultiCloudwatchMutationVariables>;

/**
 * __useStartMultiCloudwatchMutation__
 *
 * To run a mutation, you first call `useStartMultiCloudwatchMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartMultiCloudwatchMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startMultiCloudwatchMutation, { data, loading, error }] = useStartMultiCloudwatchMutation({
 *   variables: {
 *      arn: // value for 'arn'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartMultiCloudwatchMutation(baseOptions?: Apollo.MutationHookOptions<StartMultiCloudwatchMutation, StartMultiCloudwatchMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartMultiCloudwatchMutation, StartMultiCloudwatchMutationVariables>(StartMultiCloudwatchDocument, options);
      }
export type StartMultiCloudwatchMutationHookResult = ReturnType<typeof useStartMultiCloudwatchMutation>;
export type StartMultiCloudwatchMutationResult = Apollo.MutationResult<StartMultiCloudwatchMutation>;
export type StartMultiCloudwatchMutationOptions = Apollo.BaseMutationOptions<StartMultiCloudwatchMutation, StartMultiCloudwatchMutationVariables>;
export const GetMultiCloudwatchStateDocument = gql`
    query getMultiCloudwatchState($arn: String!) @api(contextKey: "apiName") {
  getMultiCloudwatchState(arn: $arn) {
    status
    cloudTrails
    snsTopics
  }
}
    `;

/**
 * __useGetMultiCloudwatchStateQuery__
 *
 * To run a query within a React component, call `useGetMultiCloudwatchStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMultiCloudwatchStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMultiCloudwatchStateQuery({
 *   variables: {
 *      arn: // value for 'arn'
 *   },
 * });
 */
export function useGetMultiCloudwatchStateQuery(baseOptions: Apollo.QueryHookOptions<GetMultiCloudwatchStateQuery, GetMultiCloudwatchStateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMultiCloudwatchStateQuery, GetMultiCloudwatchStateQueryVariables>(GetMultiCloudwatchStateDocument, options);
      }
export function useGetMultiCloudwatchStateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMultiCloudwatchStateQuery, GetMultiCloudwatchStateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMultiCloudwatchStateQuery, GetMultiCloudwatchStateQueryVariables>(GetMultiCloudwatchStateDocument, options);
        }
export type GetMultiCloudwatchStateQueryHookResult = ReturnType<typeof useGetMultiCloudwatchStateQuery>;
export type GetMultiCloudwatchStateLazyQueryHookResult = ReturnType<typeof useGetMultiCloudwatchStateLazyQuery>;
export type GetMultiCloudwatchStateQueryResult = Apollo.QueryResult<GetMultiCloudwatchStateQuery, GetMultiCloudwatchStateQueryVariables>;
export const GetRemediationStatusDocument = gql`
    query getRemediationStatus($arn: String!, $executionArn: String!) @api(contextKey: "apiName") {
  getRemediationStatus(arn: $arn, executionArn: $executionArn) {
    status
    tasks {
      name
      order
      status
      error
    }
    error
  }
}
    `;

/**
 * __useGetRemediationStatusQuery__
 *
 * To run a query within a React component, call `useGetRemediationStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRemediationStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRemediationStatusQuery({
 *   variables: {
 *      arn: // value for 'arn'
 *      executionArn: // value for 'executionArn'
 *   },
 * });
 */
export function useGetRemediationStatusQuery(baseOptions: Apollo.QueryHookOptions<GetRemediationStatusQuery, GetRemediationStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRemediationStatusQuery, GetRemediationStatusQueryVariables>(GetRemediationStatusDocument, options);
      }
export function useGetRemediationStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRemediationStatusQuery, GetRemediationStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRemediationStatusQuery, GetRemediationStatusQueryVariables>(GetRemediationStatusDocument, options);
        }
export type GetRemediationStatusQueryHookResult = ReturnType<typeof useGetRemediationStatusQuery>;
export type GetRemediationStatusLazyQueryHookResult = ReturnType<typeof useGetRemediationStatusLazyQuery>;
export type GetRemediationStatusQueryResult = Apollo.QueryResult<GetRemediationStatusQuery, GetRemediationStatusQueryVariables>;