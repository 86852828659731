import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { Alert, Box, CircularProgress, Container, FormControl, Grid, InputAdornment, InputLabel, MenuItem, Paper, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import { FC, useCallback, useEffect, useState } from "react";
import useAccount from "../../../hooks/useAccount";
import ConnectedAccount from "../../../models/ConnectedAccount";
import * as yup from "yup";
import { useCreateMultiFrameworkMutation } from "../../../generated";
import useSnackBars from "../../../hooks/useSnackbar";

interface MultiFrameworkSelectionProps {
  arn: string;
  organisation: string;
  targetAccountType: string;
  setUnlockSteps: React.Dispatch<
    React.SetStateAction<{
      step2: boolean;
      step3: boolean;
      step4: boolean;
      step5: boolean;
    }>
  >;
  changeTab: (
    index: number,
    frameworkName: string,
    targetArn: string,
    frameworkSelection: string,
    connectedAccounts: Array<ConnectedAccount>,
    accounttype: string,
  ) => void;
}

type Account = {
	arn: string;
  defaultregion: string;
  accounttype: string;
  externalId: string;
  accessnickname: string;
  member_accounts: Array<string>;
  member_regions: Array<string>;
  connectedAccounts: Array<ConnectedAccount>;
  accountIdRef: string;
}

const validationSchema = yup.object({
  // Dynamic validation schema for text fields
  ...new Array(1).fill("").reduce((acc, _, index) => {
    acc[`textField${index + 1}`] = yup
      .string()
      .min(3, "Workload Name should be of minimum 3 characters length.")
      .test(
        "unique",
        "Workload names must be unique",
        function (value) {
          const { path, parent } = this;
          const values = Object.values(parent).slice(
            1
          ); // Only consider text fields
          const index = parseInt(String(path.match(/\d+/)?.[0]), 10); // Ensure path is cast to string
          // Ensure values and index are defined
          if (values && !isNaN(index) && values.length > index) {
            const otherValues = values.filter((_, i) => i !== index - 1); // Exclude the current field value from the comparison
            return !otherValues.includes(value);
          }

          return true; // Default to true if unable to perform comparison
        }
      );
    return acc;
  }, {}),
});

//MultiFramework Selection Page
const MultiFrameworkSelection: FC<MultiFrameworkSelectionProps> =({
  arn,
  organisation,
  targetAccountType,
  setUnlockSteps,
  changeTab,
}: MultiFrameworkSelectionProps) => {
  const { accounts, connectedAccounts } = useAccount();
  const { setAlerts } = useSnackBars();
  const [technicalReviewArn, setTechnicalReviewArn] = useState(arn); //track technical review account that is selected
  const [connectedAccountsDropDown, setConnectedAccountsDropDown] = useState(connectedAccounts); //Displays the connected accounts dropdown
  const [technicalAccountType, setTechnicalAccountType] = useState(targetAccountType);
  const [accountIdRef, setAccountIdRef] = useState<string>("");
  const [connectedAutomatePlus, setConnectedAutomatePlus] = useState(true);

  const [createMultiFrameworkMutation, { loading }] = useCreateMultiFrameworkMutation({
    context: {
      apiName: "well_architected",
    },
  });

  //Filter and get the connected account
  const getFilteredConnectedAccounts = () => {
    return connectedAccountsDropDown
      .filter((connectedAccount: ConnectedAccount) => {
        return connectedAccount.accountIdRef === accountIdRef;
      })
      .map((connectedAccount: ConnectedAccount) => {
        return {
          accountIdRef: connectedAccount.accountIdRef,
          region: connectedAccount.region,
        };
      });
  };

  //Technical Review Account Dropdown Handle
  const handleTechnicalReviewAccount = useCallback((e: SelectChangeEvent<string>) => {
    const account = accounts.find((i: Account) => i.arn === e.target.value);
    if (account) {
      setConnectedAccountsDropDown(account.connectedAccounts);
      setAccountIdRef(account.connectedAccounts?.[0]?.accountIdRef);
      setTechnicalReviewArn(account.arn);
      setTechnicalAccountType(account.accounttype);
    }
  }, [
    accounts,
    setConnectedAccountsDropDown,
    setAccountIdRef,
    setTechnicalReviewArn,
    setTechnicalAccountType
  ]);

  //Connected Account Dropdown Handle
  const handleConnectedAccount = useCallback((e: SelectChangeEvent<string>) => {
    setAccountIdRef(e.target.value as string);
    const account = accounts.find((i: Account) => i.arn === technicalReviewArn); //get the account that the connectedAccount belongs to
    if (account) {
      let automatePlus = false;
      //Check if connected account is part of the member_accounts or is the same as targetAccount
      if (
        account?.member_accounts.includes(e.target.value as string) ||
        technicalReviewArn.split(":")[4] === (e.target.value as string)
      ) {
        automatePlus = true;
      } else {
        automatePlus = false;
      }
      //If connected Account is part of member account or if the targetAccount and connected account
      //are the same then set true to connectedAutomatePlus
      setConnectedAutomatePlus(automatePlus);
    }
  }, [setAccountIdRef, accounts, setConnectedAutomatePlus, technicalReviewArn]);

  // Update getFilteredConnectedAccounts() when accountIdRef changes
  useEffect(() => {
    getFilteredConnectedAccounts();
  }, [accountIdRef]);
  
  const formik = useFormik({
    initialValues: {
      // numberOfWorkloads: 1, // Initial value for number of text fields
      // Initial values for dynamic text fields
      frameworkSelection: "",
      ...new Array(4).fill("").reduce((acc, _, index) => {
        acc[`textField${index + 1}`] = "";
        return acc;
      }, {}),
    },
    validationSchema,
    onSubmit: async (values) => {
      const name = `${organisation}-${values[`textField${1}`]}`;
      const response = await Promise.all([
        createMultiFrameworkMutation({
          variables: {
            arn, // value for 'arn'
            standard: values.frameworkSelection, // value for 'framework'
            frameworkName: name,
            awsTechnicalReviewAccount: technicalReviewArn, // value for 'awsTechnicalReviewAccount'
            connectedAccounts: getFilteredConnectedAccounts(), // value for 'connectedAccounts'
          },
        })
      ]);
      if(response?.[0]?.data?.createMultiFramework?.status === "COMPLETE"){
        setUnlockSteps((prevState) => ({
          ...prevState,
          step2: true, // Unlock step 2
        }));
        if (
          technicalAccountType === "AUTOMATED" &&
          connectedAutomatePlus
        ) {
          changeTab(1, name, technicalReviewArn, values.frameworkSelection, getFilteredConnectedAccounts(), "AUTOMATED");
        } else {
          changeTab(1, name, technicalReviewArn, values.frameworkSelection, getFilteredConnectedAccounts(), "READ_ONLY");
        }
      } else {
        setAlerts([
          {
            severity: "error",
            msg: "Please use another Compliance Workflow name",
          },
        ]);
      }
    },
  });
  
  return (
    <form onSubmit={formik.handleSubmit}>
    <Container maxWidth="lg" component={Paper}>
      <Box sx={{ flexGrow: 1, pb: 2 }}>
        <Grid
          container
          sx={{ pb: 2, pt: 2, pl: 2 }}
          spacing={2}
          alignItems="stretch"
          flexDirection={"column"}
        >
          <Typography
            component={"span"}
            variant="h6"
            color="text.primary"
            paragraph
          >
            Create a Compliance Workload in your AWS Account. 
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              width: "inherit",
              p: 2,
              pl: 10,
              pr: 10,
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Typography
                component={"span"}
                variant="subtitle1"
                color="text.secondary"
                paragraph
                sx={{ mb: 0 }}
              >
                STEP 1: Create Workload
              </Typography>
              <Typography
                component={"span"}
                variant="subtitle1"
                color="text.secondary"
                paragraph
                sx={{ mb: 0 }}
              >
                STEP 2: Complete Non-Disruptive Recommendations
              </Typography>
              <Typography
                component={"span"}
                variant="subtitle1"
                color="text.secondary"
                paragraph
                sx={{ mb: 0 }}
              >
                STEP 3: Complete Policies and Process Review
              </Typography>
              <Typography
                component={"span"}
                variant="subtitle1"
                color="text.secondary"
                paragraph
                sx={{ mb: 0 }}
              >
                STEP 4: Generate Auditor Reports
              </Typography>
            </Box>
          </Box>
          <Grid item md={10} />
          <Grid item md={12}>
            <Alert variant="outlined" severity="info">
              {`Please select the AWS Account that will create the compliance 
              workload using the "All Accounts" drop-down in
              the top right corner of the screen.`}
            </Alert>
          </Grid>
          <Grid item md={6} />
          <Grid item md={12}>
            <Typography variant="body2">
              Compliance Workflows Reviews are conducted on a
              per-workload basis. Please choose the AWS account below that
              contains the workloads that are to be reviewed.
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="aws-technical-review-account">
                  AWS Technical Review Account
                </InputLabel>
                <Select
                  required
                  id="awsTechnicalReviewAccount"
                  name="awsTechnicalReviewAccount"
                  value={technicalReviewArn}
                  label="AWS Technical Review Account"
                  onChange={handleTechnicalReviewAccount}
                >
                  {accounts.map((a: Account) => {
                    const splits = a.arn.split(":");
                    return (
                      <MenuItem
                        key={`${a.accessnickname} - ${splits[4]}`}
                        value={a.arn}
                      >{`${a.accessnickname} - ${splits[4]}`}</MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item md={6} />
          {connectedAccountsDropDown.length > 1 ? (
            <>
              <Grid item md={12}>
                <Typography variant="body2">
                  You have selected a AWS parent account which has multiple
                  AWS sub-accounts. Which AWS sub-account contains the
                  workflow you want to review? Please select from the drop
                  down below.
                </Typography>
              </Grid>
              <Grid item md={6}>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="aws-connected-account">
                      AWS Connected Account
                    </InputLabel>
                    <Select
                      required
                      id="awsConnectedAccount"
                      name="awsConnectedAccount"
                      value={accountIdRef}
                      label="AWS Connected Account"
                      onChange={handleConnectedAccount}
                    >
                      {connectedAccountsDropDown
                        .reduce(
                          (
                            acc: Array<string>,
                            a: ConnectedAccount,
                          ) => {
                            if (
                              a.accountIdRef &&
                              !acc.includes(a.accountIdRef)
                            ) {
                              acc.push(a.accountIdRef);
                            }
                            return acc;
                          },
                          []
                        )
                        .map((accountIdRef: string) => {
                          return (
                            <MenuItem
                              key={`${accountIdRef}`}
                              value={accountIdRef}
                            >{`${accountIdRef}`}</MenuItem>
                          );
                        })}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
              <Grid item md={6} />
            </>
          ) : null}
          <Grid item md={12}>
            <Typography variant="body2">
              Please select which compliance standard you wish to certify against?
            </Typography>
          </Grid>
          <Grid item md={6}>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth>
                <InputLabel id="framework-selection">
                  Workflow Selection
                </InputLabel>
                <Select
                  required
                  id="frameworkSelection"
                  name="frameworkSelection"
                  value={formik.values.frameworkSelection}
                  label="Workflow Selection"
                  onChange={formik.handleChange}
                >
                  <MenuItem key={"ISO2022"} value={"ISO-IEC 27001:2022 Annex A"}>
                    ISO-IEC 27001:2022
                  </MenuItem>
                  <MenuItem key={"SOC2"} value={"SOC 2"}>
                    SOC 2
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item md={6} />
          {[...Array(formik.values.numberOfWorkloads)].map((_, index) => (
            <Grid item md={6} sx={{ pb: 2 }} key={index}>
              <TextField
                fullWidth
                id={`textField${index + 1}`}
                placeholder={"PROD-Workload Name"}
                label={"Workload Name"}
                value={formik.values[`textField${index + 1}`]}
                error={
                  formik.touched[`textField${index + 1}`] &&
                  Boolean(formik.errors[`textField${index + 1}`])
                }
                helperText={
                  formik.touched[`textField${index + 1}`] &&
                  formik.errors[`textField${index + 1}`]
                    ? String(formik.errors[`textField${index + 1}`])
                    : ""
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {organisation}-
                    </InputAdornment>
                  ),
                }}
                onChange={formik.handleChange}
              />
            </Grid>
          ))}
          <Grid item xs md={4} justifyContent="flex-end">
            <LoadingButton
              id="wat-setup-btn"
              variant="contained"
              color="secondary"
              type="submit"
              disabled={loading}
              loading={loading}
              sx={{
                width: 157,
                height: 40,
                bgcolor: "secondary.main",
                "&:hover": {
                  bgcolor: "secondary.main",
                  color: "secondary.contrastText",
                },
              }}
              loadingIndicator={
                <CircularProgress color={"secondary"} size={22} />
              }
            >
              Create
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Container>
    </form>
  );
};

export default MultiFrameworkSelection;