import { Box, CircularProgress, Container, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import Loader from "../../common/Loader";
import { useCallback, useEffect, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import { useGetFrameworkQuestionnaireQuery, useUpdateMultiFrameworkQuestionnaireTableMutation } from "../../../generated";
import FrameworkQuestion from "./FrameworkQuestion";
import useSnackBars from "../../../hooks/useSnackbar";
import useNewrelic from "../../../hooks/useNewrelic";

interface FrameworkQuestionnaireProps {
  arn: string;
  frameworkName: string;
  frameworkSelection: string;
  setUnlockSteps: React.Dispatch<
    React.SetStateAction<{
      step2: boolean;
      step3: boolean;
      step4: boolean;
      step5: boolean;
    }>
  >;
  // answers: { [key: string]: string };
  answers: Array<{
    id: string;
    description: string;
    selection: string;
  }>;
  onAnswerChange: (questionId: string, question: string, answer: string) => void;
  changeTab: (
    index: number,
  ) => void;
}

/**
 * Row data information
 * @param question_id question id
 * @param question question description
 * @param answer_id answer id
 * @param answers answer yes or no
 * @param template mapping template
 * @param questionnaire_answer answer from db
 * @returns row information
 */
function createData(
  question_id: string,
  question: string,
  answer_id: string,
  answers: string[],
  template: string,
  questionnaire_answer: string,
) {
  return { question_id, question, answer_id, answers, template, questionnaire_answer };
}

type RowQuestion = {
  question_id: string;
  question: string;
  answer_id: string;
  answers: string[];
  template: string;
  questionnaire_answer: string;
};

/**
 * Displays overall framework questionnaire page
 * @param arn arn of user
 * @param frameworkName name of framework
 * @param frameworkSelection standard selected
 * @param setUnlockSteps which step to unlock and lock in the tabs
 * @param answers usestate that keeps track of answers selected in this page
 * @param onAnswerChange handle to be called when answer switch is toggled
 * @param changeTab tab to switch to after this page is completed
 * @returns all the framework questions based on framework selected
 */
export default function FrameworkQuestionnaire({
  arn,
  frameworkName,
  frameworkSelection,
  setUnlockSteps,
  answers,
  onAnswerChange,
  changeTab,
}: FrameworkQuestionnaireProps){
  const { noticeError } = useNewrelic();
  const [rows, setRows] = useState<Array<RowQuestion>>([]);
  const [activeSpinner, setActiveSpinner] = useState(false);
  
  const { setAlerts } = useSnackBars();
  
  const { data, loading } = useGetFrameworkQuestionnaireQuery({
    variables: {
      arn,
      standard: frameworkSelection, // value for 'standard'
      frameworkName,
    },
    context: {
      apiName: "well_architected",
    },
  });

  const [updateMultiFrameworkQuestionnaireTableMutation] = useUpdateMultiFrameworkQuestionnaireTableMutation({
    context: {
      apiName: "well_architected",
    },
    notifyOnNetworkStatusChange: true,
  });

  //Handle that unlocks step 2
  const handleUnlockSteps = useCallback(() => {
    setUnlockSteps((prevState) => ({
      ...prevState,
      step4: false, // Lock step 4
      step5: true, // Unlock step 5
    }));
  }, [setUnlockSteps]);

  //Trigger updateMultiFrameworkQuestionnaireTable API and send answers to DB
  const handleSubmit = useCallback(async () => {
    const answeredQuestions = answers.filter((a) => a.selection !== "");

    setActiveSpinner(true);

    try {
      await Promise.all(
        answeredQuestions.map((question) =>
          updateMultiFrameworkQuestionnaireTableMutation({
            variables: {
              arn,// value for 'arn'
              standard: frameworkSelection,// value for 'standard'
              frameworkName,// value for 'frameworkName'
              questionId: question.id,
              answer: question.selection,
            },
          })
        )
      );

      setActiveSpinner(false);
      setAlerts([
        {
          severity: "success",
          msg: "All answers submitted successfully!",
        },
      ]);
      handleUnlockSteps(); // Optionally unlock steps after successful submission
      changeTab(4);
    } catch (error) {
      setActiveSpinner(false);
      noticeError(error, "FrameworkQuestionnaire");
      setAlerts([
        {
          severity: "error",
          msg: "An error occurred while submitting answers. Please try again.",
        },
      ]);
    }
  }, [
    setActiveSpinner,
    updateMultiFrameworkQuestionnaireTableMutation,
    setAlerts,
    handleUnlockSteps,
    changeTab,
    answers,
    arn,
    frameworkName, 
    frameworkSelection
  ]);

  useEffect(() => {
    if (!loading && data?.getFrameworkQuestionnaire) {
      const questions = data?.getFrameworkQuestionnaire.map((q: any) => {
        return createData(q.question_id, q.question, q.answer_id, q.answers, q.template, q.questionnaire_answer);
      });
      setRows(questions);

      //Pre-select answers
      questions.forEach((q) => onAnswerChange(q.question_id, q.question, q.questionnaire_answer));
    }
  }, [data, loading]);
  
  return (
    <Container maxWidth="lg" component={Paper}>
      <Grid container sx={{ p: 1 }}>
        <Typography>
          NB: You will need to answer all questions in the one sitting due to
          the way that the workload is updated. If you would like any
          assistance, please reach out to secure@6pillars.ai
        </Typography>
      </Grid>
      <Grid item xs md={12} />
      <Grid
        container
        sx={{ pb: 2, pt: 2, pl: 2 }}
        spacing={2}
        alignItems="center"
      >
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650, pt:10 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell>Question</TableCell>
                <TableCell align="center">Select</TableCell>
                <TableCell align="center">Download Template</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length === 0 && (
                <TableRow
                  key={1}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell colSpan={12}>
                    <Loader/>
                  </TableCell>
                </TableRow>
              )}
              {rows.map((row, index) => (
                <FrameworkQuestion
                  key={row.question_id}
                  index={index}
                  row={row}
                  answer={answers.find((a) => a.id === row.question_id)?.selection || ""}
                  onAnswerChange={onAnswerChange}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid item xs md={10}>
          <Typography>
            Click to submit your Compliance Workflows Review
          </Typography>
        </Grid>
        <Grid item xs md={2}>
          <Box>
            <LoadingButton
              variant="contained"
              color="secondary"
              type="submit"
              loading={activeSpinner}
              onClick={handleSubmit}
              sx={{
                width: 157,
                height: 40,
                bgcolor: "secondary.main",
                "&:hover": {
                  bgcolor: "secondary.main",
                  color: "secondary.contrastText",
                },
              }}
              loadingIndicator={
                <CircularProgress color={"secondary"} size={22} />
              }
            >
              Next
            </LoadingButton>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}